import { TagCategoryEnum } from '@aksia/enums';
import { ITagDTO } from '@aksia/models';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { InvestmentVehicleStore } from '../stores/investment-vehicle.store';

@Pipe({
  name: 'tag',
  standalone: true,
})
export class TagPipe implements PipeTransform {
  private readonly store = inject(InvestmentVehicleStore);

  transform(
    quantumTags?: Array<ITagDTO> | null | undefined,
    categoryName?: string,
    selection: 'single' | 'multi' = 'single',
  ) {
    let allTags = [
      ...(this.store.availableAssetClassSubstrategyTags() ?? []),
      ...(this.store.availableCrossStrategyTags() ?? []),
    ];

    let categoryId = allTags?.find(
      (tag) => tag.categoryName === categoryName,
    )?.categoryId;

    let tags =
      quantumTags?.length! > 0
        ? selection === 'single'
          ? quantumTags?.find((tag) => tag.tagCategory === categoryId)?.tagId
          : quantumTags
              ?.filter((tag) => tag.tagCategory === categoryId)
              ?.map((tag) => tag.tagId)
        : undefined;
    return tags;
  }
}
