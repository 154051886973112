@let iv = ivStore.entity(); @let company = companyStore.entity(); @if (iv) {
<number
  class="c1s1"
  [(value)]="iv.fundCRD"
  [settings]="{
        label: 'CRD',
        labelTip: 'Central Registration Depository',
        abbr: false,
        onlyWholeNumbers: true,
        thousandsSeparator: '',
      }"
></number>
<number
  class="c2s1"
  [(value)]="iv.fundCIK"
  [settings]="{
        label: 'CIK',
        labelTip: 'Central Index Key',
        abbr: false,
        onlyWholeNumbers: true,
        thousandsSeparator: '',
      }"
></number>
} @else if (company) {
<number
  class="c1s1"
  [(value)]="company.managerCRD"
  [settings]="{
        label: 'CRD',
        labelTip: 'Central Registration Depository',
        abbr: false,
        onlyWholeNumbers: true,
        thousandsSeparator: '',
      }"
></number>
<number
  class="c2s1"
  [(value)]="company.managerCIK"
  [settings]="{
        label: 'CIK',
        labelTip: 'Central Index Key',
        abbr: false,
        onlyWholeNumbers: true,
        thousandsSeparator: '',
      }"
></number>
}
