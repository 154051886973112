@let iv = ivStore.investmentVehicle()!;
@if (iv) {
  <date
    class="c1s1"
    [(value)]="iv.dateOfFormation"
    [settings]="{
      label: 'Date of Formation',
      labelTip: 'Insert the date the fund was established/incorporated.',
      tag: 'Date of Formation',
      uid: iv.$uid,
    }"
    [validators]="ivStore.dateOfFormation_validators()"
  ></date>
  <date
    class="c2s1"
    [(value)]="iv.commencementOfOperations"
    [settings]="{
      label: 'Commencement of Operations',
      labelTip:
        'Insert the date the fund started operations/investment activities.',
      tag: 'Commencement of Operations',
      uid: iv.$uid,
    }"
    [validators]="ivStore.commencementOfOperations_validators()"
  ></date>
  @if (
    layout.selectedView() === VIEWS.DATA ||
    layout.selectedView() === VIEWS.INVESTMENT_RESEARCH
  ) {
    <dropdown
      class="c1s1"
      [(value)]="iv.fundraisingStatus"
      [settings]="{
        label: 'Fundraising Status',
        labelTip:
          'Fundraising indicates in market, accepting new investors and capital. If final close has passed, automated to Not Fundraising.',
        options: ENUMS.FundRaisingStatusEnum.toOptions,
        optionsFiltering: false,
        tag: 'Fundraising Status',
      }"
    ></dropdown>
    <text
      class="c2s1"
      [(value)]="iv.fundraisingNotes"
      [settings]="{
        label: 'Fundraising Notes',
        placeholder: 'Enter notes',
        tag: 'Fundraising Notes',
      }"
    ></text>
  }
}
