import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyStore } from '../stores/company.store';
import { LocalStorageService, LayoutService } from '@aksia/services';
import { AuthenticationService } from '../services/authentication.service';
import { STORAGE_KEYS } from '@aksia/infrastructure';

@Injectable({ providedIn: 'root' })
export class CompanyResolver implements Resolve<number> {
  private readonly local = inject(LocalStorageService);
  private readonly view = inject(LayoutService);
  private readonly auth = inject(AuthenticationService);
  private readonly store = inject(CompanyStore);

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<number> | Promise<number> | number {
    //Set Default View
    let defaultView = this.local.get(STORAGE_KEYS.LAYOUT.DEFAULT_VIEW);
    if (!defaultView) {
      defaultView = this.auth.user()?.getDefaultView();
    }
    this.view.setDefaultView(defaultView);

    //Load Data
    const entityId = +route.params['entityId'];
    this.store.setEntityId(entityId);
    this.store.loadAllMethods();
    return entityId;
  }
}
