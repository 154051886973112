import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LocationComponent,
  NumberComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Decision Dates"]',
  imports: [DateComponent, DropdownComponent, TextComponent],
  templateUrl: './decision-dates.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DecisionDatesSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Financial Statistics"]',
  imports: [DateComponent, NumberComponent, TextComponent],
  templateUrl: './financial-statistics.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialStatisticsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Co Investment Key Terms"]',
  imports: [DropdownComponent, DateComponent, NumberComponent, TextComponent],
  templateUrl: './key-terms.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyTermsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Real Estate Details"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './real-estate-details.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateDetailsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Staff Involved"]',
  imports: [DropdownComponent, TextComponent],
  templateUrl: './staff-involved.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffInvolvedSectionContent extends StaticContentDirective {}

export const InvestmentVehicleCoInvestmentSectionContents = [
  DecisionDatesSectionContent,
  FinancialStatisticsSectionContent,
  KeyTermsSectionContent,
  RealEstateDetailsSectionContent,
  StaffInvolvedSectionContent,
];
