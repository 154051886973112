import { RouterModule } from '@angular/router';
import { Component, inject } from '@angular/core';
import { PageLayoutComponent } from '@aksia/ui';
import {
  Icons,
  LAYOUT_SERVICE_TOKEN,
  LOADING_SERVICE_TOKEN,
} from '@aksia/infrastructure';
import { CommonModule } from '@angular/common';

@Component({
  imports: [CommonModule, RouterModule, PageLayoutComponent],
  selector: 'app',
  templateUrl: './app.component.html',
})
export class AppComponent {
  protected readonly layout = inject(LAYOUT_SERVICE_TOKEN);
  protected readonly loading = inject(LOADING_SERVICE_TOKEN);

  readonly icons = Icons;
  title = 'o2';
}
