import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  IValidationRequest,
  IValidationValidateRequest,
  OptionType,
  SectionGroupSettings,
  TViewItem,
  UTILS,
} from '@aksia/infrastructure';
import {
  AssetTeamViewType,
  DOMAIN_API_REQUESTS,
  DOMAINS,
  Enums,
  InvestmentVehicle,
  ISector,
  ITag,
  IUiCategoryTag,
  MasterFund,
  Sector,
  Tag,
} from '@aksia/models';
import {
  ApiService,
  LayoutService,
  LoadingService,
  NavigationService,
} from '@aksia/services';
import { computed, inject, Signal } from '@angular/core';
import { AssetClassEnum, TagCategoryEnum } from '@aksia/enums';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  concatMap,
  map,
  pipe,
  switchMap,
  tap,
  concat,
  of,
  toArray,
  Observable,
} from 'rxjs';

type TagType = {
  label: string;
  views: Array<AssetTeamViewType>;
  categories: Array<IUiCategoryTag>;
  settings: SectionGroupSettings;
  contentCssClass: string;
};

type TaxonomyState = {
  availableHierarchy: Array<Sector> | undefined;
  availableCrossStrategyTags: Array<Tag> | undefined;
  availableAssetClassSubstrategyTags: Array<Tag> | undefined;
  availablePrimaryRegions: Array<Tag> | undefined;
  selectedAssetClassId: AssetClassEnum | undefined;
  selectedSectorId: number | undefined;
  selectedStrategyId: number | undefined;
  selectedSubstrategyId: number | undefined;
};

export function withTaxonomy<TaxonomyFeature>() {
  return signalStoreFeature(
    {
      state: type<{ taxonomyIsLoaded?: boolean }>(),
      computed: type<{
        investmentVehicle: Signal<InvestmentVehicle | undefined>;
      }>(),
      methods: type<{
        registerMethod(method: Function, priority?: number): void;
        finalizeMethod(priority: number): void;
        validate(
          request:
            | IValidationValidateRequest
            | Array<IValidationValidateRequest>,
        ): void;
        removeValidation(
          request: IValidationRequest | Array<IValidationRequest>,
        ): void;
      }>(),
    },
    withState<TaxonomyState>({
      availableHierarchy: undefined,
      availableCrossStrategyTags: undefined,
      availableAssetClassSubstrategyTags: undefined,
      availablePrimaryRegions: undefined,
      selectedAssetClassId: undefined,
      selectedSectorId: undefined,
      selectedStrategyId: undefined,
      selectedSubstrategyId: undefined,
    }),
    withComputed((store) => ({
      filteredSectors: computed(() =>
        UTILS.UI.dataToOptions(
          store
            .availableHierarchy()
            ?.filter(
              (sector) => sector.assetClassId === store.selectedAssetClassId(),
            )!,
          'sector',
          'sectorId',
        ),
      ),
      filteredStrategies: computed(() =>
        UTILS.UI.dataToOptions(
          store
            .availableHierarchy()
            ?.filter(
              (sector) => sector.assetClassId === store.selectedAssetClassId(),
            )
            ?.filter((sector) => sector.sectorId === store.selectedSectorId())
            ?.filter((sector) => sector.strategies?.length! > 0)
            ?.flatMap((sector) => sector.strategies!)
            ?.filter((strategy) => strategy)!,
          'strategy',
          'strategyId',
        ),
      ),
      filteredSubstrategies: computed(() =>
        UTILS.UI.dataToOptions(
          store
            .availableHierarchy()
            ?.filter(
              (sector) => sector.assetClassId === store.selectedAssetClassId(),
            )
            ?.filter((sector) => sector.sectorId === store.selectedSectorId())
            ?.filter((sector) => sector.strategies?.length! > 0)
            ?.flatMap((sector) => sector.strategies!)
            ?.filter((strategy) => strategy)
            ?.filter(
              (strategy) => strategy.strategyId === store.selectedStrategyId(),
            )
            ?.flatMap((strategy) => strategy.substrategies!)
            ?.filter((subStrategy) => subStrategy)!,
          'substrategy',
          'substrategyId',
        ),
      ),
      filteredPrimaryRegions: computed(() =>
        UTILS.UI.dataToOptions(store.availablePrimaryRegions()!, 'name', 'id'),
      ),
    })),
    withMethods(
      (
        store,
        loading = inject(LoadingService),
        api = inject(ApiService),
        layout = inject(LayoutService),
      ) => {
        //#region Common Methods

        const loadTaxonomy = () => {
          let domains: Map<string, Observable<unknown>> = new Map([
            [
              DOMAIN_API_REQUESTS.TAXONOMY_MAIN,
              api.get(DOMAINS.TAXONOMY_MAIN.URI, undefined, false),
            ],
            [
              DOMAIN_API_REQUESTS.TAXONOMY_CROSS_STRATEGY_TAGS,
              api.get(
                DOMAINS.TAXONOMY_CROSS_STRATEGY_TAGS.URI,
                undefined,
                false,
              ),
            ],
            [
              DOMAIN_API_REQUESTS.TAXONOMY_ASSET_CLASS_SUBSTRATEGY_TAGS,
              api.get(
                DOMAINS.TAXONOMY_ASSET_CLASS_SUBSTRATEGY_TAGS.URI,
                undefined,
                false,
              ),
            ],
            [
              DOMAIN_API_REQUESTS.TAXONOMY_PRIMARY_REGIONS,
              api.get(DOMAINS.TAXONOMY_PRIMARY_REGIONS.URI, undefined, false),
            ],
          ]);

          loadTaxonomyRx(domains);
        };

        const loadTaxonomyRx = rxMethod<Map<string, Observable<unknown>>>(
          pipe(
            tap(() => loading.addLoadRequest(DOMAIN_API_REQUESTS.TAXONOMY)),
            switchMap((domains) =>
              concat(...domains.values()).pipe(
                toArray(),
                concatMap((results: Array<unknown>) => {
                  initAvailableHierarchyAndTags(domains, results);
                  initTaxonomyViewItems();
                  loading.setLoadResponse(DOMAIN_API_REQUESTS.TAXONOMY);
                  store.finalizeMethod(1);

                  return of(undefined);
                }),
              ),
            ),
          ),
        );

        const initAvailableHierarchyAndTags = (
          domains: Map<string, Observable<unknown>>,
          results: Array<unknown>,
        ) => {
          let domainRequests = Array.from(domains.keys());
          // Set the available Global Taxonomy and Tags
          let availableHierarchy = results?.at(
            domainRequests.indexOf(DOMAIN_API_REQUESTS.TAXONOMY_MAIN),
          ) as Array<ISector>;

          let availableCrossStrategyTags = results?.at(
            domainRequests.indexOf(
              DOMAIN_API_REQUESTS.TAXONOMY_CROSS_STRATEGY_TAGS,
            ),
          ) as Array<ITag>;

          let availableAssetClassSubstrategyTags = results?.at(
            domainRequests.indexOf(
              DOMAIN_API_REQUESTS.TAXONOMY_ASSET_CLASS_SUBSTRATEGY_TAGS,
            ),
          ) as Array<ITag>;

          let availablePrimaryRegions = results?.at(
            domainRequests.indexOf(
              DOMAIN_API_REQUESTS.TAXONOMY_PRIMARY_REGIONS,
            ),
          ) as Array<ITag>;

          //Set the selected Taxonomy Hierarchy
          let iv = store.investmentVehicle()!;

          patchState(store, {
            availableHierarchy,
            availableCrossStrategyTags,
            availableAssetClassSubstrategyTags,
            availablePrimaryRegions,
            selectedAssetClassId: iv.assetClassId!,
            selectedSectorId: iv.sectorId!,
            selectedStrategyId: iv.strategyId!,
            selectedSubstrategyId: iv.substrategyId!,
          });
        };

        const initTaxonomyViewItems = () => {
          layout.viewCategory.set(store.investmentVehicle()!.type);

          //Set layout items
          let universalTagItem = layout
            .availableViewItems()
            ?.find((item) => item.settings.tag === 'Universal Tags');
          universalTagItem?.content
            ?.flatMap((control: TViewItem<unknown>) => control.content)
            ?.forEach((control: TViewItem<unknown>) => {
              if (control) {
                let categoryId =
                  TagCategoryEnum[
                    control.settings.tag as keyof typeof TagCategoryEnum
                  ];
                control.settings.options = [
                  ...store.availableCrossStrategyTags()!,
                  ...store.availableAssetClassSubstrategyTags()!,
                ].filter((tag) => tag.categoryId === categoryId);
              }
            });

          let crossStrategyTagItem = layout
            .availableViewItems()
            ?.find((item) => item.settings.tag === 'Cross Strategy Tags');

          let appliedCrossStrategyTags: Array<TViewItem<unknown>> = [];

          let groupedCrossStrategyTags = Object.entries(
            Object.groupBy(
              store
                .availableCrossStrategyTags()
                ?.filter((tag: Tag) =>
                  tag.applicableStrategies?.some(
                    (strategy) =>
                      strategy.sectorId === store.selectedSectorId() &&
                      strategy.strategyId === store.selectedStrategyId(),
                  ),
                )!,
              ({ categoryName }) => categoryName!,
            ),
          );

          groupedCrossStrategyTags.forEach(([categoryName, tags]) => {
            let item = crossStrategyTagItem?.content?.find(
              (item: TViewItem<unknown>) => item.settings.tag === categoryName,
            );

            if (item) {
              item.settings.optionsSorting = false;
              item.settings.options = tags!;
            } else {
              item = {
                control: 'dropdown',
                settings: {
                  label: categoryName,
                  tag: categoryName,
                  options: tags!,
                  optionsSorting: false,
                },
              };
            }
            appliedCrossStrategyTags.push(item);
          });

          crossStrategyTagItem!.content = appliedCrossStrategyTags;
        };

        //#endregion

        const selectSector = (sectorId: unknown) => {
          //store.investmentVehicle()!.sectorId = sectorId as number;
          patchState(store, { selectedSectorId: sectorId as number });
        };

        const selectStrategy = (strategyId: unknown) => {
          patchState(store, { selectedStrategyId: strategyId as number });
        };

        const selectSubstrategy = (subStrategyId: unknown) => {
          patchState(store, { selectedSubstrategyId: subStrategyId as number });
        };

        const selectTag = (tagId: unknown, tagCategoryName: string) => {
          let categoryId: number = TagCategoryEnum[
            tagCategoryName as keyof typeof TagCategoryEnum
          ] as number;

          if (!tagId) {
            store.investmentVehicle()!.quantumTags = store
              .investmentVehicle()!
              .quantumTags?.filter((tag) => tag?.tagCategory !== categoryId);
            return;
          }

          store.investmentVehicle()!.quantumTags = [
            ...(store
              .investmentVehicle()!
              .quantumTags?.filter((tag) => tag?.tagCategory !== categoryId) ??
              []),
            ...(Array.isArray(tagId) ? tagId : [tagId])?.map((tagId) => ({
              tagCategory: categoryId,
              tagId,
            })),
          ];
        };

        const updateLegalStructure = (value: unknown) => {
          store.investmentVehicle()!.masterFunds = undefined;
          store.investmentVehicle()!.actsAsMasterFund = undefined;
          switch (value as Enums.FundStructureEnum) {
            case Enums.FundStructureEnum.Master_feeder:
            case Enums.FundStructureEnum.Intermediate__Master:
            case Enums.FundStructureEnum.UmbrellaMaster_feeder:
              store.investmentVehicle()!.masterFunds = [new MasterFund()];
              break;
            case Enums.FundStructureEnum.MultipleMasters:
              store.investmentVehicle()!.masterFunds = [
                new MasterFund(),
                new MasterFund(),
              ];
              break;
          }
        };

        const updateEsgOwnership = (value: unknown) => {
          /* selectTag(undefined, 'WomanMinoriyDisabledOwnership');
          const esgOwnership = store.taxonomy
            .universaltags()
            ?.find(
              (sectionGroup) => sectionGroup.label === 'Responsible Investing',
            );
          if (esgOwnership) {
            esgOwnership.categories?.forEach((category) => {
              if (category.id === 'WomanMinoriyDisabledOwnership') {
                category.disabled = !!value;
              }
            });
          } */
        };

        const addMasterFund = () => {
          store.investmentVehicle()!.masterFunds = [
            ...(store.investmentVehicle()!.masterFunds ?? []),
            new MasterFund(),
          ];
        };

        const removeMasterFund = (masterFund: MasterFund) => {
          store.investmentVehicle()!.masterFunds = store
            .investmentVehicle()!
            .masterFunds?.filter((mf: MasterFund) => mf !== masterFund);
          console.log(masterFund);
        };

        return {
          loadTaxonomy,
          selectSector,
          selectStrategy,
          selectSubstrategy,
          selectTag,
          updateLegalStructure,
          updateEsgOwnership,
          addMasterFund,
          removeMasterFund,
        };
      },
    ),
    withHooks({
      onInit(store) {
        store.registerMethod(store.loadTaxonomy, 1);
        console.log(
          `%c TaxonomyStore initialized`,
          'background: #222; color: orange;',
          store,
        );
      },
    }),
  );
}
