@let iv = ivStore.investmentVehicle()!;
@if (iv) {
  @if (
    iv.type === ENUMS.AssetTypeEnum.PrivateEquity ||
    iv.type === ENUMS.AssetTypeEnum.RealAssets
  ) {
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.revenueRangeLow
      "
      [settings]="{
        label: 'GP Revenue Range Low',
        permitZero: true,
        abbr: true,
      }"
    ></number>
    <number
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.revenueRangeHigh
      "
      [settings]="{
        label: 'GP Revenue Range High',
        permitZero: true,
        abbr: true,
      }"
    ></number>
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.ebitdaRangeLow
      "
      [settings]="{
        label: 'GP EBITDA Range Low',
        permitZero: true,
        abbr: true,
      }"
    ></number>
    <number
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.ebitdaRangeHigh
      "
      [settings]="{
        label: 'GP EBITDA Range High',
        permitZero: true,
        abbr: true,
      }"
    ></number>
  }
  @if (iv.type === ENUMS.AssetTypeEnum.RealEstate) {
    <dropdown
      class="c1s1"
      [(value)]="iv.closedEndDetails.closedEndGPTargetInvestmentStat.seedAssets"
      [settings]="{
        label: 'Seed Assets',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
    ></dropdown>
    <number
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat
          .programEquityCommittedSeedAssets
      "
      [settings]="{
        label: 'Program Equity Committed to Seed Assets',
        placeholder: '#',
        permitZero: true,
        abbr: true,
      }"
    ></number>
    <number
      class="c3s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.numSeedInvestments
      "
      [settings]="{
        label: 'Number of Seed Investment',
        permitZero: true,
        abbr: true,
      }"
    ></number>
  }

  <number
    class="c1s1"
    [(value)]="
      iv.closedEndDetails.closedEndGPTargetInvestmentStat
        .numPortfolioCompaniesLow
    "
    [settings]="{
      label: 'Number of Investment Low',
      permitZero: true,
      abbr: true,
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="
      iv.closedEndDetails.closedEndGPTargetInvestmentStat
        .numPortfolioCompaniesHigh
    "
    [settings]="{
      label: 'Number of Investment High',
      permitZero: true,
      abbr: true,
    }"
  ></number>
  <number
    class="c1s1"
    [(value)]="
      iv.closedEndDetails.closedEndGPTargetInvestmentStat
        .enterpriseValueRangeLow
    "
    [settings]="{
      label: ['RealEstate'].includes(iv.type?.replace?.(' ', '') ?? '')
        ? 'GP Gross Investment Size Low'
        : 'GP Enterprise Value Range Low',
      permitZero: true,
      abbr: true,
      tag: 'GP Enterprise Value Range Low',
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="
      iv.closedEndDetails.closedEndGPTargetInvestmentStat
        .enterpriseValueRangeHigh
    "
    [settings]="{
      label: ['RealEstate'].includes(iv.type?.replace?.(' ', '') ?? '')
        ? 'GP Gross Investment Size High'
        : 'GP Enterprise Value Range High',
      permitZero: true,
      abbr: true,
      tag: 'GP Enterprise Value Range High',
    }"
  ></number>
  <number
    class="c1s1"
    [(value)]="
      iv.closedEndDetails.closedEndGPTargetInvestmentStat
        .equityInvestmentRangeLow
    "
    [settings]="{
      label: ['RealEstate'].includes(iv.type?.replace?.(' ', '') ?? '')
        ? 'GP Equity Investment Size Low'
        : 'GP Equity Investment Range Low',
      permitZero: true,
      abbr: true,
      tag: 'GP Equity Investment Range Low',
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="
      iv.closedEndDetails.closedEndGPTargetInvestmentStat
        .equityInvestmentRangeHigh
    "
    [settings]="{
      label: ['RealEstate'].includes(iv.type?.replace?.(' ', '') ?? '')
        ? 'GP Equity Investment Size High'
        : 'GP Equity Investment Range High',
      permitZero: true,
      abbr: true,
      tag: 'GP Equity Investment Range High',
    }"
  ></number>

  @if (
    iv.type === ENUMS.AssetTypeEnum.PrivateEquity ||
    iv.type === ENUMS.AssetTypeEnum.RealAssets
  ) {
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat
          .transactionMultipleLow
      "
      [settings]="{
        label: 'GP Transaction Multiple Range Low (EBITDA)',
        permitZero: true,
        placeholder: 'x',
        suffix: 'x',
      }"
    ></number>
    <number
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat
          .transactionMultipleHigh
      "
      [settings]="{
        label: 'GP Transaction Multiple Range High (EBITDA)',
        permitZero: true,
        placeholder: 'x',
        suffix: 'x',
      }"
    ></number>
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat
          .transactionMultipleRevenueLow
      "
      [settings]="{
        label: 'GP Transaction Multiple Range Low (Revenue)',
        permitZero: true,
        placeholder: 'x',
        suffix: 'x',
      }"
    ></number>
    <number
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat
          .transactionMultipleRevenueHigh
      "
      [settings]="{
        label: 'GP Transaction Multiple Range High (Revenue)',
        permitZero: true,
        placeholder: 'x',
        suffix: 'x',
      }"
    ></number>
  }
  @if (iv.type === ENUMS.AssetTypeEnum.RealEstate) {
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.maxDevelopment
      "
      [settings]="{
        label: 'Max Development',
        placeholder: '%',
        suffix: ' %',
      }"
    ></number>
    <number
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPTargetInvestmentStat.targetHoldingPeriod
      "
      [settings]="{
        label: 'Target Holding Period',
        placeholder: 'years',
        suffix: ' years',
      }"
    ></number>
  }
  @if (
    iv.type === ENUMS.AssetTypeEnum.PrivateEquity ||
    iv.type === ENUMS.AssetTypeEnum.RealAssets
  ) {
    @if (layout.selectedView() === VIEWS.DATA) {
      <number
        class="c1s1"
        [(value)]="
          iv.closedEndDetails.closedEndGPTargetInvestmentStat
            .preferredCompanyValuationLow
        "
        [settings]="{
          label: 'Preferred Company Valuation Low',
          abbr: true,
        }"
      ></number>
      <number
        class="c2s1"
        [(value)]="
          iv.closedEndDetails.closedEndGPTargetInvestmentStat
            .preferredCompanyValuationHigh
        "
        [settings]="{
          label: 'Preferred Company Valuation High',
          abbr: true,
        }"
      ></number>
    }

    <dropdown
      class="c1s1"
      [(value)]="iv.closedEndDetails.closedEndGPTargetInvestmentStat.boardSeats"
      [settings]="{
        label: 'Board Seats',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
    ></dropdown>
  }
}
