import {
  signalStore,
  withState,
  withMethods,
  patchState,
  withComputed,
  withHooks,
} from '@ngrx/signals';

import {
  ClosedEndInterimDate,
  ContractualExtension,
  DOMAIN_API_REQUESTS,
  DOMAINS,
  Enums,
  InvestmentVehicle,
  Leverage,
  Location,
  LpClawbackPerLimit,
  LpClawbackTimeLimit,
  PERIODIC_STREAM_REQUESTS,
  PERIODIC_STREAMS,
  PeriodicStreamType,
  RecyclingProceedsLimit,
  RecyclingTimeLimit,
  SearchEntity,
  SecondaryAsset,
} from '@aksia/models';
import { pipe, switchMap, tap } from 'rxjs';
import { withTaxonomy } from './features/taxonomy.store.feature';
import { withShareClass } from './features/shareclass.store.feature';
import { withStream } from './features/stream.store.feature';
import { computed, inject } from '@angular/core';
import { EntityService, LoadingService } from '@aksia/services';
import { withAsync } from './features/async.store.feature';
import { withValidation } from './features/validation.store.feature';
import { withUtils } from './features/utils.store.feature';
import { withInvestmentVehicleValidators } from './validators/investment-vehicle.store.validators';
import { withTaxonomyValidators } from './validators/taxonomy.store.validators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';

type EntityState = {
  entityId: number | undefined;
  entityTypeId: Enums.EntityTypeEnum;
  entity: InvestmentVehicle | undefined;
  taxonomyIsLoaded?: boolean;
  basicDataIsLoaded?: boolean;
  shareClassesIsLoaded?: boolean;
  streamsIsLoaded?: boolean;
  selectedAumLevel: PeriodicStreamType;
};

export type InvestmentVehicleStoreType = InstanceType<
  typeof InvestmentVehicleStore
>;

export const InvestmentVehicleStore = signalStore(
  { providedIn: 'root' },
  withState<EntityState>({
    entityId: undefined,
    entityTypeId: Enums.EntityTypeEnum.Fund,
    entity: undefined,
    selectedAumLevel: PERIODIC_STREAMS.FUND_AUM,
  }),
  withAsync<InvestmentVehicle>(),
  withUtils<InvestmentVehicle>(),
  withValidation(),
  withComputed((store) => ({
    investmentVehicle: computed(() => store.entity()!),
    fundLevelLeverages: computed(() =>
      store
        .entity()
        ?.leverage?.filter(
          (leverage) => leverage?.leverageType === Enums.LeverageTypeEnum.Fund,
        ),
    ),
    assetLevelLeverages: computed(() =>
      store
        .entity()
        ?.leverage?.filter(
          (leverage) => leverage?.leverageType === Enums.LeverageTypeEnum.Asset,
        ),
    ),
    assetType: computed(() => store.entity()?.type),
    companyHeadquarters: computed(() => {
      let companyHeadquartersString =
        store.entity()?.coInvestmentDetails?.companyHeadquarters;

      let companyHeadquarters = new Location();
      companyHeadquarters.entityId = store.entityId();
      companyHeadquarters.entityTypeId = store.entityTypeId();

      try {
        companyHeadquarters = Object.assign(
          companyHeadquarters,
          companyHeadquartersString
            ? JSON.parse(companyHeadquartersString!)
            : {},
        );
      } catch (error) {
        console.error(error);
      }

      return companyHeadquarters;
    }),
    assetMode: computed(() => (asset: SecondaryAsset) => {
      if (asset.$tags?.has(`@${Enums.AssetModeEnum.Editing}`))
        return Enums.AssetModeEnum.Editing;
      else if (asset.$tags?.has(`@${Enums.AssetModeEnum.Linking}`))
        return Enums.AssetModeEnum.Linking;
      else return Enums.AssetModeEnum.Viewing;
    }),
    streamStartDate: computed(() => {
      return InvestmentVehicle.getStartDate(
        store.entity() as InvestmentVehicle,
      );
    }),
    streamEndDate: computed(() => new Date()),
    streamRequestOptions: computed(() => {
      //if (!store.entity()) return undefined;
      return {
        entityId: store.entityId()!,
        entityTypeId: store.entityTypeId(),
        streamTypes:
          store.entity()?.liquidityStructure ===
          Enums.LiquidityStructureEnum.OpenEnd
            ? [PERIODIC_STREAMS.AUM_EXPAND, PERIODIC_STREAMS.PUBLIC_RETURNS]
            : [PERIODIC_STREAMS.PRIVATE_RETURNS],
        streamRequest:
          store.entity()?.liquidityStructure ===
          Enums.LiquidityStructureEnum.OpenEnd
            ? PERIODIC_STREAM_REQUESTS.HISTORICAL
            : PERIODIC_STREAM_REQUESTS.MINMAX,
        periodicity: Enums.StreamPeriodicityEnum.Monthly.toString(),
        fillMissing:
          store.entity()?.liquidityStructure ===
          Enums.LiquidityStructureEnum.OpenEnd,
      };
    }),
  })),
  withTaxonomy(),
  withTaxonomyValidators(),
  withShareClass(),
  withInvestmentVehicleValidators(),
  withStream(),
  withComputed((store) => {
    const aum = computed(
      () => store.streamEntityMap()[store.selectedAumLevel()],
    );
    const aumLevels = computed(() => [
      {
        label: store.entity()?.managementCompanyName,
        value: PERIODIC_STREAMS.COMPANY_AUM,
      },
      {
        label: store.entity()?.investmentProgramName,
        value: PERIODIC_STREAMS.PROGRAM_AUM,
      },
      {
        label: store.entity()?.name,
        value: PERIODIC_STREAMS.FUND_AUM,
      },
    ]);
    const returns = computed(
      () => store.streamEntityMap()[PERIODIC_STREAMS.PUBLIC_RETURNS],
    );
    const returnsSummary = computed(() => {
      if (!store.streamEntityMap()[PERIODIC_STREAMS.PUBLIC_RETURNS]) return [];
      let currentYear = new Date().getFullYear();
      let ytd = store.streamEntityMap()[PERIODIC_STREAMS.PUBLIC_RETURNS].ytd;
      let minYear = Math.min(
        ...(ytd?.map(
          (ytd) => new Date(ytd.asOf!)?.getFullYear() ?? currentYear,
        ) ?? [currentYear]),
      );
      return Array.from(Array(currentYear - minYear + 1).keys())
        .map(
          (i) =>
            ytd?.find(
              (ytd) => new Date(ytd.asOf!)?.getFullYear() === minYear + i,
            )?.value!,
        )
        .toReversed();
    });
    return {
      aum,
      aumLevels,
      returns,
      returnsSummary,
    };
  }),
  withMethods(
    (
      store,
      loading = inject(LoadingService),
      entity = inject(EntityService),
    ) => {
      //#region Common Methods

      const setEntityId = (entityId: number) => {
        patchState(store, { entityId });
      };

      const initEntities = (iv: InvestmentVehicle) => {
        let ivModel = store.toStoreModel(iv, InvestmentVehicle);
        patchState(store, { entity: ivModel });
      };

      const loadInvestmentVehicle = () => {
        loadInvestmentVehicleRx(store.entityId()!);
      };

      const loadInvestmentVehicleRx = rxMethod<number>(
        pipe(
          tap(() =>
            loading.addLoadRequest(DOMAIN_API_REQUESTS.INVESTMENT_VEHICLE),
          ),
          switchMap((entityId) =>
            entity
              .getEntityById<InvestmentVehicle>(
                entityId!,
                DOMAINS.INVESTMENT_VEHICLE.URI,
              )
              .pipe(
                tap({
                  next: (iv) => {
                    initEntities(iv);
                  },
                  error: (error) => {
                    console.error(
                      `Error loading: ${DOMAIN_API_REQUESTS.INVESTMENT_VEHICLE}`,
                      error,
                    );
                  },
                  finalize: () => {
                    loading.setLoadResponse(
                      DOMAIN_API_REQUESTS.INVESTMENT_VEHICLE,
                    );
                    store.finalizeMethod(0);
                  },
                }),
              ),
          ),
        ),
      );

      const saveInvestmentVehicle = () => {
        let ivErrors = store.getGroupErrors(store.entity()!.$uid!);

        if (ivErrors?.length > 0) {
          console.error(ivErrors);
        } else {
          let { isDirty, dto } = store.modelIsDirty(store.entity()!);
          if (isDirty) {
            console.log('Saving Basic Data...');
            console.log(dto);
          } else {
            console.log('Basic Data have no changes');
          }
        }

        store.saveShareClasses();
        store.saveStreams();
      };

      const updateById = (uid: number) => {
        if (store.investmentVehicle().$uid === uid) {
          updateInvestmentVehicle(store.entity()!);
        } else if (store.selectedClass()?.$uid === uid) {
          store.updateShareClass(store.selectedClass()!);
        }
      };

      const updateInvestmentVehicle = (
        partialIv: Partial<InvestmentVehicle>,
      ) => {
        patchState(store, {
          entity: {
            ...store.entity()!,
            ...partialIv,
          },
        });
        store.updateShareClass(store.selectedClass()!);
      };

      //#endregion

      //#region Historical Info

      const updateCompanyHeadquarters = (value: unknown) => {
        let location = store.companyHeadquarters?.();
        location = {
          entityId: location.entityId,
          entityTypeId: location.entityTypeId,
          ...(value as Partial<Location>),
        };
        store.entity()!.coInvestmentDetails.companyHeadquarters =
          JSON.stringify(location);
      };

      //#endregion

      //#region Leverage

      const updateLeverageLimit = (
        value: unknown,
        leverageType: Enums.LeverageTypeEnum,
      ) => {
        if (value === Enums.SimpleAnswerEnum.Yes) {
          if (
            !store
              .investmentVehicle()
              ?.leverage?.some(
                (l) => l.leverageType === leverageType && !l.$isDeleted,
              )
          )
            addLeverage(leverageType);
        } else {
          store.investmentVehicle()?.leverage?.forEach((leverage: Leverage) => {
            if (leverage.leverageType === leverageType) {
              removeLeverage(leverage);
            }
          });
        }
        if (leverageType === Enums.LeverageTypeEnum.Asset) {
          updateInvestmentVehicle({
            assetLevelLeverageLimit: value as Enums.SimpleAnswerEnum,
          });
        } else {
          updateInvestmentVehicle({
            fundLevelLeverageLimit: value as Enums.SimpleAnswerEnum,
          });
        }
      };

      const updateLeverage = (
        leverage: Leverage,
        field: keyof Leverage,
        value: unknown,
      ) => {
        if (field === 'leverageBasis') {
          let index = store
            .investmentVehicle()
            ?.leverage?.filter(
              (lev: Leverage) => lev.leverageType === leverage.leverageType,
            )
            ?.indexOf(leverage)!;
          let removeValidationsFields: Array<string> = [];

          if (!value) {
            leverage.leveragePercentageMin = undefined;
            leverage.leveragePercentageMax = undefined;
            leverage.leveragePercentageTarget = undefined;
            leverage.leverageMultipleMin = undefined;
            leverage.leverageMultipleMax = undefined;
            leverage.leverageMultipleTarget = undefined;
            leverage.leverageDesc = undefined;

            removeValidationsFields = [
              'Leverage Percentage Min #',
              'Leverage Percentage Max #',
              'Leverage Percentage Target #',
              'Leverage Multiple Min #',
              'Leverage Multiple Max #',
              'Leverage Multiple Target #',
            ];
          } else {
            if (
              ![
                Enums.LeverageBasisEnum.leverage_to_value__LTV____,
                Enums.LeverageBasisEnum.leverage_to_cost__LTC____,
              ].includes(value as Enums.LeverageBasisEnum)
            ) {
              leverage.leveragePercentageMin = undefined;
              leverage.leveragePercentageTarget = undefined;

              removeValidationsFields = [
                ...removeValidationsFields,
                'Leverage Percentage Min #',
                'Leverage Percentage Target #',
              ];
            }
            if (
              ![Enums.LeverageBasisEnum.calculated_on_other_amount].includes(
                value as Enums.LeverageBasisEnum,
              )
            ) {
              leverage.leverageDesc = undefined;
            }
            if (
              [
                Enums.LeverageBasisEnum.debt_to_equity__D___E____,
                Enums.LeverageBasisEnum.net_debt___EBITDA,
              ].includes(value as Enums.LeverageBasisEnum)
            ) {
              leverage.leveragePercentageMax = undefined;
              removeValidationsFields = [
                ...removeValidationsFields,
                'Leverage Percentage Max #',
              ];
            } else {
              leverage.leverageMultipleMin = undefined;
              leverage.leverageMultipleMax = undefined;
              leverage.leverageMultipleTarget = undefined;
              removeValidationsFields = [
                ...removeValidationsFields,
                'Leverage Multiple Min #',
                'Leverage Multiple Max #',
                'Leverage Multiple Target #',
              ];
            }
          }

          removeValidationsFields.forEach((field) => {
            store.removeValidation({
              validationId: {
                groupId: store.investmentVehicle()!.$uid!,
                id: leverage.$uid!,
              },
              tag: `${Enums.LeverageTypeEnum[leverage.leverageType!]} ${field}${index + 1}`,
            });
          });
        }
      };

      const addLeverage = (leverageType: Enums.LeverageTypeEnum) => {
        let leverage = new Leverage();
        leverage.leverageType = leverageType;
        leverage.fundId = store.investmentVehicle()?.fundId;

        store.investmentVehicle()!.leverage = [
          ...(store.investmentVehicle()?.leverage ?? []),
          leverage,
        ];

        updateInvestmentVehicle(store.entity()!);
      };

      const removeLeverage = (leverage: Leverage) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle().$uid!,
            id: leverage.$uid!,
          },
        });
        store.investmentVehicle()!.leverage = store
          .investmentVehicle()
          ?.leverage?.filter((l: Leverage) => l !== leverage);
        updateInvestmentVehicle(store.entity()!);
      };

      //#endregion

      //#region Fund Timing

      const updateContractualFinalCloseDateType = (value: unknown) => {
        if (!value) {
          store.investmentVehicle()!.closedEndDetails.contractualFinalCloseDateDesc =
            undefined;
          store.investmentVehicle()!.closedEndDetails.contractualFinalCloseDate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.contractualFinalCloseMonths =
            undefined;
          store.investmentVehicle()!.closedEndDetails.contractualFinalCloseUnlimitedExtensions =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Contractual Final Close Months',
          });

          store
            .investmentVehicle()!
            .closedEndDetails.contractualFinalCloseExtensions?.forEach(
              (ext: ContractualExtension) => {
                removeContractualFinalCloseExtension(ext);
              },
            );
        } else {
          if (
            store.investmentVehicle()!.closedEndDetails
              .contractualFinalCloseExtensions!.length < 1
          ) {
            addContractualFinalCloseExtension();
          }
          if (value === Enums.ContractualDateTypeEnum.Fixed_date) {
            store.investmentVehicle()!.closedEndDetails.contractualFinalCloseMonths =
              undefined;
            store.investmentVehicle()!.closedEndDetails.contractualFinalCloseDateDesc =
              undefined;

            store.removeValidation({
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Contractual Final Close Months',
            });
          } else {
            store.investmentVehicle()!.closedEndDetails.contractualFinalCloseDateDesc =
              undefined;
            store.investmentVehicle()!.closedEndDetails.contractualFinalCloseDate =
              undefined;
          }
        }
      };

      const addContractualFinalCloseExtension = () => {
        store.investmentVehicle()!.closedEndDetails.contractualFinalCloseExtensions =
          [
            ...(store.investmentVehicle()!.closedEndDetails
              .contractualFinalCloseExtensions ?? []),
            new ContractualExtension(),
          ];
      };

      const removeContractualFinalCloseExtension = (
        extension: ContractualExtension,
      ) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()?.$uid!,
            id: extension.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.contractualFinalCloseExtensions =
          store
            .investmentVehicle()!
            .closedEndDetails.contractualFinalCloseExtensions?.filter(
              (ext: ContractualExtension) => ext !== extension,
            );
      };

      const updateContractualInvestmentPeriodExpirationYears = (
        value: unknown,
      ) => {
        if (!value) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualInvestmentPeriodExpirationType =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualInvestmentPeriodExpirationDate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualInvestmentPeriodExpirationDesc =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.hasContractualInvestmentPeriodUnlimitedExtensions =
            undefined;

          store
            .investmentVehicle()!
            .closedEndDetails.contractualInvestmentPeriodExtensions?.forEach(
              (ext: ContractualExtension) => {
                removeContractualInvestmentPeriodExtension(ext);
              },
            );
        } else if (
          store.investmentVehicle()!.closedEndDetails
            .contractualInvestmentPeriodExtensions?.length! < 1
        ) {
          addContractualInvestmentPeriodExtension();
        }
      };

      const updateContractualInvestmentPeriodExpirationType = (
        value: unknown,
      ) => {
        if (value !== Enums.PeriodTypeEnum.FollowingOtherDate) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualInvestmentPeriodExpirationDate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualInvestmentPeriodExpirationDesc =
            undefined;
        }
      };

      const addContractualInvestmentPeriodExtension = () => {
        store.investmentVehicle()!.closedEndDetails.contractualInvestmentPeriodExtensions =
          [
            ...(store.investmentVehicle()!.closedEndDetails
              .contractualInvestmentPeriodExtensions ?? []),
            new ContractualExtension(),
          ];
      };

      const removeContractualInvestmentPeriodExtension = (
        extension: ContractualExtension,
      ) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()?.$uid!,
            id: extension.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.contractualInvestmentPeriodExtensions =
          store
            .investmentVehicle()!
            .closedEndDetails.contractualInvestmentPeriodExtensions?.filter(
              (ext: ContractualExtension) => ext !== extension,
            );
      };

      const updateContractualTermExpirationYears = (value: unknown) => {
        if (!value) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDesc =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.hasContractualTermUnlimitedExtensions =
            undefined;

          store
            .investmentVehicle()!
            .closedEndDetails.contractualTermExtensions?.forEach(
              (ext: ContractualExtension) => {
                removeContractualTermExtension(ext);
              },
            );
        } else if (
          store.investmentVehicle()!.closedEndDetails.contractualTermExtensions
            ?.length! < 1
        ) {
          addContractualTermExtension();
        }
      };

      const updateContractualTermExpirationType = (value: unknown) => {
        if (value !== Enums.PeriodTypeEnum.FollowingOtherDate) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDesc =
            undefined;
        }
      };

      const addContractualTermExtension = () => {
        store.investmentVehicle()!.closedEndDetails.contractualTermExtensions =
          [
            ...(store.investmentVehicle()!.closedEndDetails
              .contractualTermExtensions ?? []),
            new ContractualExtension(),
          ];
      };

      const removeContractualTermExtension = (
        extension: ContractualExtension,
      ) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()?.$uid!,
            id: extension.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.contractualTermExtensions =
          store
            .investmentVehicle()!
            .closedEndDetails.contractualTermExtensions?.filter(
              (ext: ContractualExtension) => ext !== extension,
            );
      };

      //#endregion

      //#region Capital Call Procedure

      const updateHasCommitmentCap = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.commitmentCap =
            undefined;
          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Commitment Cap',
          });

          store.validate({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Commitment Target',
            value:
              store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm
                .commitmentTarget,
          });
        }
      };

      const updateMinGPCommitmentType = (value: unknown) => {
        if (!value) {
          store.investmentVehicle()!.closedEndDetails.closedEndStructure.minGPCommitmentCashless =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndStructure.minGPCommitment =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndStructure.minGPCommitmentAmount =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Minimum GP Commitment',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Minimum GP Commitment Amount',
            },
          ]);
        }
        if (value === Enums.MinGPCommitmentEnum.Amount) {
          store.investmentVehicle()!.closedEndDetails.closedEndStructure.minGPCommitment =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Minimum GP Commitment',
          });
        } else if (value === Enums.MinGPCommitmentEnum.Percentage) {
          store.investmentVehicle()!.closedEndDetails.closedEndStructure.minGPCommitmentAmount =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Minimum GP Commitment Amount',
          });
        }
      };

      const updateFollowOnInvestmentsPerLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.followOnInvestmentsPerLimitRate =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Follow-on Investments Per Limit Rate',
          });
        }
      };

      const updateFollowOnInvestmentsTimeLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.followOnInvestmentsTimeLimitMonths =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Follow-on Investments Time Limit Months',
          });
        }
      };

      const updateInvestmentsInProgressTimeLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.investmentsInProgressTimeLimitMonths =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Investments in Progress Time Limit Months',
          });
        }
      };

      const updateSubsequentCloseInterest = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestRate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndex =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndexDesc =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Subsequent Close Interest Rate',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Subsequent Close Interest Type',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Subsequent Close Interest Index',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Subsequent Close Interest Index Description',
            },
          ]);
        }
      };

      const updateSubsequentCloseInterestType = (value: unknown) => {
        if (
          value !== Enums.RateIndexEnum.Index &&
          value !== Enums.RateIndexEnum.IndexPlusFixedRate
        ) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndex =
            undefined;
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndexDesc =
            undefined;
        }
        if (
          value !== Enums.RateIndexEnum.FixedRate &&
          value !== Enums.RateIndexEnum.IndexPlusFixedRate
        ) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestRate =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Subsequent Close Interest Rate',
          });
        }
      };

      const updateSubsequentCloseInterestIndex = (value: unknown) => {
        if (value !== Enums.SubClInterestIndexEnum.Other) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndexDesc =
            undefined;
        }
      };

      //#endregion

      //#region Organizational Expenses

      const updateOrganizationalExpenseCapType = (value: unknown) => {
        if (value === Enums.MinGPCommitmentEnum.Percentage) {
          store.investmentVehicle()!.closedEndDetails.organizationalExpenseCapAmount =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Organizational Expense Cap Amount',
          });
        } else if (value === Enums.MinGPCommitmentEnum.Amount) {
          store.investmentVehicle()!.closedEndDetails.organizationalExpenseCapRate =
            undefined;

          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Organizational Expense Cap Rate',
          });
        } else if (!value) {
          store.investmentVehicle()!.closedEndDetails.organizationalExpenseCapAmount =
            undefined;
          store.investmentVehicle()!.closedEndDetails.organizationalExpenseCapRate =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Organizational Expense Cap Amount',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Organizational Expense Cap Rate',
            },
          ]);
        }
      };

      //#endregion

      //#region Recycling

      const updateHasRecycling = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.hasTimeLimit =
            undefined;

          updateHasTimeLimit(undefined);

          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.hasRecyclingLimit =
            undefined;

          updateHasRecyclingLimit(undefined);

          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.hasProceedsLimit =
            undefined;

          updateHasProceedsLimit(undefined);
        }
      };

      const updateHasTimeLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store
            .investmentVehicle()!
            .closedEndDetails.recyclingTimeLimits?.forEach(
              (recycle: RecyclingTimeLimit) => {
                removeRecyclingTimeLimit(recycle);
              },
            );
        } else {
          addRecyclingTimeLimit();
        }
      };

      const updateTimeLimitType = (
        recycle: RecyclingTimeLimit,
        value: unknown,
      ) => {
        if (
          value !==
          Enums.RecyclingTimeLimitEnum
            .Only_for_investments_realized_within_a_specific_timeframe
        ) {
          recycle.monthsFromAcquisition = undefined;
          store.removeValidation({
            validationId: recycle.$uid!,
            tag: 'Months From Acquisition',
          });
        }
      };

      const updateHasRecyclingLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.closedEndKeyTerm.recyclingCommitmentsRate =
            undefined;
          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Recycling Commitments Rate',
          });
        }
      };

      const updateHasProceedsLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store
            .investmentVehicle()!
            .closedEndDetails.recyclingProceedsLimits?.forEach(
              (recycle: RecyclingProceedsLimit) => {
                removeRecyclingProceedsLimit(recycle);
              },
            );
        } else {
          addRecyclingProceedsLimit();
        }
      };

      const updateProceedsType = (
        recycle: RecyclingProceedsLimit,
        value: unknown,
      ) => {
        if (value !== Enums.RecyclingProceedsLimitEnum.Other) {
          recycle.proceedsDesc = undefined;
          store.removeValidation({
            validationId: recycle.$uid!,
            tag: 'Proceeds Description',
          });
        }
      };

      const addRecyclingTimeLimit = () => {
        store.investmentVehicle()!.closedEndDetails.recyclingTimeLimits = [
          ...(store.investmentVehicle()!.closedEndDetails.recyclingTimeLimits ??
            []),
          new RecyclingTimeLimit(),
        ];
      };

      const removeRecyclingTimeLimit = (
        recyclingTimeLimit: RecyclingTimeLimit,
      ) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()!.$uid!,
            id: recyclingTimeLimit.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.recyclingTimeLimits = store
          .investmentVehicle()!
          .closedEndDetails.recyclingTimeLimits?.filter(
            (recycle: RecyclingTimeLimit) => recycle !== recyclingTimeLimit,
          );

        updateInvestmentVehicle(store.entity()!);

        store
          .investmentVehicle()
          ?.closedEndDetails.recyclingTimeLimits?.forEach((recycle, index) => {
            store.validate({
              validationId: {
                groupId: store.investmentVehicle()!.$uid!,
                id: recycle.$uid!,
              },
              tag: 'Time Limit Type #' + (index + 1),
              value: recycle.timeLimitType,
            });
          });
      };

      const addRecyclingProceedsLimit = () => {
        store.investmentVehicle()!.closedEndDetails.recyclingProceedsLimits = [
          ...(store.investmentVehicle()!.closedEndDetails
            .recyclingProceedsLimits ?? []),
          new RecyclingProceedsLimit(),
        ];
      };

      const removeRecyclingProceedsLimit = (
        recyclingProceedsLimit: RecyclingProceedsLimit,
      ) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()!.$uid!,
            id: recyclingProceedsLimit.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.recyclingProceedsLimits =
          store
            .investmentVehicle()!
            .closedEndDetails.recyclingProceedsLimits?.filter(
              (rpl: RecyclingProceedsLimit) => rpl !== recyclingProceedsLimit,
            );
        updateInvestmentVehicle(store.entity()!);

        store
          .investmentVehicle()
          ?.closedEndDetails.recyclingTimeLimits?.forEach((recycle, index) => {
            store.validate({
              validationId: {
                groupId: store.investmentVehicle()!.$uid!,
                id: recycle.$uid!,
              },
              tag: 'Proceeds Type #' + (index + 1),
              value: recycle.timeLimitType,
            });
          });
      };

      //#endregion

      //#region LP Clawback

      const updateLpClawbackPerLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store
            .investmentVehicle()!
            .closedEndDetails.lpClawbackPerLimits?.forEach(
              (limit: LpClawbackPerLimit) => removeLpClawbackPerLimit(limit),
            );
        } else {
          addLpClawbackPerLimit();
        }
      };

      const addLpClawbackPerLimit = () => {
        store.investmentVehicle()!.closedEndDetails.lpClawbackPerLimits = [
          ...(store.investmentVehicle()!.closedEndDetails.lpClawbackPerLimits ??
            []),
          new LpClawbackPerLimit(),
        ];
      };

      const removeLpClawbackPerLimit = (
        lpClawbackPerLimit: LpClawbackPerLimit,
      ) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()!.$uid!,
            id: lpClawbackPerLimit.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.lpClawbackPerLimits = store
          .investmentVehicle()!
          .closedEndDetails.lpClawbackPerLimits?.filter(
            (lpcl: LpClawbackPerLimit) => lpcl !== lpClawbackPerLimit,
          );
        updateInvestmentVehicle(store.entity()!);

        store
          .investmentVehicle()
          ?.closedEndDetails.lpClawbackPerLimits?.forEach((clawback, index) => {
            store.validate({
              validationId: {
                groupId: store.investmentVehicle()!.$uid!,
                id: clawback.$uid!,
              },
              tag: 'LP Clawback Calculated On Type #' + (index + 1),
              value: clawback.lpClawbackCalculatedOnType,
            });
          });
      };

      const updateLpClawbackTimeLimit = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store
            .investmentVehicle()!
            .closedEndDetails.lpClawbackTimeLimits?.forEach(
              (limit: LpClawbackTimeLimit) => {
                removeLpClawbackTimeLimit(limit);
              },
            );
        } else {
          addLpClawbackTimeLimit();
        }
      };

      const addLpClawbackTimeLimit = () => {
        store.investmentVehicle()!.closedEndDetails.lpClawbackTimeLimits = [
          ...(store.investmentVehicle()!.closedEndDetails
            .lpClawbackTimeLimits ?? []),
          new LpClawbackTimeLimit(),
        ];
      };

      const removeLpClawbackTimeLimit = (limit: LpClawbackTimeLimit) => {
        store.removeValidation({
          validationId: {
            groupId: store.investmentVehicle()!.$uid!,
            id: limit.$uid!,
          },
        });
        store.investmentVehicle()!.closedEndDetails.lpClawbackTimeLimits = store
          .investmentVehicle()!
          .closedEndDetails.lpClawbackTimeLimits?.filter(
            (lpcl: LpClawbackTimeLimit) => lpcl !== limit,
          );
        updateInvestmentVehicle(store.entity()!);

        store
          .investmentVehicle()
          ?.closedEndDetails.lpClawbackTimeLimits?.forEach(
            (clawback, index) => {
              store.validate({
                validationId: {
                  groupId: store.investmentVehicle()!.$uid!,
                  id: clawback.$uid!,
                },
                tag: 'LP Clawback Time Limit From #' + (index + 1),
                value: clawback.lpClawbackTimeLimitFrom,
              });
            },
          );
      };

      //#endregion

      //#region Removal Termination Rights

      const updateForFaultGpImRemoval = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.forFaultGpImVoteThresholdRate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.carriedInterestReduction =
            undefined;

          updateCarriedInterestReduction(undefined);

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'For Fault GP/IM Vote Threshold Rate',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Carried Interest Reduction',
            },
          ]);
        }
      };

      const updateCarriedInterestReduction = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.carriedInterestReductionRate =
            undefined;
          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Carried Interest Reduction Rate',
          });
        }
      };

      const updateForFaultInvPeriodTermination = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.forFaultInvPeriodVoteThresholdRate =
            undefined;
          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'For Fault Investment Period Vote Threshold Rate',
          });
        }
      };

      const updateForFaultFundTermination = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.forFaultFundVoteThresholdRate =
            undefined;
          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'For Fault Fund Vote Threshold Rate',
          });
        }
      };

      const updateNoFaultGpImRemoval = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.noFaultGpImVoteThresholdRate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.noFaultGpImTimeLimit =
            undefined;
          store.investmentVehicle()!.closedEndDetails.noFaultGpImPenalty =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault GP/IM Vote Threshold Rate',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault GP/IM Time Limit',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault GP/IM Penalty',
            },
          ]);
        }
      };

      const updateNoFaultInvPeriodTermination = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.noFaultInvPeriodVoteThresholdRate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.noFaultInvPeriodTimeLimit =
            undefined;
          store.investmentVehicle()!.closedEndDetails.noFaultInvPeriodPenalty =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault Investment Period Vote Threshold Rate',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault Investment Period Time Limit',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault Investment Period Penalty',
            },
          ]);
        }
      };

      const updateNoFaultFundTermination = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.noFaultFundVoteThresholdRate =
            undefined;
          store.investmentVehicle()!.closedEndDetails.noFaultFundTimeLimit =
            undefined;
          store.investmentVehicle()!.closedEndDetails.noFaultFundPenalty =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault Fund Vote Threshold Rate',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault Fund Time Limit',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'No Fault Fund Penalty',
            },
          ]);
        }
      };

      const updateKeyPersonProvision = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.automaticSuspensionLift =
            undefined;
          store.investmentVehicle()!.closedEndDetails.keyPersonProVoteThresholdRate =
            undefined;

          store.removeValidation([
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Automatic Suspension Lift',
            },
            {
              validationId: store.investmentVehicle()!.$uid!,
              tag: 'Key Person Provision Vote Threshold Rate',
            },
          ]);
        }
      };

      //#endregion

      //#region Reporting

      const updateAccountingMethod = (value: unknown) => {
        if (value !== Enums.AccountingMethodEnum.Other) {
          store.investmentVehicle()!.closedEndDetails.accountingMethodDescription =
            undefined;
        }
      };

      //#endregion

      //#region Successor Fund Provision

      const updateSuccessorFundProvision = (value: unknown) => {
        if (value !== Enums.SimpleAnswerEnum.Yes) {
          store.investmentVehicle()!.closedEndDetails.successorFundProvisionThresholdRate =
            undefined;
          store.removeValidation({
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Successor Fund Provision Threshold Rate',
          });
        }
      };

      //#endregion

      //#region Fund Terms

      const addIntermCloseDate = () => {
        store.investmentVehicle()!.closedEndDetails.interimDates = [
          ...(store.investmentVehicle()!.closedEndDetails.interimDates ?? []),
          new ClosedEndInterimDate(),
        ];
      };

      const removeIntermCloseDate = (interimDate: ClosedEndInterimDate) => {
        let i = store.filteredInterimDates()?.indexOf(interimDate)!;
        store.removeValidation({
          validationId: {
            id: interimDate.$uid!,
            groupId: store.investmentVehicle()!.$uid!,
          },
          tag: `Interim Closed Date #${i + 1}`,
        });
        interimDate.$isDeleted = true;
        store.investmentVehicle()!.closedEndDetails.interimDates = store
          .investmentVehicle()!
          .closedEndDetails.interimDates?.filter(
            (itemDate: ClosedEndInterimDate) => !itemDate.$isDeleted,
          );

        updateInvestmentVehicle({
          closedEndDetails: {
            ...store.investmentVehicle()!.closedEndDetails,
            ...{
              interimDates: [
                ...(store.investmentVehicle()!.closedEndDetails.interimDates ??
                  []),
              ],
            },
          },
        });

        store.validate([
          {
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'First Close Date',
            value: store.investmentVehicle()!.firstCloseDate,
          },
          {
            validationId: store.investmentVehicle()!.$uid!,
            tag: 'Final Close Date',
            value: store.investmentVehicle()!.finalCloseDate,
          },
        ]);
      };

      //#endregion

      //#region Assets

      const addSecondaryAsset = () => {
        store.investmentVehicle()!.secondaryDetails.assets = [
          ...(store.investmentVehicle()!.secondaryDetails.assets ?? []),
          new SecondaryAsset(),
        ];
      };

      const removeSecondaryAsset = (asset: SecondaryAsset) => {
        store.removeValidation({ validationId: asset.$uid! });
        store.investmentVehicle()!.secondaryDetails.assets = store
          .investmentVehicle()!
          .secondaryDetails.assets?.filter(
            (sa: SecondaryAsset) => sa !== asset,
          );
      };

      const setSecondaryAsset = (asset: SecondaryAsset, value: unknown) => {
        let searchResult = value as SearchEntity;
        if (value) {
          asset.assetId = +searchResult.entityId!;
          asset.assetName = searchResult.name;
          toggleSecondaryAsset(asset, Enums.AssetModeEnum.Viewing);
        }
      };

      const toggleSecondaryAsset = (
        asset: SecondaryAsset,
        mode: Enums.AssetModeEnum,
      ) => {
        if (!asset.$tags) {
          asset.$tags = new Set<string>();
        }
        asset.$tags?.clear();
        asset.$tags?.add(`@${mode}`);
      };

      //#endregion

      //#region Real Estate Details

      const updateFixedFloatingDebt = (value: unknown) => {
        if (!value) {
          store.entity()!.coInvestmentDetails.floatingRate = undefined;
          store.entity()!.coInvestmentDetails.fixedRate = undefined;
        } else if (value === Enums.FixedFloatingDebtEnum.Fixed) {
          store.entity()!.coInvestmentDetails.floatingRate = undefined;
        } else if (value === Enums.FixedFloatingDebtEnum.Floating) {
          store.entity()!.coInvestmentDetails.fixedRate = undefined;
        }
      };

      //#endregion

      //#region AUM

      const setSelectedAumLevel = (value: unknown) => {
        patchState(store, { selectedAumLevel: value as PeriodicStreamType });
      };

      //#endregion

      return {
        loadInvestmentVehicle,
        saveInvestmentVehicle,
        updateInvestmentVehicle,
        updateById,
        setEntityId,

        //Common Investment Vehicle
        updateLeverageLimit,
        updateLeverage,
        addLeverage,
        removeLeverage,

        //Closed End Investment Vehicle
        updateContractualFinalCloseDateType,
        addContractualFinalCloseExtension,
        removeContractualFinalCloseExtension,
        updateContractualInvestmentPeriodExpirationYears,
        updateContractualInvestmentPeriodExpirationType,
        addContractualInvestmentPeriodExtension,
        removeContractualInvestmentPeriodExtension,
        updateContractualTermExpirationYears,
        updateContractualTermExpirationType,
        addContractualTermExtension,
        removeContractualTermExtension,
        updateHasCommitmentCap,
        updateMinGPCommitmentType,
        updateFollowOnInvestmentsPerLimit,
        updateFollowOnInvestmentsTimeLimit,
        updateInvestmentsInProgressTimeLimit,
        updateSubsequentCloseInterest,
        updateSubsequentCloseInterestType,
        updateSubsequentCloseInterestIndex,
        updateOrganizationalExpenseCapType,
        updateHasRecycling,
        updateHasTimeLimit,
        updateTimeLimitType,
        updateHasRecyclingLimit,
        updateHasProceedsLimit,
        updateProceedsType,
        addRecyclingTimeLimit,
        removeRecyclingTimeLimit,
        addRecyclingProceedsLimit,
        removeRecyclingProceedsLimit,
        updateLpClawbackPerLimit,
        addLpClawbackPerLimit,
        removeLpClawbackPerLimit,
        updateLpClawbackTimeLimit,
        addLpClawbackTimeLimit,
        removeLpClawbackTimeLimit,
        updateForFaultGpImRemoval,
        updateCarriedInterestReduction,
        updateForFaultInvPeriodTermination,
        updateForFaultFundTermination,
        updateNoFaultGpImRemoval,
        updateNoFaultInvPeriodTermination,
        updateNoFaultFundTermination,
        updateKeyPersonProvision,
        updateAccountingMethod,
        updateSuccessorFundProvision,
        addIntermCloseDate,
        removeIntermCloseDate,

        //Co-Investment
        updateCompanyHeadquarters,
        updateFixedFloatingDebt,

        //Secondary
        addSecondaryAsset,
        removeSecondaryAsset,
        setSecondaryAsset,
        toggleSecondaryAsset,

        //AUM
        setSelectedAumLevel,
      };
    },
  ),
  withHooks({
    onInit(store) {
      store.registerMethod(store.loadInvestmentVehicle, 0);
      console.log(
        `%c InvestmentVehicleStore initialized`,
        'background: #222; color: orange;',
        store,
      );
    },
  }),
);
