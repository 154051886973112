@let iv = ivStore.investmentVehicle()!;
@if (iv) {
  @if (layout.selectedView() !== VIEWS.SECONDARY) {
    <date
      class="c1s1"
      [(value)]="iv.coInvestmentDetails.financialsReportDate"
      [settings]="{
        label: 'Reporting Date',
        placeholder: 'mm/dd/yyyy',
      }"
    ></date>
    <text
      class="c2s1"
      [(value)]="iv.coInvestmentDetails.valuationMetric"
      [settings]="{
        label: 'Valuation Metric',
      }"
    ></text>
  }

  <number
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.enterpriseValue"
    [settings]="{
      label: 'Enterprise Value / Asset Value',
      tag: 'Enterprise Value',
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.equityValue"
    [settings]="{ label: 'Equity Value' }"
  ></number>
  <number
    class="c3s1"
    [(value)]="iv.coInvestmentDetails.netDebt"
    [settings]="{ label: 'Net Debt' }"
  ></number>
  <number
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.revenue"
    [settings]="{ label: 'Revenue' }"
  ></number>
  <number
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.ebitdanoi"
    [settings]="{ label: 'EBITDA/NOI' }"
  ></number>
  <number
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.evSales"
    [settings]="{
      label: 'EV/Sales',
      placeholder: 'x',
      suffix: 'x',
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.evebitda"
    [settings]="{
      label: 'EV/EBITDA',
      placeholder: 'x',
      suffix: 'x',
    }"
  ></number>

  @if (layout.selectedView() !== VIEWS.SECONDARY) {
    <number
      class="c1s1"
      [(value)]="iv.coInvestmentDetails.revenueCAGR"
      [settings]="{
        label: 'Revenue CAGR',
        placeholder: '%',
        suffix: '%',
      }"
    ></number>
    <text
      class="c2s1"
      [(value)]="iv.coInvestmentDetails.revenueCAGRTimePeriod"
      [settings]="{
        label: 'Revenue CAGR Time Period',
        placeholder: 'Time Period',
      }"
    ></text>
    <number
      class="c3s1"
      [(value)]="iv.coInvestmentDetails.ebitdacagr"
      [settings]="{
        label: 'EBITDA CAGR',
        placeholder: '%',
        suffix: '%',
      }"
    ></number>
    <text
      class="c4s1"
      [(value)]="iv.coInvestmentDetails.ebitdacagrTimePeriod"
      [settings]="{
        label: 'EBITDA CAGR Time Period',
        placeholder: 'Time Period',
      }"
    ></text>
    <number
      class="c1s1"
      [(value)]="iv.coInvestmentDetails.loanValue"
      [settings]="{
        label: 'Loan to Value',
        placeholder: '%',
        suffix: ' %',
      }"
    ></number>
    <number
      class="c2s1"
      [(value)]="iv.coInvestmentDetails.attachmentPoint"
      [settings]="{
        label: 'Attachment Point',
        placeholder: '%',
        suffix: ' %',
        permitZero: true,
      }"
    ></number>
    <number
      class="c3s1"
      [(value)]="iv.coInvestmentDetails.detachmentPoint"
      [settings]="{
        label: 'Detachment Point',
        placeholder: '%',
        suffix: ' %',
        permitZero: true,
      }"
    ></number>
  }
}
