import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  IconComponent,
  NumberComponent,
  SectionComponent,
  TextComponent,
} from '@aksia/ui';
import {
  AuthenticationStepEnum,
  Credentials,
  UTILS,
} from '@aksia/infrastructure';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { StaticContentDirective } from '../../section-contents/base.section.content';

@Component({
  selector: 'page-content[uipagecontent="login"]',
  imports: [
    CommonModule,
    SectionComponent,
    TextComponent,
    NumberComponent,
    IconComponent,
    ButtonComponent,
  ],
  templateUrl: './login.content.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageContent extends StaticContentDirective {
  //#region Injectors

  protected readonly auth = inject(AuthenticationService);
  private readonly router = inject(Router);

  //#endregion

  //#region Host Bindings

  @HostBinding('class')
  uiclass = 'grid-cols-3 grid-rows-3';

  //#endregion

  //#region Properties

  readonly authStepEnum = AuthenticationStepEnum;
  readonly version = environment.version;
  readonly credentials = signal(new Credentials());

  title = computed(() => {
    switch (this.credentials().step) {
      case AuthenticationStepEnum.IsAuthenticated:
        return 'Welcome';
      case AuthenticationStepEnum.Verifying2FA:
      case AuthenticationStepEnum.Requested2FA:
        return '2FA';
      default:
        return 'Sign In';
    }
  });

  btnTitle = computed(() => {
    switch (this.credentials().step) {
      case AuthenticationStepEnum.Requested2FA:
        return 'Verify Code';
      case AuthenticationStepEnum.Verifying2FA:
      case AuthenticationStepEnum.VerifyingCredentials:
        return 'Please Wait...';
      default:
        return 'Sign In';
    }
  });

  signInIsDisabled = computed(() => {
    let isDisabled =
      this.credentials().step === AuthenticationStepEnum.VerifyingCredentials ||
      this.credentials().step === AuthenticationStepEnum.Verifying2FA ||
      UTILS.OBJECT.isEmpty(this.credentials().maxusername) ||
      UTILS.OBJECT.isEmpty(this.credentials().maxpassword);
    return isDisabled;
  });

  passwordVisibility = signal(false);

  //#endregion

  //#region Methods

  protected updateUsername(value: unknown) {
    this.credentials.set({
      ...this.credentials(),
      ...{ maxusername: value as string },
    });
  }

  protected updatePassword(value: unknown) {
    this.credentials.set({
      ...this.credentials(),
      ...{ maxpassword: value as string },
    });
  }

  private redirect() {
    const { redirect } = window.history.state;
    this.router.navigateByUrl(redirect || '/home');
  }

  //#endregion
}
