const hostUrl = 'https://o2-experimental.aksia.com';

export const environment = {
  //General
  title: 'O2',
  production: false,
  version: '0.0.1',
  environment_variable: 'staging',
  storage_type: 3, // 1: Database, 2: Local Storage, 3: Both

  //URLs
  app_url: `${hostUrl}/`,
  service_api_url: `https://services-staging.aksia.com/base`,
  mock_service_api_url: `${hostUrl}/api`,
  max_url: `https://max-staging.aksia.com`,
  google_api_url:
    'https://maps.googleapis.com/maps/api/js?libraries=places&callback=Function.prototype&language=en-US',

  //API keys
  google_api_key: 'AIzaSyA5RUC7PCdpbA0_RIwLDyYB5_Df1F3PHqY',

  //Security
  omit_user_groups: true,
};
