@let iv = ivStore.investmentVehicle()!; @if (iv) {
<text
  class="c1s6"
  [(value)]="iv.shortDescription"
  [settings]="{
        label: 'Internal Notes',
        multiline: true,
        showCharCount: true,
        labelTip: 'Up to 2000 characters.',
      }"
></text>
}
