import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import { DateComponent, DropdownComponent, TextComponent } from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Date Info"]',
  imports: [DropdownComponent, TextComponent, DateComponent],
  templateUrl: './date-info.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInfoSectionContent extends StaticContentDirective {}

export const InvestmentVehicleOpenEndSectionContents = [DateInfoSectionContent];
