@let selectedOpenClass = ivStore.selectedOpenClass()!;
@if (selectedOpenClass) {
  <section-group
    [settings]="{
      label: 'Lockup',
    }"
  >
    <dropdown
      class="c1s2"
      [(value)]="selectedOpenClass.hasLockup"
      [settings]="{
        label: 'Lock-up',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has Lockup',
      }"
      [validators]="ivStore.hasLockup_validators()"
      [validation.id]="selectedOpenClass.$uid!"
      (valueChange)="ivStore.updateHasLockup($event)"
    ></dropdown>
    @if (selectedOpenClass.hasLockup === ENUMS.SimpleAnswerEnum.Yes) {
      @for (
        lockup of ivStore.filteredLockups();
        track lockup.$uid;
        let i = $index, c =
  $count, first = $first
      ) {
        <number
          class="c1s2"
          [(value)]="lockup.lockupDurationMinRange"
          [settings]="{
            label: 'From',
            placeholder: 'in months',
            prefix: 'month ',
            tag: 'Lockup Duration Min Range #' + (i + 1),
          }"
          [validators]="ivStore.lockupDurationMinRange_validators()(lockup)"
          [validation.id]="{
            groupId: selectedOpenClass.$uid!,
            id: lockup.$uid!,
          }"
          [validation.chain]="
            ivStore.lockupDurationMinRange_validator_chain()(lockup)
          "
        ></number>
        <number
          class="c3s2"
          [(value)]="lockup.lockupDurationMaxRange"
          [settings]="{
            label: 'To',
            placeholder: 'in months',
            prefix: 'month ',
            tag: 'Lockup Duration Max Range #' + (i + 1),
          }"
          [validators]="ivStore.lockupDurationMaxRange_validators()(lockup)"
          [validation.id]="{
            groupId: selectedOpenClass.$uid!,
            id: lockup.$uid!,
          }"
          [validation.chain]="
            ivStore.lockupDurationMaxRange_validator_chain()(lockup)
          "
        ></number>

        @if (i === 0) {
          <dropdown
            class="c5s1"
            [(value)]="lockup.lockupType"
            [settings]="{
              label: 'Type',
              options: ENUMS.LockUpEnum.toOptions,
              optionsFiltering: false,
              tag: 'Lockup Type #' + (i + 1),
            }"
            [validators]="ivStore.lockupType_validators()"
            [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: lockup.$uid!,
            }"
            (valueChange)="ivStore.updateLockupType(lockup, $event)"
          ></dropdown>
        } @else {
          <div class="c5s1">Soft</div>
        }

        <check
          class="c6s1"
          [(value)]="lockup.lockupRolling"
          [settings]="{ label: 'Rolling' }"
        ></check>

        @if (lockup.lockupType === ENUMS.LockUpEnum.Soft || i > 0) {
          <number
            class="c7s1"
            [(value)]="lockup.lockupFee"
            [settings]="{
              label: 'Exit Fee',
              placeholder: '%',
              suffix: ' %',
              tag: 'Lockup Fee #' + (i + 1),
            }"
            [validators]="ivStore.lockupFee_validators()(lockup)"
            [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: lockup.$uid!,
            }"
            [validation.chain]="ivStore.lockupFee_validator_chain()(lockup)"
          ></number>
        }
        @if (i === 0) {
          <check
            class="c8s2"
            [(value)]="selectedOpenClass.lockupEndAllow"
            [settings]="{
              label: 'Allow redemptions on lockup end',
              tag: 'Lockup End Allow',
            }"
          ></check>
        }
        <div class="flex c10s1">
          @if (i > 0) {
            <btn
              [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Remove Lockup #' + (i + 1),
              }"
              (click)="ivStore.removeLockup(lockup)"
            ></btn>
          }
          @if (i === c - 1) {
            <btn
              [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add Lockup',
              }"
              (click)="ivStore.addLockup()"
            ></btn>
          }
        </div>
      }
    }
  </section-group>
  <section-group
    [settings]="{
      label: 'Frequency',
    }"
  >
    @if (
      selectedOpenClass.redemptionTermsFrequency !==
      ENUMS.CalendarUnitEnum.Custom
    ) {
      <number
        class="c1s1"
        [(value)]="selectedOpenClass.redemptionTermsFrequencyAmount"
        [settings]="{
          label: 'Frequency',
          labelFocused: 'Every',
          placeholder: '# of yrs/mns',
          tag: 'Redemption Terms Frequency Amount',
        }"
        [validators]="ivStore.redemptionTermsFrequencyAmount_validators()"
        [validation.id]="selectedOpenClass.$uid!"
        (valueChange)="
          ivStore.updateRedemptionTerms(
            'redemptionTermsFrequencyAmount',
            $event
          )
        "
      ></number>
      <dropdown
        class="c2s1"
        [(value)]="selectedOpenClass.redemptionTermsFrequency"
        [settings]="{
          label: '',
          placeholder: 'in',
          options: ENUMS.CalendarUnitEnum.toOptions,
          optionsFiltering: false,
          tag: 'Redemption Terms Frequency',
        }"
        [validators]="ivStore.redemptionTermsFrequency_validators()"
        [validation.id]="selectedOpenClass.$uid!"
        [validation.chain]="ivStore.redemptionTermsFrequency_validator_chain()"
        (valueChange)="
          ivStore.updateRedemptionTerms('redemptionTermsFrequency', $event)
        "
      ></dropdown>
    }
    @if (!ivStore.hideRedemptionRest()) {
      <dropdown
        class="c3s3"
        [(value)]="selectedOpenClass.redemptionTermsFrequencyDay"
        [settings]="{
          label: 'redeeming on',
          options: ENUMS.RedemptionDayEnum.toOptions,
          optionsFiltering: false,
          tag: 'Redemption Terms Frequency Day',
        }"
        [validators]="ivStore.redemptionTermsFrequencyDay_validators()"
        [validation.id]="selectedOpenClass.$uid!"
        (valueChange)="
          ivStore.updateRedemptionTerms('redemptionTermsFrequencyDay', $event)
        "
      ></dropdown>
    }
    @if (
      selectedOpenClass.redemptionTermsFrequencyAmount ||
      selectedOpenClass.redemptionTermsFrequency ===
        ENUMS.CalendarUnitEnum.Custom
    ) {
      <number
        class="c6s1"
        [class.middle]="
          selectedOpenClass.redemptionTermsFrequency ===
          ENUMS.CalendarUnitEnum.Custom
        "
        [(value)]="selectedOpenClass.redemptionTermsNotice"
        [settings]="{
          label: 'without notice',
          labelFocused: 'with notice',
          tag: 'Redemption Terms Notice',
        }"
        [validators]="ivStore.redemptionTermsNotice_validators()"
        [validation.id]="selectedOpenClass.$uid!"
        (valueChange)="
          ivStore.updateRedemptionTerms('redemptionTermsNotice', $event)
        "
      ></number>
      <dropdown
        class="c7s2"
        [class.middle]="
          selectedOpenClass.redemptionTermsFrequency ===
          ENUMS.CalendarUnitEnum.Custom
        "
        [(value)]="selectedOpenClass.redemptionTermsNoticePeriod"
        [settings]="{
          label: '',
          placeholder: 'in',
          options: ENUMS.ActiveTimePeriodEnum.toOptions,
          optionsFiltering: false,
          tag: 'Redemption Terms Notice Period',
        }"
        [validators]="ivStore.redemptionTermsNoticePeriod_validators()"
        [validation.id]="selectedOpenClass.$uid!"
        (valueChange)="
          ivStore.updateRedemptionTerms('redemptionTermsNoticePeriod', $event)
        "
      ></dropdown>
      <number
        class="c9s2"
        [class.middle]="
          selectedOpenClass.redemptionTermsFrequency ===
          ENUMS.CalendarUnitEnum.Custom
        "
        [(value)]="selectedOpenClass.redemptionFee"
        [settings]="{
          label: 'without redemption fee',
          labelFocused: 'with redemption fee',
          placeholder: '%',
          suffix: ' %',
          tag: 'Redemption Fee',
        }"
        [validators]="ivStore.redemptionFee_validators()"
        [validation.id]="selectedOpenClass.$uid!"
        (valueChange)="ivStore.updateRedemptionTerms('redemptionFee', $event)"
      ></number>
    }
  </section-group>
  <section-group
    [settings]="{
      label: 'Gate',
    }"
  >
    <dropdown
      class="c1s1"
      [(value)]="selectedOpenClass.hasGate"
      [settings]="{
        label: 'Gate',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
      [validators]="ivStore.hasGate_validators()"
      [validation.id]="selectedOpenClass.$uid"
      (valueChange)="ivStore.updateHasGate($event)"
    ></dropdown>

    @if (selectedOpenClass.hasGate === ENUMS.SimpleAnswerEnum.Yes) {
      @for (
        gate
  of ivStore.filteredGates();
        track gate.$uid;
        let i = $index, c = $count, first
  = $first
      ) {
        <dropdown
          class="c1s1"
          [(value)]="gate.gateLevel"
          [settings]="{
            selectedOptionStrategy: 'removed',
            label: 'Gate Level #' + (i + 1),
            options: ENUMS.FundLevelsEnum.toOptions,
            optionsFiltering: false,
            tag: 'Gate Level #' + (i + 1),
          }"
          [validators]="ivStore.gateLevel_validators()"
          [validation.id]="{
            groupId: selectedOpenClass.$uid!,
            id: gate.$uid!,
          }"
          [validation.chain]="ivStore.gateLevel_validator_chain()(i)"
          (valueChange)="ivStore.updateGateLevel(gate, $event)"
        ></dropdown>

        @if (
          gate.gateLevel === ENUMS.FundLevelsEnum.MasterFund ||
          gate.gateLevel === ENUMS.FundLevelsEnum.Fund ||
          gate.gateLevel === ENUMS.FundLevelsEnum.Class ||
          gate.gateLevel === ENUMS.FundLevelsEnum.Investor
        ) {
          <number
            class="c2s1"
            [(value)]="gate.gatePercentage"
            [settings]="{
              label: 'Gate Percentage #' + (i + 1),
              placeholder: '%',
              suffix: ' %',
              tag: 'Gate Percentage #' + (i + 1),
            }"
            [validators]="ivStore.gatePercentage_validators()"
            [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: gate.$uid!,
            }"
          ></number>
        } @else if (
          gate.gateLevel === ENUMS.FundLevelsEnum.Investor_MasterFund
        ) {
          <number
            class="c2s1"
            [(value)]="gate.gateMasterFundPercentage"
            [settings]="{
              label: 'Master Fund Percentage #' + (i + 1),
              placeholder: '%',
              suffix: ' %',
              tag: 'Gate Master Fund Percentage #' + (i + 1),
            }"
            [validators]="ivStore.gateMasterFundPercentage_validators()"
            [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: gate.$uid!,
            }"
          ></number>
        } @else if (gate.gateLevel === ENUMS.FundLevelsEnum.Investor_Fund) {
          <number
            class="c2s1"
            [(value)]="gate.gateFundPercentage"
            [settings]="{
              label: 'Fund Percentage #' + (i + 1),
              placeholder: '%',
              suffix: ' %',
              tag: 'Gate Fund Percentage #' + (i + 1),
            }"
            [validators]="ivStore.gateFundPercentage_validators()"
            [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: gate.$uid!,
            }"
          ></number>
        }
        @if (
          gate.gateLevel === ENUMS.FundLevelsEnum.Investor_Fund ||
          gate.gateLevel === ENUMS.FundLevelsEnum.Investor_MasterFund
        ) {
          <number
            class="c3s1"
            [(value)]="gate.gateInvestorPercentage"
            [settings]="{
              label: 'Investor Percentage #' + (i + 1),
              placeholder: '%',
              suffix: ' %',
              tag: 'Gate Investor Percentage #' + (i + 1),
            }"
            [validators]="ivStore.gateInvestorPercentage_validators()"
            [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: gate.$uid!,
            }"
          ></number>
        }

        <div class="flex c4s1">
          @if (i > 0) {
            <btn
              [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Remove Gate #' + (i + 1),
              }"
              (click)="ivStore.removeGate(gate)"
            ></btn>
          }
          @if (i === c - 1) {
            <btn
              [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add Gate',
              }"
              (click)="ivStore.addGate()"
            ></btn>
          }
        </div>
      }
    }

    <number
      class="c1s1"
      [(value)]="selectedOpenClass!.holdback"
      [settings]="{
        label: 'Holdback',
        placeholder: '%',
        suffix: ' %',
        prefix: 'up to ',
        tag: 'Holdback',
      }"
      [validators]="ivStore.holdback_validators()"
      [validation.id]="selectedOpenClass.$uid"
    ></number>
  </section-group>
}
