import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LocationComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';

let controlImports = [
  TextComponent,
  CheckComponent,
  NumberComponent,
  DropdownComponent,
  DateComponent,
  ButtonComponent,
  LocationComponent,
];

@Component({
  selector: '[uisectioncontent="Carried Interest"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    NumberComponent,
    TextComponent,
    ButtonComponent,
  ],
  templateUrl: './carried-interest.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarriedInterestSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Lp Commitment"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './lp-commitment.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LpCommitmentSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Management Fee"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    DateComponent,
    NumberComponent,
    TextComponent,
    ButtonComponent,
  ],
  templateUrl: './management-fee.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagementFeeSectionContent extends StaticContentDirective {}

export const ShareclassClosedEndSectionContents = [
  CarriedInterestSectionContent,
  LpCommitmentSectionContent,
  ManagementFeeSectionContent,
];
