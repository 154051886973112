import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  inject,
  input,
  reflectComponentType,
  Type,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SectionComponent,
  ButtonComponent,
  UiPermissionDirective,
} from '@aksia/ui';
import { IPageSettings, PageSettings } from '@aksia/infrastructure';
import { AuthenticationService } from '../../services/authentication.service';
import { InvestmentVehicleStore } from '../../stores/investment-vehicle.store';
import { CommonSectionContents } from '../../section-contents/common/common.section.content';
import { InvestmentVehicleCommonSectionContents } from '../../section-contents/investment-vehicle/common/investment-vehicle-common.section.content';
import { StaticContentDirective } from '../../section-contents/base.section.content';
import { InvestmentVehicleClosedEndSectionContents } from '../../section-contents/investment-vehicle/closed-end/investment-vehicle-closed-end.section.content';
import { ShareclassClosedEndSectionContents } from '../../section-contents/shareclass/closed-end/shareclass-closed-end.section.content';
import { InvestmentVehicleOpenEndSectionContents } from '../../section-contents/investment-vehicle/open-end/investment-vehicle-open-end.section.content';
import { ShareclassOpenEndSectionContents } from '../../section-contents/shareclass/open-end/shareclass-open-end.section.content';
import { TaxonomySectionContents } from '../../section-contents/taxonomy/taxonomy.section.content';
import { InvestmentVehicleCoInvestmentSectionContents } from '../../section-contents/investment-vehicle/co-investment/investment-vehicle-co-investment.section.content';
import { InvestmentVehicleSecondarySectionContents } from '../../section-contents/investment-vehicle/secondary/investment-vehicle-secondary.section.content';

@Component({
  selector: 'page-content[uipagecontent="investment-vehicle"]',
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    TaxonomySectionContents,
    CommonSectionContents,
    InvestmentVehicleCommonSectionContents,
    InvestmentVehicleOpenEndSectionContents,
    InvestmentVehicleClosedEndSectionContents,
    InvestmentVehicleCoInvestmentSectionContents,
    InvestmentVehicleSecondarySectionContents,
    ShareclassOpenEndSectionContents,
    ShareclassClosedEndSectionContents,
  ],
  template: `
    <section
      class="unstyled"
      [settings]="{ headerIsHidden: true }"
      [contentCssClass]="'flex place-content-end py-2'"
    >
      <btn
        class="md secondary"
        [settings]="{
          label: 'Download View',
          icon: ICONS.download,
          iconSize: 'large',
          tag: 'Downlad View',
        }"
        (click)="layout.downloadView()"
      ></btn>
      <btn
        class="md secondary"
        [settings]="{
          label: 'Download',
          icon: ICONS.download,
          iconSize: 'large',
          tag: 'Downlad One Pager',
        }"
      ></btn>
      <btn
        class="md secondary"
        [settings]="{
          label: 'Upload',
          icon: ICONS.upload,
          iconSize: 'large',
          tag: 'Upload One Pager',
        }"
      ></btn>
    </section>
    @if (layout.layoutIsReady()) {
      @for (item of layout.selectedViewItems(); track getSectionId($index)) {
        <section
          [settings]="item.settings"
          [class]="item.class"
          [contentCssClass]="item.contentCssClass"
          [sectionContentComponent]="
            sectionContentMap.get(item.settings.tag) ?? null
          "
        ></section>
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: UiPermissionDirective,
      inputs: ['uipermission'],
    },
  ],
})
export class InvestmentVehiclePageContent extends StaticContentDirective {
  //#region Injections

  protected readonly auth = inject(AuthenticationService);
  protected readonly store = inject(InvestmentVehicleStore);
  protected readonly permDir = inject(UiPermissionDirective);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uitag') get uitag() {
    return this.permDir.tag();
  }

  //#endregion

  //#region Properties

  settings = input<PageSettings, IPageSettings>(new PageSettings(), {
    transform: (settings: IPageSettings) => this.initSettings(settings),
  });

  sectionContentMap: Map<string, Type<any> | null> = new Map();

  //#endregion

  protected initSettings(settings: IPageSettings) {
    return Object.assign(new PageSettings(), settings);
  }

  constructor() {
    super();
    [
      ...TaxonomySectionContents,
      ...CommonSectionContents,
      ...InvestmentVehicleCommonSectionContents,
      ...InvestmentVehicleOpenEndSectionContents,
      ...InvestmentVehicleClosedEndSectionContents,
      ...InvestmentVehicleCoInvestmentSectionContents,
      ...InvestmentVehicleSecondarySectionContents,
      ...ShareclassOpenEndSectionContents,
      ...ShareclassClosedEndSectionContents,
    ].forEach((sectionContent) => {
      const metadata = reflectComponentType(sectionContent as Type<any>);
      const selector = this.normalizeSelector(metadata?.selector);
      this.sectionContentMap.set(selector!, sectionContent);
    });

    effect(() => this.permDir.tag.set(this.layout.selectedView() as string));
  }

  getSectionId(index: number) {
    return this.ivStore.entityId()! + index;
  }

  private normalizeSelector(selector?: string) {
    if (!selector) return null;
    selector = selector
      .toLowerCase()
      .replace('[uisectioncontent=', '')
      .replace(']', '')
      .replaceAll('"', '')
      .replace(/([^a-z])([a-z])(?=[a-z]{2})|^([a-z])/g, (_, g1, g2, g3) =>
        typeof g1 === 'undefined' ? g3.toUpperCase() : g1 + g2.toUpperCase(),
      );
    return selector;
  }
}
