import { signalStoreFeature, type, withComputed } from '@ngrx/signals';
import { ManagementCompany } from '@aksia/models';
import { computed, Signal } from '@angular/core';
import { AksiaValidators, IValidationRequest } from '@aksia/infrastructure';

export function withCompanyValidators<EntityValidators>() {
  return signalStoreFeature(
    {
      computed: type<{
        company: Signal<ManagementCompany | undefined>;
      }>(),
      methods: type<{
        removeValidation(
          request: IValidationRequest | Array<IValidationRequest>,
        ): void;
      }>(),
    },
    withComputed((store) => ({
      dateEstablished_validators: computed(() => [
        AksiaValidators.maxDate(new Date(), {
          isWarning: true,
          userMessage: 'Is usually not occuring in the future',
        }),
      ]),
      address_validators: computed(() => [AksiaValidators.required()]),
      staffAsOf_validators: computed(() => [
        AksiaValidators.required({
          isWarning: true,
          isDisabled:
            !store.company?.()?.totalStaff &&
            !store.company?.()?.nonInvestmentStaff,
        }),
      ]),
      totalStaff_validators: computed(() => [
        AksiaValidators.min(store.company()?.nonInvestmentStaff),
      ]),
      nonInvestmentStaff_validators: computed(() => [
        AksiaValidators.max(store.company()?.totalStaff),
      ]),
    })),
  );
}
