@let iv = ivStore.investmentVehicle()!; @let selectedClosedClass =
ivStore.selectedClosedClass()!; @if (iv && selectedClosedClass) {
<number
  class="c1s1"
  [(value)]="selectedClosedClass.minimumLPCommitment"
  [settings]="{
        label: 'Minimum LP Commitment',
        abbr: true,
        placeholder: 'in million/billion',
      }"
  [validators]="ivStore.minimumLPCommitment_validators()"
  [validation.id]="{
        groupId: iv.$uid!,
        id: selectedClosedClass.$uid!,
      }"
></number>
<dropdown
  class="c2s1"
  [(value)]="ivStore.selectedClosedClass()!.minimumLPCommitmentCurrency"
  [settings]="{
        label: 'Currency',
        labelFocused: ' ',
        placeholder: 'Currency',
        options: ENUMS.CurrencyEnum.toOptions,
        tag: 'Minimum LP Commitment Currency',
      }"
  [validators]="ivStore.minimumLPCommitmentCurrency_validators()"
  [validation.id]="{
        groupId: iv.$uid!,
        id: selectedClosedClass.$uid!,
      }"
></dropdown>
}
