import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  IconComponent,
  LocationComponent,
  ModalComponent,
  NumberComponent,
  SectionGroupComponent,
  SpreadsheetComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Capital Call Procedure"]',
  imports: [NumberComponent, TextComponent, DropdownComponent, CheckComponent],
  templateUrl: './capital-call-procedure.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapitalCallProcedureSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Fund Terms"]',
  imports: [
    NumberComponent,
    TextComponent,
    DropdownComponent,
    DateComponent,
    ButtonComponent,
    CheckComponent,
  ],
  templateUrl: './fund-terms.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundTermsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Fund Timing"]',
  imports: [
    SectionGroupComponent,
    ButtonComponent,
    CheckComponent,
    DropdownComponent,
    NumberComponent,
    TextComponent,
    DateComponent,
  ],
  templateUrl: './fund-timing.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundTimingSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Gp Led Details"]',
  imports: [DropdownComponent, CheckComponent, NumberComponent],
  templateUrl: './gp-led-details.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GpLedDetailsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Investment Statistics"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './investment-statistics.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentStatisticsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Key Considerations"]',
  imports: [DropdownComponent, CheckComponent],
  templateUrl: './key-considerations.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyConsiderationsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Key Terms"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    DateComponent,
    ButtonComponent,
    CheckComponent,
    NumberComponent,
    TextComponent,
    CheckComponent,
  ],
  templateUrl: './key-terms.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyTermsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Lp Clawback"]',
  imports: [ButtonComponent, DropdownComponent, NumberComponent],
  templateUrl: './lp-clawback.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LpClawbackSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Organizational Expense Cap"]',
  imports: [NumberComponent, DropdownComponent],
  templateUrl: './organizational-expense-cap.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationalExpenseCapSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Recycling"]',
  imports: [ButtonComponent, TextComponent, DropdownComponent, NumberComponent],
  templateUrl: './recycling.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecyclingSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Removal Termination Rights"]',
  imports: [NumberComponent, DropdownComponent],
  templateUrl: './removal-termination-rights.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemovalTerminationRightsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Return Profile"]',
  imports: [
    DropdownComponent,
    TextComponent,
    SectionGroupComponent,
    SpreadsheetComponent,
    ModalComponent,
    IconComponent,
    ButtonComponent,
    NumberComponent,
    DateComponent,
  ],
  templateUrl: './return-profile.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnProfileSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Successor Fund Provision"]',
  imports: [NumberComponent, DropdownComponent],
  templateUrl: './successor-fund-provision.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessorFundProvisionSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Syndication Capacity Notes"]',
  imports: [TextComponent, NumberComponent, DropdownComponent],
  templateUrl: './syndication-capacity-notes.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyndicationCapacityNotesSectionContent extends StaticContentDirective {}

export const InvestmentVehicleClosedEndSectionContents = [
  CapitalCallProcedureSectionContent,
  FundTermsSectionContent,
  FundTimingSectionContent,
  GpLedDetailsSectionContent,
  InvestmentStatisticsSectionContent,
  KeyConsiderationsSectionContent,
  KeyTermsSectionContent,
  LpClawbackSectionContent,
  OrganizationalExpenseCapSectionContent,
  RecyclingSectionContent,
  RemovalTerminationRightsSectionContent,
  ReturnProfileSectionContent,
  SuccessorFundProvisionSectionContent,
  SyndicationCapacityNotesSectionContent,
];
