@let selectedOpenClass = ivStore.selectedOpenClass()!; @if (selectedOpenClass) {
<section-group
  [settings]="{
        label: 'Management',
      }"
>
  <dropdown
    class="c1s1"
    [(value)]="selectedOpenClass.hasManagementFee"
    [settings]="{
          label: 'Management Fee',
          options: ENUMS.SimpleAnswerPassEnum.toOptions,
          optionsFiltering: false,
          tag: 'Has Management Fee',
        }"
    [validators]="ivStore.hasManagementFee_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updateHasManagementFee($event)"
  ></dropdown>

  @if ( selectedOpenClass.hasManagementFee ===
  ENUMS.SimpleAnswerPassEnum.PassThrough ) {
  <dropdown
    class="c2s1"
    [(value)]="selectedOpenClass.managementFeePassThrough"
    [settings]="{
            label: 'Pass Through',
            options: ENUMS.PassThroughManagementFeeTypeEnum.toOptions,
            tag: 'Pass Through',
          }"
  ></dropdown>
  } @else if ( selectedOpenClass.hasManagementFee ===
  ENUMS.SimpleAnswerPassEnum.Yes ) {
  <dropdown
    class="c1s1"
    [(value)]="selectedOpenClass.mgmtFeeRateType"
    [settings]="{
            label: 'Rate Type',
            options: ENUMS.MgmtFeeRateTypeEnum.toOptions,
            optionsFiltering: false,
            tag: 'Management Fee Rate Type',
          }"
    [validators]="ivStore.mgmtFeeRateType_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updateMgmtFeeRateType($event)"
  ></dropdown>

  @for ( feeRate of ivStore.filteredOpenMgmtFeeRates(); track feeRate.$uid; let
  i = $index, c = $count ) {
  <number
    class="c2s1"
    [(value)]="feeRate.mgmtFeeRatePercentage"
    [settings]="{
              label: c === 1 ? 'Fee Rate' : 'Fee Rate #' + (i + 1),
              placeholder: '%',
              suffix: ' %',
              tag: 'Management Fee Rate Percentage #' + (i + 1),
            }"
    [validators]="ivStore.mgmtFeeRatePercentage_validators()(feeRate)"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: feeRate.$uid!,
            }"
    [validation.chain]="
              ivStore.mgmtFeeRatePercentage_validator_chain()(feeRate)
            "
  ></number>

  @if ( selectedOpenClass.mgmtFeeRateType ===
  ENUMS.MgmtFeeRateTypeEnum.BlendedRate ) {
  <number
    class="c3s1"
    [(value)]="feeRate.mgmtFeeRateAmountFrom"
    [settings]="{
                label: c === 1 ? 'From' : 'From #' + (i + 1),
                placeholder: 'in million/billion',
                abbr: true,
                tag: 'Management Fee Rate Amount From #' + (i + 1),
              }"
    [validators]="ivStore.mgmtFeeRateAmountFrom_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.mgmtFeeRateAmountFrom_validator_chain()(feeRate)
              "
  ></number>
  <number
    class="c4s1"
    [(value)]="feeRate.mgmtFeeRateAmountTo"
    [settings]="{
                label: c === 1 ? 'To' : 'To #' + (i + 1),
                placeholder: 'in million/billion',
                abbr: true,
                tag: 'Management Fee Rate Amount To #' + (i + 1),
              }"
    [validators]="ivStore.mgmtFeeRateAmountTo_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.mgmtFeeRateAmountTo_validator_chain()(feeRate)
              "
  ></number>
  <div class="c5s1">
    @if (i > 0) {
    <btn
      [settings]="{
                    icon: ICONS.remove,
                    iconOnly: true,
                    iconSize: 'normal',
                    tag: 'Remove Management Fee Rate #' + (i + 1),
                  }"
      (click)="ivStore.removeOpenMgmtFeeRate(feeRate)"
    ></btn>
    } @if (i === c - 1) {
    <btn
      [settings]="{
                    icon: ICONS.add,
                    iconOnly: true,
                    iconSize: 'normal',
                    tag: 'Add Management Fee Rate',
                  }"
      (click)="ivStore.addOpenMgmtFeeRate()"
    ></btn>
    }
  </div>
  } }

  <dropdown
    [class]="
            selectedOpenClass.mgmtFeeRateType ===
            ENUMS.MgmtFeeRateTypeEnum.SingleRate
              ? 'c3s1'
              : 'c1s1'
          "
    [(value)]="selectedOpenClass.payable"
    [settings]="{
            label: 'Payable',
            options: ENUMS.MgmtFeeFrequencyEnum.toOptions,
            optionsFiltering: false,
            tag: 'Payable',
          }"
    [validators]="ivStore.payable_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updatePayable($event)"
  ></dropdown>

  @if (selectedOpenClass.payable === ENUMS.MgmtFeeFrequencyEnum.Other) {
  <text
    [class]="
              selectedOpenClass.mgmtFeeRateType ===
              ENUMS.MgmtFeeRateTypeEnum.SingleRate
                ? 'c4s1'
                : 'c2s1'
            "
    [(value)]="selectedOpenClass.payableDescription"
    [settings]="{
              label: 'Description',
              tag: 'Payable Description',
            }"
    [validators]="ivStore.payableDescription_validators()"
    [validation.id]="selectedOpenClass.$uid"
  >
  </text>
  }

  <dropdown
    class="c1s1"
    [(value)]="selectedOpenClass.hasManagementFeeDiscounts"
    [settings]="{
            label: 'Discount',
            options: ENUMS.SimpleAnswerEnum.toOptions,
            optionsFiltering: false,
            tag: 'Has Open Management Fee Discounts',
          }"
    [validators]="ivStore.hasManagementFeeDiscounts_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updateHasManagementFeeDiscounts($event)"
  ></dropdown>

  @for ( discount of ivStore.filteredOpenMgmtFeeDiscounts(); track
  discount.$uid; let i = $index, c = $count ) {
  <dropdown
    class="c2s1"
    [(value)]="discount.discountType"
    [settings]="{
              label: c > 1 ? 'Discount Type #' + (i + 1) : 'Discount Type',
              options: ENUMS.DiscountTypeEnum.toOptions,
              optionsFiltering: false,
              tag: 'Management Fee Discount Type #' + (i + 1),
            }"
    [validators]="ivStore.openMgmtDiscountType_validators()"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: discount.$uid!,
            }"
  ></dropdown>
  <number
    class="c3s1"
    [(value)]="discount.discountRate"
    [settings]="{
              label: c > 1 ? 'Fee Reduction #' + (i + 1) : 'Fee Reduction',
              placeholder: '%',
              suffix: ' %',
              labelTip: '0.1% = 10 basis points',
              tag: 'Management Fee Discount Rate #' + (i + 1),
            }"
    [validators]="ivStore.openMgmtDiscountRate_validators()"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: discount.$uid!,
            }"
  ></number>
  <text
    class="c4s2"
    [(value)]="discount.discountDesc"
    [settings]="{
              label: c > 1 ? 'Eligibility #' + (i + 1) : 'Eligibility',
              tag: 'Management Fee Discount Desc #' + (i + 1),
            }"
    [validators]="ivStore.openMgmtDiscountDesc_validators()"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: discount.$uid!,
            }"
  ></text>

  <div class="c6s1">
    @if (i > 0) {
    <btn
      [settings]="{
                  icon: ICONS.remove,
                  iconOnly: true,
                  iconSize: 'normal',
                  tag: 'Remove Management Fee Discount #' + (i + 1),
                }"
      (click)="ivStore.removeOpenManagementFeeDiscount(discount)"
    ></btn>
    } @if (i === c - 1) {
    <btn
      [settings]="{
                  icon: ICONS.add,
                  iconOnly: true,
                  iconSize: 'normal',
                  tag: 'Add Management Fee Discount',
                }"
      (click)="ivStore.addOpenManagementFeeDiscount()"
    ></btn>
    }
  </div>
  } }
</section-group>
<section-group
  [settings]="{
        label: 'Incentive',
      }"
>
  <dropdown
    class="c1s2"
    [(value)]="selectedOpenClass.hasIncentiveFee"
    [settings]="{
          label: 'Incentive Fee',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          optionsFiltering: false,
          tag: 'Has Incentive Fee',
        }"
    [validators]="ivStore.hasIncentiveFee_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updateHasIncentiveFee($event)"
  ></dropdown>

  @if (selectedOpenClass.hasIncentiveFee === ENUMS.SimpleAnswerEnum.Yes) {
  <dropdown
    [class]="
            selectedOpenClass.incFeeRateType !==
            ENUMS.IncFeeRateTypeEnum.SlidingScaleRate
              ? 'c1s1'
              : 'c1s2'
          "
    [(value)]="selectedOpenClass.incFeeRateType"
    [settings]="{
            label: 'Rate Type',
            options: ENUMS.IncFeeRateTypeEnum.toOptions,
            optionsFiltering: false,
            tag: 'Incentive Fee Rate Type',
          }"
    [validators]="ivStore.incFeeRateType_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updateIncFeeRateType($event)"
  ></dropdown>
  } @if (selectedOpenClass.incFeeRateType) { @for ( feeRate of
  ivStore.filteredIncFeeRates(); track feeRate.$uid; let i = $index, c = $count
  ) { @if ( selectedOpenClass.incFeeRateType !==
  ENUMS.IncFeeRateTypeEnum.SlidingScaleRate ) {
  <number
    class="c2s1"
    [(value)]="feeRate.incFeeRatePercentage"
    [settings]="{
                placeholder: '%',
                suffix: ' %',
                label: c === 1 ? 'Fee Rate' : 'Fee Rate #' + (i + 1),
                tag: 'Incentive Fee Rate Percentage #' + (i + 1),
              }"
    [validators]="ivStore.incFeeRatePercentage_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.incFeeRatePercentage_validator_chain()(feeRate)
              "
  ></number>
  <dropdown
    class="c3s1"
    [(value)]="feeRate.hasHurdle"
    [settings]="{
                label: c === 1 ? 'Hurdle' : 'Hurdle #' + (i + 1),
                options: ENUMS.SimpleAnswerEnum.toOptions,
                optionsFiltering: false,
                tag: 'Has Hurdle #' + (i + 1),
              }"
    [validators]="ivStore.hasHurdle_validators()"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    (valueChange)="ivStore.updateHasHurdle(feeRate, $event)"
  ></dropdown>

  @if (feeRate.hasHurdle === ENUMS.SimpleAnswerEnum.Yes) {
  <dropdown
    class="c4s1"
    [(value)]="feeRate.hurdleRateType"
    [settings]="{
                  label: c === 1 ? 'Hurdle Type' : 'Hurdle Type #' + (i + 1),
                  options: ENUMS.RateIndexEnum.toOptions,
                  optionsFiltering: false,
                  tag: 'Hurdle Rate Type #' + (i + 1),
                }"
    [validators]="ivStore.hurdleRateType_validators()"
    [validation.id]="{
                  groupId: selectedOpenClass.$uid!,
                  id: feeRate.$uid!,
                }"
    (valueChange)="ivStore.updateHurdleRateType(feeRate, $event)"
  ></dropdown>
  } @if ( feeRate.hurdleRateType === ENUMS.RateIndexEnum.Index ||
  feeRate.hurdleRateType === ENUMS.RateIndexEnum.IndexPlusFixedRate ) {
  <dropdown
    [class]="
                  feeRate.hurdleIndex === ENUMS.HurdleIndexEnum.Other
                    ? 'c5s1'
                    : 'c5s3'
                "
    [(value)]="feeRate.hurdleIndex"
    [settings]="{
                  label: c === 1 ? 'Index' : 'Index #' + (i + 1),
                  options: ENUMS.HurdleIndexEnum.toOptions,
                  tag: 'Hurdle Index #' + (i + 1),
                }"
  ></dropdown>
  } @if ( feeRate.hurdleRateType !== ENUMS.RateIndexEnum.FixedRate &&
  feeRate.hurdleIndex === ENUMS.HurdleIndexEnum.Other ) {
  <text
    class="c6s2"
    [(value)]="feeRate.hurdleIndexDesc"
    [settings]="{
                  label: c === 1 ? 'Description' : 'Description #' + (i + 1),
                  tag: 'Hurdle Index Desc #' + (i + 1),
                }"
  ></text>
  } @if ( feeRate.hurdleRateType === ENUMS.RateIndexEnum.FixedRate ||
  feeRate.hurdleRateType === ENUMS.RateIndexEnum.IndexPlusFixedRate ) {
  <number
    class="c8s1"
    [(value)]="feeRate.hurdleRate"
    [settings]="{
                  placeholder: '%',
                  suffix: ' %',
                  label: c === 1 ? 'Rate' : 'Rate #' + (i + 1),
                  tag: 'Hurdle Rate #' + (i + 1),
                }"
    [validators]="ivStore.hurdleRate_validators()(feeRate)"
    [validation.id]="{
                  groupId: selectedOpenClass.$uid!,
                  id: feeRate.$uid!,
                }"
    [validation.chain]="ivStore.hurdleRate_validator_chain()(feeRate)"
  ></number>
  } @if ( selectedOpenClass.incFeeRateType ===
  ENUMS.IncFeeRateTypeEnum.TieredRate ) {
  <div class="c9s1">
    @if (i > 1) {
    <btn
      [settings]="{
                      icon: ICONS.remove,
                      iconOnly: true,
                      iconSize: 'normal',
                      tag: 'Remove Incentive Fee Rate #' + (i + 1),
                    }"
      (click)="ivStore.removeIncFeeRate(feeRate)"
    ></btn>
    } @if (i === c - 1) {
    <btn
      [settings]="{
                      icon: ICONS.add,
                      iconOnly: true,
                      iconSize: 'normal',
                      tag: 'Add Incentive Fee Rate',
                    }"
      (click)="ivStore.addIncFeeRate()"
    ></btn>
    }
  </div>
  } } @if ( selectedOpenClass.incFeeRateType ===
  ENUMS.IncFeeRateTypeEnum.SlidingScaleRate ) {
  <number
    class="c3s2"
    [(value)]="feeRate.incFeeMinRatePercentage"
    [settings]="{
                label: 'Min Fee Rate #' + (i + 1),
                placeholder: '%',
                suffix: ' %',
                tag: 'Incentive Fee Min Rate Percentage #' + (i + 1),
              }"
    [validators]="ivStore.incFeeMinRatePercentage_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.incFeeMinRatePercentage_validator_chain()(feeRate)
              "
  ></number>
  <number
    class="c5s2"
    [(value)]="feeRate.incFeeMaxRatePercentage"
    [settings]="{
                label: 'Max Fee Rate #' + (i + 1),
                placeholder: '%',
                suffix: ' %',
                tag: 'Incentive Fee Max Rate Percentage #' + (i + 1),
              }"
    [validators]="ivStore.incFeeMaxRatePercentage_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.incFeeMaxRatePercentage_validator_chain()(feeRate)
              "
  ></number>
  <number
    class="c7s2"
    [(value)]="feeRate.hurdleMinRatePercentage"
    [settings]="{
                label: 'Min Hurdle Rate #' + (i + 1),
                placeholder: '%',
                suffix: ' %',
                tag: 'Hurdle Min Rate Percentage #' + (i + 1),
              }"
    [validators]="ivStore.hurdleMinRatePercentage_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.hurdleMinRatePercentage_validator_chain()(feeRate)
              "
  ></number>
  <number
    class="c9s2"
    [(value)]="feeRate.hurdleMaxRatePercentage"
    [settings]="{
                label: 'Max Hurdle Rate #' + (i + 1),
                placeholder: '%',
                suffix: ' %',
                tag: 'Hurdle Max Rate Percentage #' + (i + 1),
              }"
    [validators]="ivStore.hurdleMaxRatePercentage_validators()(feeRate)"
    [validation.id]="{
                groupId: selectedOpenClass.$uid!,
                id: feeRate.$uid!,
              }"
    [validation.chain]="
                ivStore.hurdleMaxRatePercentage_validator_chain()(feeRate)
              "
  ></number>
  } }

  <number
    class="c1s2"
    [(value)]="selectedOpenClass.crystalization"
    [settings]="{
            label: 'Crystalization',
            onlyWholeNumbers: true,
            placeholder: 'Every',
            prefix: 'Every ',
            labelTip: 'Insert the incentive fee realization frequency.',
            tag: 'Crystalization Period Amount',
          }"
    [validators]="ivStore.crystalization_validators()"
    [validation.id]="selectedOpenClass.$uid"
  ></number>
  <dropdown
    class="c3s2"
    [(value)]="selectedOpenClass.crystalizationPeriod"
    [settings]="{
            label: 'Frequency',
            options: ENUMS.CalendarUnitEnum.toOptions,
            tag: 'Crystalization Period',
          }"
    [validators]="ivStore.crystalizationPeriod_validators()"
    [validation.id]="selectedOpenClass.$uid"
    [validation.chain]="ivStore.crystalizationPeriod_validator_chain()"
  ></dropdown>
  <dropdown
    class="c5s2"
    [(value)]="selectedOpenClass.highwaterMark"
    [settings]="{
            label: 'High watermark',
            options: ENUMS.HighWaterMarkEnum.toOptions,
            optionsFiltering: false,
          }"
    [validators]="ivStore.highwaterMark_validators()"
    [validation.id]="selectedOpenClass.$uid"
  ></dropdown>
  <dropdown
    class="c1s2"
    [(value)]="selectedOpenClass.hasIncentiveFeeDiscounts"
    [settings]="{
            label: 'Discount',
            options: ENUMS.SimpleAnswerEnum.toOptions,
            optionsFiltering: false,
            tag: 'Has Incentive Fee Discounts',
          }"
    [validators]="ivStore.hasIncentiveFeeDiscounts_validators()"
    [validation.id]="selectedOpenClass.$uid"
    (valueChange)="ivStore.updateHasIncentiveFeeDiscounts($event)"
  ></dropdown>

  @for ( discount of ivStore.filteredIncFeeDiscounts(); track discount.$uid; let
  i = $index, c = $count ) {
  <dropdown
    class="c3s2"
    [(value)]="discount.discountType"
    [settings]="{
              label: c > 1 ? 'Discount Type #' + (i + 1) : 'Discount Type',
              options: ENUMS.DiscountTypeEnum.toOptions,
              optionsFiltering: false,
              tag: 'Incentive Fee Discount Type #' + (i + 1),
            }"
    [validators]="ivStore.incFeeDiscountType_validators()"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: discount.$uid!,
            }"
  ></dropdown>
  <number
    class="c5s2"
    [(value)]="discount.discountRate"
    [settings]="{
              label:
                selectedOpenClass?.managementFeeDiscounts?.length! > 1
                  ? 'Fee Reduction #' + (i + 1)
                  : 'Fee Reduction',
              placeholder: '%',
              suffix: ' %',
              labelTip: '0.1% = 10 basis points',
              tag: 'Incentive Fee Discount Rate #' + (i + 1),
            }"
    [validators]="ivStore.incFeeDiscountRate_validators()()"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: discount.$uid!,
            }"
  ></number>
  <text
    class="c7s4"
    [(value)]="discount.discountDesc"
    [settings]="{
              label: c > 1 ? 'Eligibility #' + (i + 1) : 'Eligibility',
              tag: 'Incentive Fee Discount Desc #' + (i + 1),
            }"
    [validators]="ivStore.incFeeDiscountDesc_validators()"
    [validation.id]="{
              groupId: selectedOpenClass.$uid!,
              id: discount.$uid!,
            }"
  ></text>
  <div class="c11s1">
    @if (i > 0) {
    <btn
      [settings]="{
                  icon: ICONS.remove,
                  iconOnly: true,
                  iconSize: 'normal',
                  tag: 'Remove Incentive Fee Discount #' + (i + 1),
                }"
      (click)="ivStore.removeIncFeeDiscount(discount)"
    ></btn>
    } @if (i === c - 1) {
    <btn
      [settings]="{
                  icon: ICONS.add,
                  iconOnly: true,
                  iconSize: 'normal',
                  tag: 'Add Incentive Fee Discount',
                }"
      (click)="ivStore.addIncFeeDiscount()"
    ></btn>
    }
  </div>
  } }
</section-group>
}
