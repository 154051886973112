@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.hasRecycling"
  [settings]="{
        label: 'Recycling',
        labelTip: 'Reflects whether reinvestments are allowed.',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Has Recycling',
      }"
  (valueChange)="ivStore.updateHasRecycling($event)"
></dropdown>

@if ( iv.closedEndDetails.closedEndKeyTerm.hasRecycling ===
ENUMS.SimpleAnswerEnum.Yes ) {
<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.hasTimeLimit"
  [settings]="{
          label: 'Time Limit(s)',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          tag: 'Has Time Limit',
        }"
  (valueChange)="ivStore.updateHasTimeLimit($event)"
></dropdown>

@for ( timeLimit of iv.closedEndDetails.recyclingTimeLimits; track
timeLimit.$uid; let i = $index, c = $count ) {
<dropdown
  class="c2s1"
  [(value)]="timeLimit.timeLimitType"
  [settings]="{
            label: 'Description',
            options: ENUMS.RecyclingTimeLimitEnum.toOptions,
            tag: 'Time Limit Type #' + (i + 1),
          }"
  [validators]="ivStore.timeLimitType_validators()"
  [validation.id]="{
            groupId: iv.$uid!,
            id: timeLimit.$uid!,
          }"
  [validation.chain]="ivStore.timeLimitType_validator_chain()(timeLimit)"
  (valueChange)="ivStore.updateTimeLimitType(timeLimit, $event)"
></dropdown>

@if ( timeLimit.timeLimitType === ENUMS.RecyclingTimeLimitEnum
.Only_for_investments_realized_within_a_specific_timeframe ) {
<number
  class="c3s1"
  [(value)]="timeLimit.monthsFromAcquisition"
  [settings]="{
              label: '',
              placeholder: '# of months from acquisition',
              suffix: ' months from acquisition',
              abbr: true,
              tag: 'Months From Acquisition',
            }"
  [validators]="ivStore.monthsFromAcquisition_validators()"
  [validation.id]="{
              groupId: iv.$uid!,
              id: timeLimit.$uid!,
            }"
></number>
}

<div class="c4s1 flex place-self-stretch">
  @if (i > 0) {
  <btn
    [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Remove Recycling Time Limit #' + (i + 1),
              }"
    (click)="ivStore.removeRecyclingTimeLimit(timeLimit)"
  ></btn>
  } @if (i === c - 1) {
  <btn
    [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add Recycling Time Limit',
              }"
    (click)="ivStore.addRecyclingTimeLimit()"
  ></btn>
  }
</div>
}

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.hasRecyclingLimit"
  [settings]="{
          label: '% Limit',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          tag: 'Has Recycling Limit',
        }"
  (valueChange)="ivStore.updateHasRecyclingLimit($event)"
></dropdown>

@if ( iv.closedEndDetails.closedEndKeyTerm.hasRecyclingLimit ===
ENUMS.SimpleAnswerEnum.Yes ) {
<number
  class="c2s1"
  [(value)]="
            iv.closedEndDetails.closedEndKeyTerm.recyclingCommitmentsRate
          "
  [settings]="{
            label: '',
            placeholder: '% of commitments',
            suffix: ' % of commitments',
            abbr: true,
            tag: 'Recycling Commitments Rate',
          }"
  [validators]="ivStore.recyclingCommitmentsRate_validators()"
  [validation.id]="iv.$uid"
></number>
}

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.hasProceedsLimit"
  [settings]="{
          label: 'Limit(s) Relating to Type of Proceeds',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          tag: 'Has Proceeds Limit',
        }"
  (valueChange)="ivStore.updateHasProceedsLimit($event)"
></dropdown>

@for ( proceedsLimit of iv.closedEndDetails.recyclingProceedsLimits; track
proceedsLimit.$uid; let i = $index, c = $count ) {
<dropdown
  class="c2s1"
  [(value)]="proceedsLimit.proceedsType"
  [settings]="{
            label: 'Type of Proceeds',
            options: ENUMS.RecyclingProceedsLimitEnum.toOptions,
            tag: 'Proceeds Type #' + (i + 1),
          }"
  [validators]="ivStore.proceedsType_validators()"
  [validation.id]="{
            groupId: iv.$uid!,
            id: proceedsLimit.$uid!,
          }"
  [validation.chain]="
            ivStore.proceedsType_validator_chain()(proceedsLimit)
          "
  (valueChange)="ivStore.updateProceedsType(proceedsLimit, $event)"
></dropdown>

@if ( proceedsLimit.proceedsType === ENUMS.RecyclingProceedsLimitEnum.Other ) {
<text
  class="c3s1"
  [(value)]="proceedsLimit.proceedsDesc"
  [settings]="{
              label: 'Description',
              placeholder: 'Description',
              tag: 'Proceeds Description',
            }"
></text>
}

<div class="c4s1 flex place-self-stretch">
  @if (i > 0) {
  <btn
    [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Remove Recycling Proceeds Limit #' + (i + 1),
              }"
    (click)="ivStore.removeRecyclingProceedsLimit(proceedsLimit)"
  ></btn>
  } @if (i === c - 1) {
  <btn
    [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add Recycling Proceeds Limit',
              }"
    (click)="ivStore.addRecyclingProceedsLimit()"
  ></btn>
  }
</div>
} } }
