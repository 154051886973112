@let iv = ivStore.investmentVehicle();
@let company = companyStore.company();
@if (iv) {
  <!-- layout.selectedView() === VIEWS.CO_INVESTMENT  -->
  <text
    class="c1s4"
    [(value)]="iv.coInvestmentDetails.projectName"
    [settings]="{ label: 'Project Name' }"
  ></text>
  <location
    class="c5s3"
    [initialValue]="ivStore.companyHeadquarters()"
    [settings]="{
      label: 'Company Headquarters',
      valueFormat: 'city,state,country',
      placeholder: 'Search for a location...',
    }"
    [popupCssClass]="'w-auto left-0 whitespace-nowrap'"
    (valueChange)="ivStore.updateCompanyHeadquarters($event)"
  >
  </location>

  <!-- layout.selectedView() !== VIEWS.SECONDARY -->
  <text
    class="c1s4"
    [(value)]="iv.formerNames"
    [settings]="{ label: 'Former Names', uid: iv.$uid }"
  ></text>
  <check
    class="c5s1 justify-center"
    [(value)]="iv.nameIsPlaceholder"
    [settings]="{ label: 'Name is Placeholder', uid: iv.$uid }"
  ></check>
  <check
    class="c6s1 justify-center"
    [(value)]="iv.highConviction"
    [settings]="{
      label: 'High Conviction',
      labelTip:
        'Indicates Aksia is in favor of this fund, defined as part of the Research Team\'s \'Favorites List\'. Used internally by Advisory to guide client recommendations.',
      uid: iv.$uid,
    }"
  ></check>
  <check
    class="c7s1 justify-center"
    [(value)]="iv.important"
    [settings]="{
      label: 'Key',
      labelTip:
        'Institutional universe, inclusive of managers considered key in the industry, irrespective of assets under management, length of existence, or Aksia research opinion. Used in MAX client portal tools for client guidance',
      uid: iv.$uid,
    }"
  ></check>

  <!-- layout.selectedView() === VIEWS.SECONDARY -->
  <text
    class="c1s1"
    [(value)]="iv.secondaryDetails.fundNameAsset"
    [settings]="{
      label: 'Fund Name / Asset',
      tag: 'Fund Name Asset',
      uid: iv.$uid,
    }"
  ></text>
} @else if (
  company &&
  companyStore.entityTypeId() === ENUMS.EntityTypeEnum.ManagementCompany
) {
  <text
    class="c1s4"
    [(value)]="company.formerNames"
    [settings]="{ label: 'Former Names' }"
  ></text>
}
