import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
  withState,
} from '@ngrx/signals';

import { PropExtend, StoreModel, UTILS } from '@aksia/infrastructure';

type UtilsState = {
  modelMap: Map<number, StoreModel>;
  dirtyFields: Map<string, { pristineValue: unknown; currentValue: unknown }>;
  removeFields: Set<string>;
};

export function withUtils<T extends StoreModel>() {
  return signalStoreFeature(
    {
      state: type<{
        entity: T | Array<T> | undefined;
      }>(),
    },
    withState<UtilsState>({
      modelMap: new Map<number, StoreModel>(),
      dirtyFields: new Map<
        string,
        { pristineValue: unknown; currentValue: unknown }
      >(),
      removeFields: new Set([
        '$uid',
        '$state',
        '$isDeleted',
        '$tags',
        '$attributes',
      ]),
    }),
    withMethods((store) => {
      const generateUids = (entities: Array<PropExtend<unknown>>) => {
        entities.forEach((entity) => {
          if (!entity || UTILS.OBJECT.isObject(entity) === false) {
            return;
          }

          entity.$uid = UTILS.GENERATE.nextuid ?? 1;
          entity.$isDeleted = false;

          for (const key in entity) {
            let field = key as keyof typeof entity;
            if (Array.isArray(entity[key])) {
              generateUids(entity[key]);
            }
            if (UTILS.OBJECT.isObject(entity[field])) {
              generateUids([entity[field] as PropExtend<unknown>]);
            }
          }
        });
      };

      const toStoreModelCollection = <T, U = StoreModel>(
        dto: Array<T>,
        model?: new (...args: any[]) => U,
        meta?: { tags?: Array<string>; attributes?: Map<string, unknown> },
      ): Array<U> => {
        return dto.map((item) => {
          return toStoreModel<T, U>(item, model, meta) as U;
        }) as Array<U>;
      };

      const toStoreModel = <T, U = StoreModel>(
        dto: T,
        model?: new (...args: any[]) => U,
        meta?: { tags?: Array<string>; attributes?: Map<string, unknown> },
      ): U => {
        let modelInstance = model
          ? (new model() as T extends StoreModel ? T : StoreModel)
          : new StoreModel();

        if (!dto || UTILS.OBJECT.isObject(dto) === false) {
          return modelInstance as U;
        }

        let nonNullableDto = Object.fromEntries(
          Object.entries(dto).filter(([_, value]) => value !== null),
        );
        let entity = Object.assign(modelInstance, nonNullableDto);

        registerTags(entity, meta?.tags);
        registerAttributes(entity, meta?.attributes);

        entity.$attributes?.set(
          'pristineValue',
          JSON.stringify(entity, cleanseModel),
        );

        let modelMap = store.modelMap();
        modelMap.set(entity.$uid!, entity);
        patchState(store, { modelMap });

        for (const key in entity) {
          if (store.removeFields().has(key)) continue;
          let field = key as keyof typeof entity;

          if (
            Array.isArray(entity[field]) &&
            entity[field].every((item) => UTILS.OBJECT.isObject(item))
          ) {
            (entity[field] as Object) = toStoreModelCollection(entity[field]);
          }
          if (UTILS.OBJECT.isObject(entity[field])) {
            entity[field] = toStoreModel(entity[field]);
          }
        }

        return entity as U;
      };

      const toDTO = <T extends StoreModel>(entity: T | Array<T>) => {
        let dto: T | Array<T> = JSON.parse(
          JSON.stringify(entity, cleanseModel),
        );
        return dto;
      };

      const modelIsDirty = <T extends StoreModel>(entity: T) => {
        let pristineValue = entity.$attributes?.get('pristineValue');
        let currentValue = toDTO(entity);
        let isDirty = !UTILS.OBJECT.areDeepEqual(
          JSON.parse(pristineValue as string),
          currentValue,
          true,
        );
        return {
          isDirty,
          dto: isDirty ? currentValue : undefined,
        };
      };

      const updateState = (
        tag: string,
        oldValue: unknown,
        newValue: unknown,
      ) => {
        if (!store.dirtyFields().has(tag)) {
          store
            .dirtyFields()
            .set(tag, { pristineValue: oldValue, currentValue: newValue });
        } else {
          store.dirtyFields().set(tag, {
            pristineValue: store.dirtyFields().get(tag)?.pristineValue,
            currentValue: newValue,
          });
        }
      };

      //#region Helper Methods

      const registerTags = (
        entities: StoreModel | Array<StoreModel>,
        tags?: Array<string>,
      ) => {
        if (!tags) {
          return;
        }
        entities = Array.isArray(entities) ? entities : [entities];
        entities?.forEach((entity) => {
          tags.forEach((tag) => {
            entity.$tags?.add(tag);
            let tagField = `$${tag}` as keyof typeof entity;
            (entity[tagField] as Boolean) = true;
            store.removeFields().add(tagField);
          });
        });
      };

      const registerAttributes = (
        entities: StoreModel | Array<StoreModel>,
        attributes?: Map<string, unknown>,
      ) => {
        if (!attributes) {
          return;
        }
        entities = Array.isArray(entities) ? entities : [entities];
        entities.forEach((entity) => {
          attributes.forEach((value, key) => {
            entity.$attributes?.set(key, value);
            let attributeField = `$${key}` as keyof typeof entity;
            (entity[attributeField] as unknown) = value;
            store.removeFields().add(attributeField);
          });
        });
      };

      const cleanseModel = (key: string, value: unknown) => {
        return store.removeFields().has(key) ? undefined : value;
      };

      //#endregion

      return {
        generateUids,
        toStoreModelCollection,
        toStoreModel,
        toDTO,
        modelIsDirty,
        updateState,
        addTags: registerTags,
        addAttributes: registerAttributes,
      };
    }),
  );
}
