import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AUTHENTICATION_SERVICE_TOKEN } from '@aksia/infrastructure';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
  private auth = inject(AUTHENTICATION_SERVICE_TOKEN);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    { url }: RouterStateSnapshot,
  ): boolean {
    if (this.auth.isAuthenticated()) return true;
    if (url !== '/login') this.auth.redirectUrl?.set(url);
    this.router.navigateByUrl('login', { state: { redirect: url } });
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.canActivate(route, state);
  }
}
