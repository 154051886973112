@let iv = ivStore.investmentVehicle(); @let company = companyStore.company();
@let entity = iv ? iv! : company!;
@if (entity) {
  @if (layout.selectedView() === VIEWS.LEGAL_DOC || company) {
    <text
      class="c1s8 label-inline"
      [(value)]="entity.physicalFileName"
      [settings]="{
        label: 'Name of Document(s)',
        labelTip:
          'Insert the exact name of the document(s) that constitute the source of the data.',
        placeholder: 'Name of Document(s)',
      }"
    >
    </text>
  }
  <date
    class="c9s2 label-inline"
    [(value)]="entity.dataAsOfDate"
    [settings]="{
      label: 'As of',
      placeholder: 'mm/dd/yyyy',
    }"
  >
  </date>
  <dropdown
    class="c11s2 label-inline"
    [(value)]="entity.dataSource"
    [settings]="{
      label: 'Source',
      options: ENUMS.DataSourceEnum.toOptions,
      optionValue: 'value',
      optionLabel: 'label',
    }"
  >
  </dropdown>
}
