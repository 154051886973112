@let iv = ivStore.investmentVehicle()!; @if (iv) { @if (
!iv.secondaryDetails.assets || iv.secondaryDetails.assets!.length! === 0 ) {
<btn
  class="c1s1 place-self-stretch"
  [settings]="{
          label: 'Add Asset',
          icon: ICONS.add,
          iconSize: 'normal',
          tag: 'Add Asset',
        }"
  (click)="ivStore.addSecondaryAsset()"
></btn>
} @for ( asset of iv.secondaryDetails.assets; track asset.$uid; let i = $index;
let c = $count ) {
<section-group class="c1s6 visible" [settings]="{ label: 'Asset #' + (i + 1) }">
  @switch (ivStore.assetMode()(asset)) { @case (ENUMS.AssetModeEnum.Viewing) {
  <div uiinput class="relative">
    <label> Asset Name </label>
    <div uivalue>
      @if (!asset.assetId) {
      <span class="cursor-default" [attr.title]="asset.assetName ?? undefined"
        >{{ asset.assetName ?? 'Asset #' + (i + 1) }}</span
      >
      } @else {
      <a
        class="text-blue-400 cursor-pointer"
        [attr.title]="asset.assetName ?? undefined"
        (click)="visitAsset(asset.assetId!)"
        >{{ asset.assetName }}</a
      >
      }
    </div>
    <div uitoolstrip>
      <icon
        class="flex-row-end cursor-pointer"
        uitool
        [iconSize]="'small'"
        [iconDefinition]="ICONS.edit"
        (click)="
                    ivStore.toggleSecondaryAsset(
                      asset,
                      asset.assetId
                        ? ENUMS.AssetModeEnum.Linking
                        : ENUMS.AssetModeEnum.Editing
                    )
                  "
      ></icon>
    </div>
  </div>
  } @case (ENUMS.AssetModeEnum.Editing) {
  <text
    class="c1s1"
    [(value)]="asset.assetName"
    [settings]="{ label: 'Edit Asset Name', showClearButton: false }"
  >
    <icon
      uitool
      [iconSize]="'large'"
      [iconDefinition]="ICONS.link"
      (click)="
                  ivStore.toggleSecondaryAsset(asset, ENUMS.AssetModeEnum.Linking)
                "
    ></icon>
  </text>
  } @case (ENUMS.AssetModeEnum.Linking) {
  <dropdown
    class="c1s1"
    [initialValue]="asset.assetId"
    [settings]="{
                label: 'Search for Asset',
                hideDefaultIcon: true,
                placeholder: 'Start typing...',
                optionLabel: 'name',
                optionValue: 'entityId',
                optionsFiltering: false,
                optionsSorting: false,
                optionsAsyncFn: search.searchEntities,
                showClearButton: false,
                selectedOptionStrategy: 'none',
              }"
    (OptionIsSelected)="ivStore.setSecondaryAsset(asset, $event)"
  >
    <icon
      uitool
      [iconSize]="'large'"
      [iconDefinition]="ICONS.close"
      (click)="
                  ivStore.toggleSecondaryAsset(asset, ENUMS.AssetModeEnum.Viewing)
                "
    ></icon>
  </dropdown>
  } }
  <number
    class="c2s1"
    [(value)]="asset.vintage"
    [settings]="{
            label: 'Vintage',
            labelTip:
              asset.assetId! > 0
                ? 'Vintage is populated by linked Fund'
                : undefined,
            onlyWholeNumbers: true,
            thousandsSeparator: '',
          }"
  ></number>
  <date
    class="c3s1"
    [(value)]="asset.recordDate"
    [settings]="{ label: 'Record Date', placeholder: 'mm/dd/yyyy' }"
  ></date>
  <dropdown
    class="c4s1"
    [(value)]="asset.currency"
    [settings]="{
            label: 'Currency',
            labelTip:
              asset?.assetId! > 0
                ? 'Currency is populated by linked Fund'
                : undefined,
            options: ENUMS.CurrencyEnum.toOptions,
          }"
  ></dropdown>
  <check
    class="c5s1 check"
    [(value)]="asset.showInReport"
    [settings]="{ label: 'Show in Report' }"
  ></check>
  <div class="c6s1 flex">
    <btn
      [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Asset #' + (i + 1),
            }"
      (click)="ivStore.removeSecondaryAsset(asset)"
    ></btn>

    @if (i === c - 1) {
    <btn
      [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add Asset',
              }"
      (click)="ivStore.addSecondaryAsset()"
    ></btn>
    }
  </div>
  <number
    class="c1s1"
    [(value)]="asset.navRecordDate"
    [settings]="{ label: 'NAV at Record Date ', abbr: true }"
  ></number>
  <number
    class="c2s1"
    [(value)]="asset.uncalledCapital"
    [settings]="{ label: 'Uncalled Capital ', abbr: true }"
  ></number>
  <number
    class="c3s1"
    [(value)]="asset.cashFlowsSinceRefDate"
    [settings]="{ label: 'Cash Flows Since Reference Date ', abbr: true }"
  ></number>
  <number
    class="c4s1"
    [(value)]="asset.unadjustedInvestmentAmount"
    [settings]="{ label: 'Unadjusted Investment Amount ', abbr: true }"
  ></number>
  <number
    class="c5s1"
    [(value)]="asset.adjustedInvestmentAmount"
    [settings]="{ label: 'Adjusted Investment Amount ', abbr: true }"
  ></number>
</section-group>
} }
