import { computed } from '@angular/core';
import { signalStoreFeature, withComputed } from '@ngrx/signals';
import { AksiaValidators } from '@aksia/infrastructure';

export function withTaxonomyValidators<EntityValidators>() {
  return signalStoreFeature(
    withComputed((store) => ({
      sectorId_validators: computed(() => [AksiaValidators.required()]),
      strategyId_validators: computed(() => [AksiaValidators.required()]),
      substrategyId_validators: computed(() => [AksiaValidators.required()]),
      primaryRegionId_validators: computed(() => [AksiaValidators.required()]),
    })),
  );
}
