<dropdown
  #shareclassDD
  class="c1s11 label-inline"
  [initialValue]="ivStore.selectedClassUId()"
  [settings]="{
      label: 'Share Class',
      options: ivStore.shareClasses,
      optionLabel: 'className',
      optionValue: '$uid',
      optionDeleted: 'isDeleted',
      stateless: true,
      tag: 'Share Class',
    }"
  (valueChange)="ivStore.updateSelectedClassUId($event)"
>
  <btn
    uitool
    [settings]="{
        icon: ICONS.add,
        iconSize: 'normal',
        tag: 'Add Share Class',
      }"
    (click)="addShareClass($event)"
  >
  </btn>
  <btn
    uitool
    [settings]="{
        icon: ICONS.edit,
        iconSize: 'normal',
        tag: 'Rename Share Class',
      }"
  >
  </btn>
</dropdown>
<div class="flex gap-x-xl c12s1 place-self-stretch">
  <btn
    class="place-self-stretch"
    [attr.disabled]="ivStore.selectedClass()?.isDefault ? true : null"
    [settings]="{
        icon: ivStore.selectedClass()?.isDefault
          ? ICONS.defaultOn
          : ICONS.defaultOff,
        iconSize: 'normal',
        tag: 'Set as Default Share Class',
      }"
    (click)="ivStore.updateSelectedClassDefault()"
  >
  </btn>
  @if (!ivStore.selectedClass()?.isDefault) {
  <btn
    class="place-self-stretch"
    title="Remove Share Class"
    [settings]="{
          icon: ICONS.remove,
          iconSize: 'normal',
          tag: 'Remove Share Class',
        }"
    (click)="ivStore.removeShareClass()"
  >
  </btn>
  } @if (ivStore.selectedClass()?.$isOpenEnd) {
  <check
    class="bg-transparent"
    [(value)]="ivStore.selectedOpenClass()!.noLongerOffered"
    [inputCssClass]="'bg-transparent'"
    [settings]="{
          label: 'No longer offered',
          labelTip: 'This specific class is closed/no longer offered',
        }"
  ></check>
  }
</div>

<modal
  class="shareClassModal"
  #addShareClassModal
  [settings]="{
      label: 'Add Share class',
    }"
>
  <div UiModalContent>
    <text
      [(value)]="newShareClassName"
      [settings]="{
          label: 'Name',
          placeholder: 'Enter name',
          labelTip: 'Enter the name of the new share class',
        }"
    ></text>
  </div>
  <div UiModalFooter>
    <btn
      [settings]="{
          label: 'Add',
          disabled: !newShareClassName() || newShareClassName()?.length === 0,
          tag: 'Confirm Add Share Class',
        }"
      (click)="
          ivStore.addShareClass(newShareClassName()!); addShareClassModal.close()
        "
    ></btn>
    <btn
      [settings]="{
          label: 'Cancel',
          tag: 'Cancel Add Share Class',
        }"
      (click)="addShareClassModal.close()"
    ></btn>
  </div>
</modal>
