import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent, SectionComponent } from '@aksia/ui';
import { StaticContentDirective } from '../../section-contents/base.section.content';

@Component({
  selector: 'page-content[uipagecontent="home"]',
  imports: [CommonModule, SectionComponent, IconComponent],
  templateUrl: './home.content.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageContent extends StaticContentDirective {
  //#region Host Bindings

  @HostBinding('class')
  uiclass = 'grid-cols-3 grid-rows-3';

  //#endregion

  private getGreetings(): string {
    const d = new Date();
    const time = d.getHours();

    if (time < 12) {
      return 'Good morning!';
    } else if (time > 17) {
      return 'Good evening!';
    } else {
      return 'Good afternoon!';
    }
  }
}
