import { computed, effect, inject, signal } from '@angular/core';
import {
  signalStore,
  withState,
  withMethods,
  patchState,
  withComputed,
  withHooks,
} from '@ngrx/signals';
import { PropExtend, UTILS } from '@aksia/infrastructure';
import {
  InvestmentProgram,
  ManagementCompany,
  Location,
  DOMAINS,
  Enums,
  DOMAIN_API_REQUESTS,
  InvestmentVehicle,
} from '@aksia/models';
import { withValidation } from './features/validation.store.feature';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { of, pipe, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  EntityService,
  LoadingService,
  NavigationService,
} from '@aksia/services';
import { withUtils } from './features/utils.store.feature';
import { withCompanyValidators } from './validators/company.store.validators';
import { withAsync } from './features/async.store.feature';

type EntityState = {
  entityId: number | undefined;
  entityTypeId: Enums.EntityTypeEnum;
  entity: ManagementCompany | undefined;
};

export type CompanyStoreType = InstanceType<typeof CompanyStore>;

export const CompanyStore = signalStore(
  { providedIn: 'root' },
  withState<EntityState>({
    entityId: undefined,
    entityTypeId: Enums.EntityTypeEnum.ManagementCompany,
    entity: undefined,
  }),
  withAsync<ManagementCompany>(),
  withUtils<ManagementCompany>(),
  withValidation(),
  withComputed((store) => ({
    company: computed(() => store.entity()),
    funds: computed(() =>
      store
        .entity()
        ?.investmentPrograms?.flatMap((program: InvestmentProgram) =>
          program.funds?.map((fund) => ({
            value: fund.fundId,
            label: fund.name,
          })),
        ),
    ),
    streamStartDate: computed(() => {
      return ManagementCompany.getStartDate(
        store.entity() as ManagementCompany,
      );
    }),
  })),
  withCompanyValidators(),
  withMethods(
    (
      store,
      loading = inject(LoadingService),
      entity = inject(EntityService),
    ) => {
      const setEntityId = (entityId: number) => {
        patchState(store, { entityId });
      };

      const loadCompany = (entityId: number) => {
        patchState(store, { entityId });
        loadCompanyRx({ _entityId: entityId });
        return of({});
      };

      const loadCompanyRx = rxMethod(
        pipe(
          tap(() => loading.addLoadRequest(DOMAIN_API_REQUESTS.COMPANY)),
          switchMap(() =>
            entity
              .getEntityById<ManagementCompany>(
                store.entityId()!,
                DOMAINS.COMPANY.URI,
              )
              .pipe(
                tap({
                  next: (company) => {
                    let companyModel = Object.assign(
                      new ManagementCompany(),
                      company,
                    );
                    store.generateUids([companyModel]);
                    patchState(store, { entity: companyModel });
                    sortAddresses();
                  },
                  error: (error: HttpErrorResponse) => {
                    console.error(error);
                  },
                  finalize: () => {
                    loading.setLoadResponse(DOMAIN_API_REQUESTS.COMPANY);
                    store.finalizeMethod(1);
                  },
                }),
              ),
          ),
        ),
      );
      const saveCompany = () => {
        console.log('Saving...');
        console.log(store.toDTO(store.entity()!));
      };
      const updateCompany = (partialCompany: Partial<ManagementCompany>) => {
        patchState(store, {
          entity: {
            ...store.entity()!,
            ...partialCompany,
          },
        });
      };
      const sortAddresses = () => {
        updateCompany({
          addresses: store
            .company()
            ?.addresses!?.sort((a, b) =>
              a.isPrimary
                ? -1
                : (a.$uid! ?? Number.POSITIVE_INFINITY) <
                    (b.$uid! ?? Number.POSITIVE_INFINITY)
                  ? -1
                  : 1,
            ),
        });
      };
      const updateAddress = (
        location: PropExtend<Location>,
        value: unknown,
        field?: keyof Location,
      ) => {
        if (!field) {
          location.address1 = (value as Location)?.address1;
          location.city = (value as Location)?.city;
          location.state = (value as Location)?.state;
          location.zip = (value as Location)?.zip;
          location.country = (value as Location)?.country;
          location.longitude = (value as Location)?.longitude;
          location.latitude = (value as Location)?.latitude;
        } else {
          location[field] = value;
        }
      };
      const updatePrimaryAddress = (address: Location) => {
        store.company()?.addresses?.forEach((a) => {
          a.isPrimary = a.$uid === address.$uid;
        });
        sortAddresses();
      };

      const addAddress = () => {
        updateCompany({
          addresses: [...(store.company()?.addresses ?? []), new Location()],
        });
      };

      const removeAddress = (address: Location) => {
        store.removeValidation({ validationId: address.$uid! });
        updateCompany({
          addresses: store
            .company()
            ?.addresses?.filter((a) => a.$uid !== address.$uid),
        });

        if (address.isPrimary && store.company()?.addresses?.length! > 0) {
          store.company()!.addresses!.at(0)!.isPrimary = true;
        }
      };

      return {
        loadCompany,
        saveCompany,
        updateCompany,
        setEntityId,
        sortAddresses,
        updateAddress,
        updatePrimaryAddress,
        addAddress,
        removeAddress,
      };
    },
  ),
  withHooks({
    onInit(store) {
      //console.log('CompanyStore initialized ', store);
    },
  }),
);
