import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LocationComponent,
  NumberComponent,
  SpreadsheetComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Historical Info"]',
  imports: [TextComponent, CheckComponent, LocationComponent],
  templateUrl: './historical-info.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoricalInfoSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Returns"]',
  imports: [
    SpreadsheetComponent,
    ButtonComponent,
    DateComponent,
    DropdownComponent,
  ],
  templateUrl: './returns.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="AUM"]',
  imports: [
    SpreadsheetComponent,
    ButtonComponent,
    DropdownComponent,
    CheckComponent,
  ],
  templateUrl: './aum.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AumSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Audit"]',
  imports: [DropdownComponent, DateComponent, TextComponent],
  templateUrl: './audit.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Reporting"]',
  imports: [TextComponent, DropdownComponent, NumberComponent, CheckComponent],
  templateUrl: './reporting.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Identification"]',
  imports: [NumberComponent],
  templateUrl: './identification.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class identificationSectionContent extends StaticContentDirective {}

export const CommonSectionContents = [
  HistoricalInfoSectionContent,
  ReturnsSectionContent,
  AumSectionContent,
  AuditSectionContent,
  ReportingSectionContent,
  identificationSectionContent,
];
