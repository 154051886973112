import { effect, inject, Injectable, signal, untracked } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InvestmentVehicleStore } from '../stores/investment-vehicle.store';
import { LayoutService, LocalStorageService } from '@aksia/services';
import { STORAGE_KEYS } from '@aksia/infrastructure';
import { AuthenticationService } from '../services/authentication.service';
import { ASSET_TEAM_VIEWS, AssetTeamViewType } from '@aksia/models';

@Injectable({ providedIn: 'root' })
export class InvestmentVehicleResolver implements Resolve<number> {
  private readonly local = inject(LocalStorageService);
  private readonly layout = inject(LayoutService);
  private readonly auth = inject(AuthenticationService);
  private readonly store = inject(InvestmentVehicleStore);

  entityId = signal<number | undefined>(undefined);

  constructor() {
    effect(() => {
      if (this.entityId() && this.layout.availableViewItems()?.length > 0) {
        untracked(() => {
          this.store.setEntityId(this.entityId()!);
          this.store.loadAllMethods();
        });
      }
    });
    effect(() => {
      if (this.store.allMethodsAreFinalized?.()) {
        this.layout.layoutIsReady.set(true);
      }
    });
  }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<number> | Promise<number> | number {
    //Set Default View Layout
    let defaultView: AssetTeamViewType = ASSET_TEAM_VIEWS.DATA;
    if (this.auth.user()?.impersonate) {
      defaultView = this.auth.user()?.getDefaultView() as AssetTeamViewType;
      this.layout.setDefaultView(defaultView, false);
    } else {
      defaultView = this.local.get(STORAGE_KEYS.LAYOUT.DEFAULT_VIEW);
      if (!defaultView) {
        defaultView =
          this.auth.user()?.getDefaultView() ?? ASSET_TEAM_VIEWS.DATA;
      }
      this.layout.setDefaultView(defaultView, false);
    }
    this.layout.selectView(this.layout.defaultView());

    //Load data
    const entityId = +route.params['entityId'];
    if (Number.isSafeInteger(entityId)) {
      this.entityId.set(entityId);
    }

    return entityId;
  }
}
