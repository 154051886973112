@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.secondaryDetails.dealType"
  [settings]="{
        label: 'Deal Type',
        options: ENUMS.DealTypeSecondaryEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<dropdown
  class="c2s1"
  [(value)]="iv.secondaryDetails.sourceDeal"
  [settings]="{
        label: 'Source Type',
        options: ENUMS.SourceDealEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<text
  class="c3s1"
  [(value)]="iv.coInvestmentDetails.intermediaryBroker"
  [settings]="{
        label: 'Source',
        tag: 'Intermediary Broker Source',
      }"
></text>
<text
  class="c4s1"
  [(value)]="iv.secondaryDetails.sourceContact"
  [settings]="{
        label: 'Source Contact',
      }"
></text>
<dropdown
  class="c1s1"
  [(value)]="iv.coInvestmentDetails.gpAksiaCoverage"
  [settings]="{
        label: 'Aksia Coverage of GP',
        options: ENUMS.AksiaCoverageGPNewEnum.toOptions,
        tag: 'GP Aksia Coverage',
      }"
></dropdown>
<dropdown
  class="c2s1"
  [(value)]="iv.secondaryDetails.aksiaRole"
  [settings]="{
        label: 'Aksia Role',
        options: ENUMS.AksiaRoleEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<dropdown
  class="c3s1"
  [(value)]="iv.secondaryDetails.dealDynamic"
  [settings]="{
        label: 'Deal Dynamic',
        options: ENUMS.DealDynamicEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
}
