import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  IconComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';
import { ENVIRONMENT } from '@aksia/infrastructure';
import { SearchService } from '@aksia/services';

@Component({
  selector: '[uisectioncontent="Assets"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    DateComponent,
    NumberComponent,
    TextComponent,
    CheckComponent,
    ButtonComponent,
    IconComponent,
  ],
  templateUrl: './assets.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsSectionContent extends StaticContentDirective {
  private readonly env = inject(ENVIRONMENT);
  protected readonly search = inject(SearchService);

  protected visitAsset(id: number) {
    window.open(
      `${this.env.max_url}/Application/Fund/Fund.aspx?dataProviderEntityId=${id}`,
    );
  }
}

@Component({
  selector: '[uisectioncontent="Categorization Factors"]',
  imports: [DropdownComponent, TextComponent],
  templateUrl: './categorization-factors.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategprizationFactorsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Secondary Key Terms"]',
  imports: [DropdownComponent, DateComponent, NumberComponent, TextComponent],
  templateUrl: './key-terms.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyTermsSectionContent extends StaticContentDirective {}

export const InvestmentVehicleSecondarySectionContents = [
  AssetsSectionContent,
  CategprizationFactorsSectionContent,
  KeyTermsSectionContent,
];
