import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DropdownComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Fees"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    NumberComponent,
    TextComponent,
    ButtonComponent,
  ],
  templateUrl: './fees.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeesSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Redemptions"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    NumberComponent,
    CheckComponent,
    ButtonComponent,
  ],
  templateUrl: './redemptions.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedemptionsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Side Pockets"]',
  imports: [DropdownComponent, NumberComponent, ButtonComponent],
  templateUrl: './side-pockets.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePocketsSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Subscriptions"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './subscriptions.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsSectionContent extends StaticContentDirective {}

export const ShareclassOpenEndSectionContents = [
  FeesSectionContent,
  RedemptionsSectionContent,
  SidePocketsSectionContent,
  SubscriptionsSectionContent,
];
