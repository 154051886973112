@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitmentType"
  [settings]="{
        label: 'Minimum GP Commitment',
        options: ENUMS.MinGPCommitmentEnum.toOptions,
        optionsFiltering: false,
        tag: 'Minimum GP Commitment Type',
      }"
  (valueChange)="ivStore.updateMinGPCommitmentType($event)"
></dropdown>

@if ( iv.closedEndDetails.closedEndStructure.minGPCommitmentType &&
iv.closedEndDetails.closedEndStructure.minGPCommitmentType !==
ENUMS.MinGPCommitmentEnum.Amount ) {
<number
  class="c2s1"
  [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitment"
  [settings]="{
          label: '',
          placeholder: '% of commitments',
          placeholderPersists: true,
          suffix: ' % of commitments',
          tag: 'Minimum GP Commitment',
        }"
  [validators]="ivStore.minGPCommitment_validators()"
  [validation.id]="iv.$uid"
></number>
} @if ( iv.closedEndDetails.closedEndStructure.minGPCommitmentType &&
iv.closedEndDetails.closedEndStructure.minGPCommitmentType !==
ENUMS.MinGPCommitmentEnum.Percentage ) {
<number
  class="c3s1"
  [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitmentAmount"
  [settings]="{
          label: '',
          abbr: true,
          placeholder: 'in million/billion',
          tag: 'Minimum GP Commitment Amount',
        }"
  [validators]="ivStore.minGPCommitmentAmount_validators()"
  [validation.id]="iv.$uid"
></number>
} @if (iv.closedEndDetails.closedEndStructure.minGPCommitmentType) {
<check
  class="c4s1"
  [(value)]="
          iv.closedEndDetails.closedEndStructure.minGPCommitmentCashless
        "
  [settings]="{
          label: 'Cashless',
          tag: 'Minimum GP Commitment Cashless',
        }"
></check>
}

<dropdown
  class="c1s1"
  [(value)]="iv.secondaryDetails.subsequentFundsInvesting"
  [settings]="{
        label: 'Subsequent Funds Investing',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
}
