@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.lpClawbackPerLimit"
  [settings]="{
        label: 'LP Clawback % Limit(s)',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'LP Clawback Per Limit',
        uid: iv.$uid,
      }"
  (valueChange)="ivStore.updateLpClawbackPerLimit($event)"
></dropdown>

@for ( lpClawbackPerLimit of iv.closedEndDetails.lpClawbackPerLimits; track
lpClawbackPerLimit.$uid; let i = $index, c = $count ) {
<number
  class="c2s1"
  [(value)]="lpClawbackPerLimit.lpClawbackPerLimitRate"
  [settings]="{
          label: '',
          placeholder: '%',
          suffix: ' %',
          tag: 'LP Clawback Per Limit Rate #' + (i + 1),
        }"
  [validators]="ivStore.lpClawbackPerLimitRate_validators()"
  [validation.id]="{
          groupId: iv.$uid!,
          id: lpClawbackPerLimit.$uid!,
        }"
></number>
<dropdown
  class="c3s1"
  [(value)]="lpClawbackPerLimit.lpClawbackCalculatedOnType"
  [settings]="{
          label: 'Calculated On',
          options: ENUMS.LpClawbackCalculatedOnEnum.toOptions,
          tag: 'LP Clawback Calculated On Type #' + (i + 1),
        }"
  [validators]="ivStore.lpClawbackCalculatedOnType_validators()"
  [validation.id]="{
          groupId: iv.$uid!,
          id: lpClawbackPerLimit.$uid!,
        }"
  [validation.chain]="
          ivStore.lpClawbackCalculatedOnType_validator_chain()(lpClawbackPerLimit)
        "
></dropdown>
<div class="c4s1 flex place-self-stretch">
  @if (i > 0) {
  <btn
    [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove LP Clawback Per Limit #' + (i + 1),
            }"
    (click)="ivStore.removeLpClawbackPerLimit(lpClawbackPerLimit)"
  ></btn>
  } @if (i === c - 1 && c < 2) {
  <btn
    [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add LP Clawback Per Limit',
            }"
    (click)="ivStore.addLpClawbackPerLimit()"
  ></btn>
  }
</div>
}

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.lpClawbackTimeLimit"
  [settings]="{
        label: 'LP Clawback Time Limit(s)',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'LP Clawback Time Limit',
        uid: iv.$uid,
      }"
  (valueChange)="ivStore.updateLpClawbackTimeLimit($event)"
></dropdown>

@for ( lpClawbackTimeLimit of iv.closedEndDetails.lpClawbackTimeLimits; track
lpClawbackTimeLimit.$uid; let i = $index, c = $count ) {
<number
  class="c2s1"
  [(value)]="lpClawbackTimeLimit.lpClawbackTimeLimitYears"
  [settings]="{
          label: '',
          placeholder: '# of years',
          suffix: ' years',
          onlyWholeNumbers: false,
          tag: 'LP Clawback Time Limit Years #' + (i + 1),
        }"
  [validators]="ivStore.lpClawbackTimeLimitYears_validators()"
  [validation.id]="{
          groupId: iv.$uid!,
          id: lpClawbackTimeLimit.$uid!,
        }"
></number>
<dropdown
  class="c3s1"
  [(value)]="lpClawbackTimeLimit.lpClawbackTimeLimitFrom"
  [settings]="{
          label: 'Calculated On',
          options: ENUMS.LpClawbackTimeLimitFromEnum.toOptions,
          tag: 'LP Clawback Time Limit From #' + (i + 1),
        }"
  [validators]="ivStore.lpClawbackTimeLimitFrom_validators()"
  [validation.id]="{
          groupId: iv.$uid!,
          id: lpClawbackTimeLimit.$uid!,
        }"
  [validation.chain]="
          ivStore.lpClawbackTimeLimitFrom_validator_chain()(lpClawbackTimeLimit)
        "
></dropdown>
<div class="c4s1 flex place-self-stretch">
  @if (i > 0) {
  <btn
    [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove LP Clawback Time Limit #' + (i + 1),
            }"
    (click)="ivStore.removeLpClawbackTimeLimit(lpClawbackTimeLimit)"
  ></btn>
  } @if (i === c - 1 && c < 3) {
  <btn
    [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add LP Clawback Time Limit',
            }"
    (click)="ivStore.addLpClawbackTimeLimit()"
  ></btn>
  }
</div>
} }
