@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.coInvestmentDetails.singleAssetPortfolio"
  [settings]="{
        label: 'Single Asset/Portfolio',
        options: ENUMS.SingleAssetPortfolioEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<dropdown
  class="c2s1"
  [(value)]="iv.coInvestmentDetails.acquisitionType"
  [settings]="{
        label: 'Acquisition Type',
        options: ENUMS.AcquisitionTypeEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<number
  class="c1s1"
  [(value)]="iv.coInvestmentDetails.purchasePrice"
  [settings]="{ label: 'Purchase Price', abbr: true }"
></number>
<number
  class="c2s1"
  [(value)]="iv.coInvestmentDetails.entryCapRate"
  [settings]="{
        label: 'Entry Cap Rate',
        placeholder: '%',
        suffix: ' %',
      }"
></number>
<number
  class="c3s1"
  [(value)]="iv.coInvestmentDetails.exitCapRate"
  [settings]="{
        label: 'Exit Cap Rate',
        placeholder: '%',
        suffix: ' %',
      }"
></number>
<dropdown
  class="c1s1"
  [(value)]="iv.coInvestmentDetails.fixedFloatingDebt"
  [settings]="{
        label: 'Fixed or Floating Debt',
        options: ENUMS.FixedFloatingDebtEnum.toOptions,
        optionsFiltering: false,
      }"
  (valueChange)="ivStore.updateFixedFloatingDebt($event)"
></dropdown>
@if ( iv.coInvestmentDetails.fixedFloatingDebt ===
ENUMS.FixedFloatingDebtEnum.Floating || iv.coInvestmentDetails.fixedFloatingDebt
=== ENUMS.FixedFloatingDebtEnum.Mixed ) {
<number
  class="c2s1"
  [(value)]="iv.coInvestmentDetails.floatingRate"
  [settings]="{
          label: 'Floating Rate',
          placeholder: '%',
          suffix: ' %',
        }"
></number>
} @if ( iv.coInvestmentDetails.fixedFloatingDebt ===
ENUMS.FixedFloatingDebtEnum.Fixed || iv.coInvestmentDetails.fixedFloatingDebt
=== ENUMS.FixedFloatingDebtEnum.Mixed ) {
<number
  class="c3s1"
  [(value)]="iv.coInvestmentDetails.fixedRate"
  [settings]="{
          label: 'Fixed Rate',
          placeholder: '%',
          suffix: ' %',
        }"
></number>
}

<dropdown
  class="c1s1"
  [(value)]="iv.coInvestmentDetails.debtSourcing"
  [settings]="{
        label: 'Debt Sourcing',
        options: ENUMS.DeptSourcingEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<dropdown
  class="c2s1"
  [(value)]="iv.coInvestmentDetails.stabilized"
  [settings]="{
        label: 'Stabilized',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<number
  class="c3s1"
  [(value)]="iv.coInvestmentDetails.stabilizedYOC"
  [settings]="{
        label: 'Stabilized YOC',
        placeholder: '%',
        suffix: ' %',
      }"
></number>
<dropdown
  class="c1s1"
  [(value)]="iv.coInvestmentDetails.developReDevelop"
  [settings]="{
        label: 'Development/Re-Development',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
></dropdown>
<number
  class="c2s1"
  [(value)]="iv.coInvestmentDetails.sizeSF"
  [settings]="{
        label: 'Size (sq. ft)',
        onlyWholeNumbers: true,
        thousandsSeparator: '',
      }"
></number>
<number
  class="c3s1"
  [(value)]="iv.coInvestmentDetails.sizeUn"
  [settings]="{
        label: 'Size (units)',
        onlyWholeNumbers: true,
        thousandsSeparator: '',
      }"
></number>
}
