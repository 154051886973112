import { computed } from '@angular/core';
import { signalStoreFeature, withComputed } from '@ngrx/signals';
import { AksiaValidators, UTILS } from '@aksia/infrastructure';
import {
  AUMStreamPoint,
  PeriodicStreamPoint,
  PublicReturnStreamPoint,
} from '@aksia/models';

export function withStreamValidators<EntityValidators>() {
  return signalStoreFeature(
    withComputed((store) => ({
      publicReturn_validators: computed(
        () => (streamPoint: PublicReturnStreamPoint) => [
          AksiaValidators.userDefined(() => {
            if (!streamPoint || UTILS.OBJECT.isNil(streamPoint.value)) {
              return { isInvalid: false };
            }

            let messages = [
              { prop: 'Source', isInvalid: !streamPoint.source },
              { prop: 'Fee Type', isInvalid: !streamPoint.feeType },
              {
                prop: 'Classification',
                isInvalid: !streamPoint.classification,
              },
            ];
            let message =
              messages
                .filter((message) => message.isInvalid)
                .map((message) => message.prop)
                .join(', ') + ' should not be empty.';

            return {
              isInvalid:
                !streamPoint.source ||
                !streamPoint.feeType ||
                !streamPoint.classification,
              message,
            };
          }),
        ],
      ),
      privateReturn_validators: computed(() => [
        AksiaValidators.userDefined((streamPoint: unknown) => {
          let streamPointTyped = streamPoint as PeriodicStreamPoint;

          if (!streamPointTyped || UTILS.OBJECT.isNil(streamPointTyped.value)) {
            return { isInvalid: false };
          }

          return {
            isInvalid: !streamPointTyped.source,
            message: 'Source should not be empty.',
          };
        }),
      ]),
      aum_validators: computed(() => (streamPoint: AUMStreamPoint) => [
        AksiaValidators.userDefined(() => {
          if (!streamPoint || UTILS.OBJECT.isNil(streamPoint.value)) {
            return { isInvalid: false };
          }
          let messages = [
            { prop: 'Source', isInvalid: !streamPoint.source },
            { prop: 'Currency', isInvalid: !streamPoint.currency },
          ];
          let message =
            messages
              .filter((message) => message.isInvalid)
              .map((message) => message.prop)
              .join(', ') + ' should not be empty.';

          return {
            isInvalid: !streamPoint.source || !streamPoint.currency,
            message,
          };
        }),
      ]),
    })),
  );
}
