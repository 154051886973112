@let shareClass = ivStore.selectedClosedClass();
@if (shareClass) {
  <dropdown
    class="c1s1"
    [(value)]="shareClass.mgmtFee"
    [settings]="{
      label: 'Management Fee',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
      tag: 'Mgmt Fee',
    }"
    [validators]="ivStore.mgmtFee_validators()"
    [validation.id]="shareClass.$uid"
    (valueChange)="ivStore.updateMgmtFee($event)"
  ></dropdown>
  <date
    class="c6s1"
    [(value)]="shareClass.mgmtFeeCeasedOn"
    [settings]="{
      label: 'Fee Ceased',
      placeholder: 'mm/dd/yyyy',
      tag: 'Mgmt Fee Ceased On',
    }"
  ></date>

  @for (
    period of ivStore.filteredMgmtFeePeriods();
    track period.$uid;
    let i = $index;
    let c = $count
  ) {
    <section-group
      class="c1s6 multi"
      [settings]="{
        label: 'Period #' + (i + 1),
      }"
    >
      <dropdown
        [class]="period.mgmtFeeTimeFrom ? 'c1s1' : 'c1s2'"
        [(value)]="period.mgmtFeeTimeFrom"
        [settings]="{
          label: 'From',
          options: ENUMS.MgmtFeeFromEnum.toOptions,
          tag: 'Management Fee Time From #' + (i + 1),
        }"
        [validators]="ivStore.mgmtFeeTimeFrom_validators()(period)"
        [validation.id]="{
          groupId: shareClass.$uid!,
          id: period.$uid!,
        }"
        [validation.chain]="ivStore.mgmtFeeTimeFrom_validator_chain()"
        (valueChange)="ivStore.updateMgmtFeeTimeFrom(period, $event)"
      ></dropdown>

      @if (period.mgmtFeeTimeFrom) {
        <date
          class="c2s1"
          [(value)]="period.mgmtFeeTimeFromDate"
          [settings]="{
            label: ' ',
            placeholder: 'mm/dd/yyyy',
            tag: 'Management Fee Time From Date #' + (i + 1),
          }"
          [validators]="ivStore.mgmtFeeTimeFromDate_validators()(period)"
          [validation.id]="{
            groupId: shareClass.$uid!,
            id: period.$uid!,
          }"
          [validation.chain]="ivStore.mgmtFeeTimeFromDate_validator_chain()"
        ></date>
        <text
          class="c3s1"
          [(value)]="period.mgmtFeeTimeFromDesc"
          [settings]="{
            label: ' ',
            placeholder: 'Description',
            tag: 'Management Fee Time From Desc #' + (i + 1),
          }"
          [validators]="ivStore.mgmtFeeTimeFromDesc_validators()"
          [validation.id]="{
            groupId: shareClass.$uid!,
            id: period.$uid!,
          }"
        ></text>
      }

      <dropdown
        [class]="period.mgmtFeeTimeTo ? 'c4s1' : 'c4s2'"
        [(value)]="period.mgmtFeeTimeTo"
        [settings]="{
          label: 'To',
          options: ENUMS.MgmtFeeToEnum.toOptions,
          tag: 'Management Fee Time To #' + (i + 1),
        }"
        [validators]="ivStore.mgmtFeeTimeTo_validators()(period)"
        [validation.id]="{
          groupId: shareClass.$uid!,
          id: period.$uid!,
        }"
        [validation.chain]="ivStore.mgmtFeeTimeTo_validator_chain()"
        (valueChange)="ivStore.updateMgmtFeeTimeTo(period, $event)"
      ></dropdown>

      @if (period.mgmtFeeTimeTo) {
        <date
          class="c5s1"
          [(value)]="period.mgmtFeeTimeToDate"
          [settings]="{
            label: ' ',
            placeholder: 'mm/dd/yyyy',
            tag: 'Management Fee Time To Date #' + (i + 1),
          }"
          [validators]="ivStore.mgmtFeeTimeToDate_validators()(period)"
          [validation.id]="{
            groupId: shareClass.$uid!,
            id: period.$uid!,
          }"
          [validation.chain]="ivStore.mgmtFeeTimeToDate_validator_chain()"
        ></date>
        <text
          class="c6s1"
          [(value)]="period.mgmtFeeTimeToDesc"
          [settings]="{
            label: ' ',
            placeholder: 'Description',
            tag: 'Management Fee Time To Desc #' + (i + 1),
          }"
          [validators]="ivStore.mgmtFeeTimeToDesc_validators()"
          [validation.id]="{
            groupId: shareClass.$uid!,
            id: period.$uid!,
          }"
        ></text>
      }

      <div class="c7s1 flex justify-center">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Management Fee Period #' + (i + 1),
            }"
            (click)="ivStore.removeMgmtFeePeriod(period)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Management Fee Period',
            }"
            (click)="ivStore.addMgmtFeePeriod()"
          ></btn>
        }
      </div>
      @for (
        amount of ivStore.filteredMgmtFeeAmounts()(period);
        track amount.$uid;
        let j = $index;
        let c = $count
      ) {
        <number
          class="c1s3"
          [(value)]="amount.mgmtFeeRate"
          [settings]="{
            label: 'Rate #' + (j + 1),
            placeholder: '%',
            suffix: ' %',
            tag: 'Management Fee Period #' + (i + 1) + ' - Rate #' + (j + 1),
          }"
          [validators]="ivStore.mgmtFeeRate_validators()(period)"
          [validation.id]="{
            groupId: shareClass.$uid!,
            id: amount.$uid!,
          }"
          [validation.chain]="ivStore.mgmtFeeRate_validator_chain()"
        ></number>

        @let showUseOfLeverage =
          amount.mgmtFeeCalcOnType
            ? [
                ENUMS.MgmtFeeCalcOnEnum.actively_invested_capital,
                ENUMS.MgmtFeeCalcOnEnum
                  .actively_invested_capital_plus_unfunded_commitments,
                ENUMS.MgmtFeeCalcOnEnum.total_invested_capital,
                ENUMS.MgmtFeeCalcOnEnum
                  .total_invested_capital_plus_unfunded_commitments,
                ENUMS.MgmtFeeCalcOnEnum
                  .lesser_of_NAV_and_total_invested_capital,
                ENUMS.MgmtFeeCalcOnEnum
                  .lesser_of_NAV_and_actively_invested_capital,
                ENUMS.MgmtFeeCalcOnEnum
                  .lesser_of_actively_invested_capital_and_committed_capital,
                ENUMS.MgmtFeeCalcOnEnum
                  .lesser_of_total_invested_capital_and_committed_capital,
              ].includes(amount.mgmtFeeCalcOnType)
            : false;

        <dropdown
          [class]="
            amount.mgmtFeeCalcOnType === ENUMS.MgmtFeeCalcOnEnum.other_amount ||
            showUseOfLeverage
              ? 'c4s1'
              : 'c4s3'
          "
          [(value)]="amount.mgmtFeeCalcOnType"
          [settings]="{
            label: 'calculated On',
            options: ENUMS.MgmtFeeCalcOnEnum.toOptions,
            tag:
              'Management Fee Period #' +
              (i + 1) +
              ' - Calc On Type #' +
              (j + 1),
          }"
          [validators]="ivStore.mgmtFeeCalcOnType_validators()(period)"
          [validation.id]="{
            groupId: shareClass.$uid!,
            id: amount.$uid!,
          }"
          [validation.chain]="ivStore.mgmtFeeCalcOnType_validator_chain()"
          (valueChange)="
            ivStore.updateMgmtFeeCalcOnType(period, amount, $event)
          "
        ></dropdown>

        @if (showUseOfLeverage) {
          <dropdown
            class="c5s2"
            [(value)]="amount.useOfLeverage"
            [settings]="{
              label: 'Use of leverage',
              labelTip:
                'When basis includes invested capital, indicate whether the management fee is inclusive of leverage',
              options: ENUMS.LeverageUseEnum.toOptions,
              optionsFiltering: false,
              tag:
                'Management Fee Period #' +
                (i + 1) +
                ' - Use of Leverage #' +
                (j + 1),
            }"
          ></dropdown>
        }
        @if (
          amount.mgmtFeeCalcOnType === ENUMS.MgmtFeeCalcOnEnum.other_amount
        ) {
          <text
            class="c5s2"
            [(value)]="amount.mgmtFeeCalcOnDesc"
            [settings]="{
              label: 'Description',
              tag:
                'Management Fee Period #' +
                (i + 1) +
                ' - Calc On Desc #' +
                (j + 1),
            }"
            [validators]="ivStore.mgmtFeeCalcOnDesc_validators()"
            [validation.id]="{
              groupId: shareClass.$uid!,
              id: amount.$uid!,
            }"
          ></text>
        }

        <div class="c7s1 flex justify-end">
          @if (j > 0) {
            <btn
              [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag:
                  'Remove Management Fee Period #' +
                  (i + 1) +
                  ' Rate #' +
                  (j + 1),
              }"
              (click)="ivStore.removeMgmtFeeAmount(period, amount)"
            ></btn>
          }
          @if (j === c - 1) {
            <btn
              [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag:
                  'Add Management Fee Period #' + (i + 1) + ' Rate #' + (j + 1),
              }"
              (click)="ivStore.addMgmtFeeAmount(period)"
            ></btn>
          }
        </div>
      }
    </section-group>
  }
  @if (
    shareClass.mgmtFee !== ENUMS.SimpleAnswerEnum.No &&
    (layout.selectedView() === VIEWS.LEGAL_DOC ||
      layout.selectedView() === VIEWS.CO_INVESTMENT)
  ) {
    <dropdown
      class="c1s2"
      [(value)]="shareClass.paymentFrequency"
      [settings]="{
        label: 'Payment Frequency',
        options: ENUMS.MgmtFeeFrequencyEnum.toOptions,
        optionsFiltering: false,
      }"
      [validators]="ivStore.paymentFrequency_validators()"
      [validation.id]="shareClass.$uid"
      (valueChange)="ivStore.updatePaymentFrequency($event)"
    ></dropdown>

    @if (shareClass.paymentFrequency === ENUMS.MgmtFeeFrequencyEnum.Other) {
      <text
        class="c3s2"
        [(value)]="shareClass.paymentFrequencyDescription"
        [settings]="{
          label: 'Description',
          tag: 'Payment Frequency Description',
        }"
      >
      </text>
    }
  }
  @if (
    shareClass.mgmtFee !== ENUMS.SimpleAnswerEnum.No &&
    layout.selectedView() !== VIEWS.SECONDARY
  ) {
    <number
      class="c1s2"
      [(value)]="shareClass.mgmtFeeOffsetRate"
      [settings]="{
        label: 'Management Fee Offset %',
        suffix: ' %',
        tag: 'Management Fee Offset Rate',
      }"
      [validators]="ivStore.mgmtFeeOffsetRate_validators()"
      [validation.id]="shareClass.$uid"
    ></number>
  }
  @if (
    shareClass.mgmtFee !== ENUMS.SimpleAnswerEnum.No &&
    (layout.selectedView() === VIEWS.LEGAL_DOC ||
      layout.selectedView() === VIEWS.INVESTMENT_RESEARCH ||
      layout.selectedView() === VIEWS.CO_INVESTMENT)
  ) {
    <dropdown
      class="c1s2"
      [(value)]="shareClass.hasMgmtFeeDiscounts"
      [settings]="{
        label: 'Discount',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has Management Fee Discounts',
      }"
      [validators]="ivStore.hasMgmtFeeDiscounts_validators()"
      [validation.id]="shareClass.$uid"
      (valueChange)="ivStore.updateHasMgmtFeeDiscounts($event)"
    ></dropdown>

    @for (
      discount of ivStore.filteredClosedMgmtFeeDiscounts();
      track discount.$uid;
      let i = $index;
      let c = $count
    ) {
      <dropdown
        class="c3s1"
        [(value)]="discount.discountType"
        [settings]="{
          label: 'Discount Type ' + (c > 1 ? '#' + (i + 1) : ''),
          options: ENUMS.DiscountTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Management Fee Discount Type #',
        }"
        [validators]="ivStore.closedMgmtDiscountType_validators()"
        [validation.id]="{
          groupId: shareClass.$uid!,
          id: discount.$uid!,
        }"
      ></dropdown>
      <number
        class="c4s1"
        [(value)]="discount.discountRate"
        [settings]="{
          label: 'Fee Reduction ' + (c > 1 ? '#' + (i + 1) : ''),
          labelTip:
            'Reflects the reduction granted, not the fee charged post-reduction.',
          placeholder: '%',
          suffix: ' %',
          tag: 'Management Fee Discount Rate #' + (i + 1),
        }"
        [validators]="ivStore.closedMgmtDiscountRate_validators()"
        [validation.id]="{
          groupId: shareClass.$uid!,
          id: discount.$uid!,
        }"
      ></number>
      <text
        class="c5s2"
        [(value)]="discount.discountDesc"
        [settings]="{
          label: 'Eligibility ' + (c > 1 ? '#' + (i + 1) : ''),
          tag: 'Management Fee Discount Desc #' + (i + 1),
        }"
        [validators]="ivStore.closedMgmtDiscountDesc_validators()"
        [validation.id]="{
          groupId: shareClass.$uid!,
          id: discount.$uid!,
        }"
      ></text>
      <div class="c7s1 flex">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Management Fee Discount #' + (i + 1),
            }"
            (click)="ivStore.removeClosedManagementFeeDiscount(discount)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Management Fee Discount',
            }"
            (click)="ivStore.addClosedManagementFeeDiscount()"
          ></btn>
        }
      </div>
    }
  }
}
