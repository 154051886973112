<section
  class="unstyled c2s1 r2s1"
  [settings]="{ headerIsHidden: true }"
  [contentCssClass]="'flex place-content-center'"
>
  <icon
    class="c1s1 text-[var(--color-blue-gray-400)] opacity-20"
    uihovertool
    uicleartool
    [iconSize]="400"
    [iconDefinition]="ICONS.home"
  ></icon>
</section>
