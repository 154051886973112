import {
  AksiaValidatorFn,
  IValidationId,
  IValidationRegisterRequest,
  IValidationRequest,
  IValidationResponse as IValidationResponse,
  IValidationState,
  IValidationValidateRequest,
  StateEnum,
} from '@aksia/infrastructure';
import { computed, Signal, signal } from '@angular/core';
import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import {
  entityConfig,
  removeEntities,
  removeEntity,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';

const validationConfig = entityConfig({
  entity: type<IValidationState>(),
  collection: 'validation',
  selectId: (validation: IValidationState) =>
    `${validation.id}_${validation.tag}`,
});

export function withValidation<T>() {
  return signalStoreFeature(
    withEntities(validationConfig),
    withMethods((store) => {
      const validationExists = (
        validationId: number | IValidationId,
        tag: string,
      ) => {
        let { id, groupId } = destructureValidationId(validationId);

        return store
          .validationEntities()
          ?.some((v) => v.id === id && v.tag === tag);
      };

      const validationIsInit = (
        validationId: number | IValidationId,
        tag: string,
      ) => {
        let { id, groupId } = destructureValidationId(validationId);

        return store
          .validationEntities()
          ?.some((v) => v.id === id && v.tag === tag && v.isInit);
      };

      const registerValidation = (request: IValidationRegisterRequest) => {
        let { id, groupId } = destructureValidationId(request.validationId);

        let validationState: IValidationState = {
          id: id,
          groupId: groupId,
          tag: request.tag!,
          value: undefined,
          validators: request.validators,
          errors: signal<Array<string>>([]),
          warnings: signal<Array<string>>([]),
        };

        patchState(store, setEntity(validationState, validationConfig));

        return {
          errors: validationState.errors,
          warnings: validationState.warnings,
        };
      };

      const updateValidationValue = (request: IValidationValidateRequest) => {
        let { id, groupId } = destructureValidationId(request.validationId);

        let validation = store
          .validationEntities()
          ?.find(
            (v) =>
              v.groupId === groupId && v.id === id && v.tag === request.tag,
          );
        if (validation) {
          validation.value = request.value;
          validation.isInit = true;
          patchState(
            store,
            updateEntity(
              {
                id: `${id}_${request.tag}`,
                changes: { value: validation.value, isInit: validation.isInit },
              },
              validationConfig,
            ),
          );
        }
      };

      const updateValidationTag = (
        request: IValidationValidateRequest,
        newTag: string,
      ) => {
        let { id, groupId } = destructureValidationId(request.validationId);

        let validation = store
          .validationEntities()
          ?.find(
            (v) =>
              v.groupId === groupId && v.id === id && v.tag === request.tag,
          );
        if (validation) {
          validation.tag = newTag;
        }
        patchState(
          store,
          updateEntity(
            { id: `${id}_${request.tag}`, changes: { tag: newTag } },
            validationConfig,
          ),
        );
      };

      const removeValidation = (
        request: IValidationRequest | Array<IValidationRequest>,
      ) => {
        let validationRequests = Array.isArray(request) ? request : [request];
        validationRequests.forEach((validationRequest) => {
          let { id, groupId } = destructureValidationId(
            validationRequest.validationId,
          );

          let validations = store
            .validationEntities()
            ?.filter(
              (v) =>
                v.groupId === groupId &&
                v.id === id &&
                (v.tag === validationRequest.tag || !validationRequest.tag),
            );

          if (validations?.length! > 0) {
            patchState(
              store,
              removeEntities(
                validations?.map(
                  (validation) => `${validation.id}_${validation.tag}`,
                ),
                validationConfig,
              ),
            );
          }
        });
      };

      const validate = (
        request: IValidationValidateRequest | Array<IValidationValidateRequest>,
        chain?: IValidationRequest | Array<IValidationRequest>,
        isChain?: boolean,
      ) => {
        let validationRequests = Array.isArray(request) ? request : [request];
        let validationResponses: Array<IValidationResponse> = [];
        validationRequests.forEach((validationRequest) => {
          let { id, groupId } = destructureValidationId(
            validationRequest.validationId,
          );

          let validation = store
            .validationEntities()
            ?.find(
              (v) =>
                v.groupId === groupId &&
                v.id === id &&
                (v.tag === validationRequest.tag || !validationRequest.tag),
            );

          if (validation) {
            if (!isChain) {
              validation.value = validationRequest.value;
              patchState(
                store,
                updateEntity(
                  {
                    id: `${id}_${validation.tag}`,
                    changes: { value: validation.value },
                  },
                  validationConfig,
                ),
              );
            }

            let summaryErrors: Array<string> = [];
            let summaryWarnings: Array<string> = [];
            validation.validators?.forEach((validator: AksiaValidatorFn) => {
              let { errors, warnings } = validator(validation.value);
              summaryErrors = [...summaryErrors, ...errors];
              summaryWarnings = [...summaryWarnings, ...warnings];
            });
            validation.errors.set(summaryErrors);
            validation.warnings.set(summaryWarnings);
            validationResponses.push({
              errors: summaryErrors,
              warnings: summaryWarnings,
            });
          }
        });

        if (chain) {
          validate(chain, undefined, true);
        }

        return validationResponses;
      };

      const validationErrors = (
        validationId: number | IValidationId,
        tag: string,
      ): Signal<Array<string>> => {
        let { id, groupId } = destructureValidationId(validationId);

        let validation = store
          .validationEntities()
          ?.find((v) => v.id === id && v.groupId === groupId && v.tag === tag);
        return validation?.errors ?? signal([]);
      };

      const validationWarnings = (
        validationId: number | IValidationId,
        tag: string,
      ): Signal<Array<string>> => {
        let { id, groupId } = destructureValidationId(validationId);

        let validation = store
          .validationEntities()
          ?.find((v) => v.id === id && v.groupId === groupId && v.tag === tag);
        return validation?.warnings ?? signal([]);
      };

      const debugValidations = () => {
        console.table(
          store.validationEntities().map((v) => ({
            groupId: v.groupId,
            uid: v.id,
            tag: v.tag,
            errors: v.errors?.()?.join(', '),
            warnings: v.warnings?.()?.join(', '),
            value: v.value,
          })),
        );
      };

      const getGroupErrors = (groupId: number) => {
        let validationEntities = store
          .validationEntities()
          ?.filter((v) => v.groupId === groupId && v.errors().length! > 0);
        return (
          validationEntities?.map(
            (vs: IValidationState) => `${vs.tag}: ${vs.errors()}`,
          ) ?? undefined
        );
      };

      //#region Helpers

      const destructureValidationId = (
        validationId: number | IValidationId,
      ): IValidationId => {
        return {
          id: typeof validationId === 'object' ? validationId.id : validationId,
          groupId:
            typeof validationId === 'object'
              ? validationId.groupId
              : validationId,
        };
      };

      //#endregion

      return {
        validationExists,
        validationIsInit,
        registerValidation,
        updateValidationValue,
        updateValidationTag,
        removeValidation,
        validate,
        validationErrors,
        validationWarnings,
        debugValidations,
        getGroupErrors,
      };
    }),

    withComputed(({ validationEntities }) => {
      const errors = computed(() =>
        validationEntities()?.flatMap((vs: IValidationState) => vs.errors?.()),
      );

      const warnings = computed(() =>
        validationEntities()?.flatMap((vs: IValidationState) =>
          vs.warnings?.(),
        ),
      );

      const hasErrors = computed(() => errors().length! > 0);

      const hasWarnings = computed(() => warnings().length! > 0);

      const stateSummary = computed(() =>
        validationEntities()
          ?.filter(
            (vs: IValidationState) =>
              vs.warnings()?.length! > 0 || vs.errors()?.length! > 0,
          )
          .map(
            (vs) =>
              `${vs.tag}: ${vs.errors()?.length} errors, ${vs.warnings()?.length} warnings`,
          )
          ?.join('\n\r'),
      );

      const state = computed(() =>
        hasErrors()
          ? StateEnum.Error
          : hasWarnings()
            ? StateEnum.Warning
            : StateEnum.Pristine,
      );

      return {
        errors,
        warnings,
        state,
        stateSummary,
      };
    }),
  );
}
