import {
  ChangeDetectionStrategy,
  Component,
  signal,
  viewChild,
} from '@angular/core';
import { StaticContentDirective } from '../../base.section.content';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LocationComponent,
  ModalComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Internal Notes"]',
  imports: [TextComponent],
  templateUrl: './internal-notes.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalNotesSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Overview"]',
  imports: [TextComponent],
  templateUrl: './overview.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Aksia Perspective"]',
  imports: [TextComponent, DateComponent],
  templateUrl: './aksia-perspective.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AksiaPerspectiveSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Shareclasses"]',
  imports: [
    DropdownComponent,
    TextComponent,
    ModalComponent,
    CheckComponent,
    ButtonComponent,
  ],
  templateUrl: './shareclasses.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareclassesSectionContent extends StaticContentDirective {
  addShareClassModal = viewChild<ModalComponent>('addShareClassModal');
  protected newShareClassName = signal<string | undefined>(undefined);

  protected addShareClass(event: MouseEvent) {
    this.newShareClassName.set(undefined);
    this.addShareClassModal()?.open();
    event.stopImmediatePropagation();
  }
}

@Component({
  selector: '[uisectioncontent="Leverage"]',
  imports: [
    DropdownComponent,
    NumberComponent,
    TextComponent,
    SectionGroupComponent,
    ButtonComponent,
  ],
  templateUrl: './leverage.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeverageSectionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Pacing"]',
  imports: [TextComponent, NumberComponent],
  templateUrl: './pacing.section-content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PacingSectionContent extends StaticContentDirective {}

export const InvestmentVehicleCommonSectionContents = [
  InternalNotesSectionContent,
  OverviewSectionContent,
  AksiaPerspectiveSectionContent,
  ShareclassesSectionContent,
  LeverageSectionContent,
  PacingSectionContent,
];
