@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.successorFundProvision"
  [settings]="{
        label: 'Successor Fund Provision',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Successor Fund Provision',
      }"
  [validators]="ivStore.successorFundProvision_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateSuccessorFundProvision($event)"
></dropdown>

@if ( iv.closedEndDetails.successorFundProvision === ENUMS.SimpleAnswerEnum.Yes
) {
<number
  class="c2s1"
  [(value)]="iv.closedEndDetails.successorFundProvisionThresholdRate"
  [settings]="{
          label: '% Threshold',
          placeholder: '% of commitments',
          suffix: ' % of commitments',
          tag: 'Successor Fund Provision Threshold Rate',
        }"
  [validators]="ivStore.successorFundProvisionThresholdRate_validators()"
  [validation.id]="iv.$uid"
></number>
} }
