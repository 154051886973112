@let iv = ivStore.investmentVehicle()!;
@let selectedClosedClass = ivStore.selectedClosedClass()!;
@if (iv) {
  @if (
    layout.selectedView() === VIEWS.DATA ||
    layout.selectedView() === VIEWS.INVESTMENT_RESEARCH
  ) {
    <dropdown
      class="c1s1"
      [(value)]="iv.fundraisingStatus"
      [settings]="{
        label: 'Fundraising Status',
        labelTip:
          'Fundraising indicates in market, accepting new investors and capital. If final close has passed, automated to Not Fundraising.',
        options: ENUMS.FundRaisingStatusEnum.toOptions,
      }"
    ></dropdown>
    <text
      class="c2s4"
      [(value)]="iv.fundraisingNotes"
      [settings]="{
        label: 'Fundraising Notes',
        placeholder: 'Notes',
      }"
    ></text>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.programStatus"
    [settings]="{
      label: 'Program Status',
      options: ENUMS.ProgramStatusEnum.toOptions,
    }"
  ></dropdown>

  @if (layout.selectedView() === VIEWS.INVESTMENT_RESEARCH) {
    <dropdown
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.oversubscribedFundRaise"
      [settings]="{
        label: 'Oversubscribed Fund Raise',
        labelTip: 'Indicates whether the fund raise was oversubscribed or not.',
        options: ENUMS.SimpleAnswerEnum.toOptions,
      }"
    ></dropdown>
  }
  @if (
    layout.selectedView() === VIEWS.DATA ||
    layout.selectedView() === VIEWS.INVESTMENT_RESEARCH
  ) {
    <dropdown
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.placementAgent"
      [settings]="{
        label: 'Placement Agent',
        labelTip:
          'Indicates whether a placement agent was used for the fund raise.',
        options: ENUMS.PlacementAgentEnum.toOptions,
      }"
    ></dropdown>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.commitmentCurrency"
    [settings]="{
      label: 'Currency',
      labelTip:
        '*This field is directly associated with Commitments Currency in the Classic view.',
      options: ENUMS.CurrencyEnum.toOptions,
      disabled: layout.selectedView() === VIEWS.RISK,
      tag: 'Commitment Currency',
    }"
    [validators]="ivStore.commitmentCurrency_validators()"
    [validation.id]="iv.$uid"
  ></dropdown>

  @if (
    layout.selectedView() === VIEWS.DATA ||
    layout.selectedView() === VIEWS.INVESTMENT_RESEARCH ||
    layout.selectedView() === VIEWS.RISK
  ) {
    <number
      class="c1s1"
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentTarget"
      [settings]="{
        label: 'Target Capital Raise (Equity)',
        abbr: true,
        labelTip:
          'Also known as: \n\r Commitments (Target) \n Target Final Close Size',
        disabled: layout.selectedView() === VIEWS.RISK,
        tag: 'Commitment Target',
      }"
      [validators]="ivStore.commitmentTarget_validators()"
      [validation.id]="iv.$uid"
      [validation.chain]="ivStore.commitmentTarget_validator_chain()"
    ></number>
    <dropdown
      class="c2s1"
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.hasCommitmentCap"
      [settings]="{
        label: 'Target Capital Raise (Cap)',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        disabled: layout.selectedView() === VIEWS.RISK,
        tag: 'Has Commitment Cap',
      }"
      [validators]="ivStore.hasCommitmentCap_validators()"
      [validation.id]="iv.$uid"
      (valueChange)="ivStore.updateHasCommitmentCap($event)"
    ></dropdown>

    @if (
      iv.closedEndDetails.closedEndKeyTerm.hasCommitmentCap ===
      ENUMS.SimpleAnswerEnum.Yes
    ) {
      <number
        class="c3s1"
        [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentCap"
        [settings]="{
          label: 'Target Capital Raise (Cap Amount)',
          abbr: true,
          labelTip: 'Also known as: \n\r Commitments (Cap)',
          disabled: layout.selectedView() === VIEWS.RISK,
          tag: 'Commitment Cap',
        }"
        [validators]="ivStore.commitmentCap_validators()"
        [validation.id]="iv.$uid"
        [validation.chain]="ivStore.commitmentCap_validator_chain()"
      ></number>
    }
  }
  <number
    class="c1s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentsActual"
    [settings]="{
      label: 'Total Capital Raised (Equity)',
      abbr: true,
      labelTip: 'Also known as \n\r Commitments (Actual) \n Final Close Amount',
      tag: 'Commitments Actual',
    }"
  ></number>
  <dropdown
    class="c2s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentsActualType"
    [settings]="{
      label: 'Total Capital Raised (Equity) Status',
      labelTip:
        'Also known as \n\r Commitments (Actual) Type \n Final Close Amount Status',
      options: ENUMS.CommitmentsTypeEnum.toOptions,
      tag: 'Commitments Actual Type',
    }"
  ></dropdown>
  <date
    class="c3s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentsAsOf"
    [settings]="{
      label: 'Total Capital Raised (Equity) As Of',
      placeholder: 'mm/dd/yyyy',
      labelTip:
        'Also known as \n\r Commitments (As of) \n Final Close Amount (As of)',
      tag: 'Commitments As Of',
    }"
    [validators]="ivStore.commitmentsAsOf_validators()"
    [validation.id]="iv.$uid"
  ></date>
  @if (
    layout.selectedView() === VIEWS.DATA || layout.selectedView() === VIEWS.RISK
  ) {
    <number
      class="c1s1"
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.fundCommitmentsTotal"
      [settings]="{
        label: 'Fund Commitments - Total',
        labelTip:
          'Amount committed by all investors in this fund; could be lower than Total Capital Raise if this is a vehicle',
        abbr: true,
        tag: 'Fund Commitments Total',
      }"
      [validators]="ivStore.fundCommitmentsTotal_validators()"
      [validation.id]="iv.$uid"
      [validation.chain]="ivStore.fundCommitmentsTotal_validator_chain()"
    ></number>
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.fundCommitmentsLP"
      [settings]="{
        label: 'Fund Commitments - LP',
        labelTip:
          'Amount committed by all investors in the fund excluding the manager',
        abbr: true,
        tag: 'Fund Commitments LP',
      }"
      [validators]="ivStore.fundCommitmentsLP_validators()"
      [validation.id]="iv.$uid"
      [validation.chain]="ivStore.fundCommitmentsLP_validator_chain()"
    ></number>
    <number
      class="c3s1"
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.fundCommitmentsGP"
      [settings]="{
        label: 'Fund Commitments - GP',
        labelTip: 'Amount committed by the manager',
        abbr: true,
        tag: 'Fund Commitments GP',
      }"
      [validators]="ivStore.fundCommitmentsGP_validators()"
      [validation.id]="iv.$uid"
      [validation.chain]="ivStore.fundCommitmentsGP_validator_chain()"
    ></number>
  }
  @if (
    layout.selectedView() === VIEWS.DATA ||
    layout.selectedView() === VIEWS.INVESTMENT_RESEARCH
  ) {
    <dropdown
      class="c1s1"
      [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitmentType"
      [settings]="{
        label: 'Minimum GP Commitment',
        options: ENUMS.MinGPCommitmentEnum.toOptions,
        tag: 'Minimum GP Commitment Type',
      }"
      (valueChange)="ivStore.updateMinGPCommitmentType($event)"
    ></dropdown>

    @if (
      iv.closedEndDetails.closedEndStructure.minGPCommitmentType &&
      iv.closedEndDetails.closedEndStructure.minGPCommitmentType !==
        ENUMS.MinGPCommitmentEnum.Amount
    ) {
      <number
        class="c2s1"
        [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitment"
        [settings]="{
          label: '',
          placeholder: '% of commitments',
          placeholderPersists: true,
          suffix: ' % of commitments',
          tag: 'Minimum GP Commitment',
        }"
        [validators]="ivStore.minGPCommitment_validators()"
        [validation.id]="iv.$uid"
      ></number>
    }
    @if (
      iv.closedEndDetails.closedEndStructure.minGPCommitmentType &&
      iv.closedEndDetails.closedEndStructure.minGPCommitmentType !==
        ENUMS.MinGPCommitmentEnum.Percentage
    ) {
      <number
        class="c3s1"
        [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitmentAmount"
        [settings]="{
          label: '',
          abbr: true,
          placeholder: 'in million/billion',
          tag: 'Minimum GP Commitment Amount',
        }"
        [validators]="ivStore.minGPCommitmentAmount_validators()"
        [validation.id]="iv.$uid"
      ></number>
    }
    @if (iv.closedEndDetails.closedEndStructure.minGPCommitmentType) {
      <check
        class="c4s1"
        [(value)]="
          iv.closedEndDetails.closedEndStructure.minGPCommitmentCashless
        "
        [settings]="{
          label: 'Cashless',
          tag: 'Minimum GP Commitment Cashless',
        }"
      ></check>
    }
    @if (selectedClosedClass) {
      <number
        class="c1s1"
        [(value)]="selectedClosedClass.minimumLPCommitment"
        [settings]="{
          label: 'Minimum LP Commitment',
          abbr: true,
          placeholder: 'in million/billion',
        }"
        [validators]="ivStore.minimumLPCommitment_validators()"
        [validation.id]="{
          groupId: iv.$uid!,
          id: selectedClosedClass.$uid!,
        }"
      ></number>
    }
  }
  <dropdown
    class="c1s1"
    [(value)]="iv.firstCloseDateType"
    [settings]="{
      label: 'First Close Date',
      labelTip: 'Also known as: \n\r Est. First Close Date',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      optionsFiltering: false,
      tag: 'First Close Date Type',
    }"
  ></dropdown>
  <date
    class="c2s1"
    [(value)]="iv.firstCloseDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: '',
      tag: 'First Close Date',
    }"
    [validators]="ivStore.firstCloseDate_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.firstCloseDate_validator_chain()"
  ></date>

  @if (iv.closedEndDetails.interimDates?.length! > 0) {
    @for (
      interimDate of
iv.closedEndDetails.interimDates;
      track interimDate.$uid;
      let i = $index, c =
$count
    ) {
      <dropdown
        class="c1s1"
        [(value)]="interimDate.type"
        [settings]="{
          label: 'Interim Closed Date',
          options: ENUMS.CloseDateTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Interim Closed Date Type #' + (i + 1),
        }"
      ></dropdown>
      <date
        class="c2s1"
        [(value)]="interimDate.date"
        [settings]="{
          label: 'mm/dd/yyyy',
          labelFocused: ' ',
          tag: 'Interim Closed Date #' + (i + 1),
        }"
        [validators]="ivStore.interimCloseDate_validators()"
        [validation.id]="{
          groupId: iv.$uid!,
          id: interimDate.$uid!,
        }"
        [validation.chain]="ivStore.interimCloseDate_validator_chain()"
      ></date>
      <text
        class="c3s1"
        [(value)]="interimDate.description"
        [settings]="{
          label: '',
          placeholder: 'Description',
          placeholderPersists: true,
          tag: 'Interim Closed Date Description #' + (i + 1),
        }"
      ></text>
      <div class="c4s1 flex place-self-stretch">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Interim Closed Date #' + (i + 1),
            }"
            (click)="ivStore.removeIntermCloseDate(interimDate)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Interim Closed Date',
            }"
            (click)="ivStore.addIntermCloseDate()"
          ></btn>
        }
      </div>
    }
  } @else {
    <btn
      class="c1s1 place-self-stretch"
      [settings]="{
        label: 'Add Interim Closed Date',
        icon: ICONS.add,
        iconSize: 'normal',
        tag: 'Add Interim Closed Date',
      }"
      (click)="ivStore.addIntermCloseDate()"
      >Add Interim Closed Date</btn
    >
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.finalCloseDateType"
    [settings]="{
      label: 'Final Close Date',
      labelTip: 'Also known as: \n\r Est. Final Close Date',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      optionsFiltering: false,
      tag: 'Final Close Date Type',
    }"
  ></dropdown>
  <date
    class="c2s1"
    [(value)]="iv.finalCloseDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: ' ',
      tag: 'Final Close Date',
    }"
    [validators]="ivStore.finalCloseDate_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.finalCloseDate_validator_chain()"
  ></date>
  @if (
    (layout.selectedView() === VIEWS.DATA ||
      layout.selectedView() === VIEWS.INVESTMENT_RESEARCH) &&
    ivStore.selectedClosedClass()
  ) {
    <dropdown
      class="c1s1"
      [(value)]="ivStore.selectedClosedClass()!.hasFirstCloseDiscount"
      [settings]="{
        label: 'First Close Discount',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has First Close Discount',
      }"
    ></dropdown>
    <dropdown
      class="c2s1"
      [(value)]="ivStore.selectedClosedClass()!.hasSizeThresholdDiscount"
      [settings]="{
        label: 'Size Threshold Discount',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has Size Threshold Discount',
      }"
    ></dropdown>
    <dropdown
      class="c3s1"
      [(value)]="ivStore.selectedClosedClass()!.hasOtherDiscount"
      [settings]="{
        label: 'Other Discount',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has Other Discount',
      }"
    ></dropdown>
    <dropdown
      class="c1s1"
      [(value)]="iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterest"
      [settings]="{
        label: 'Subsequent Close Interest',
        labelTip: 'Insert the Subsequent LP Interest Payment.',
        optionsFiltering: false,
        options: ENUMS.SimpleAnswerEnum.toOptions,
      }"
      [validators]="ivStore.subsequentCloseInterest_validators()"
      [validation.id]="iv.$uid"
      (valueChange)="ivStore.updateSubsequentCloseInterest($event)"
    ></dropdown>

    @if (
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterest ===
      ENUMS.SimpleAnswerEnum.Yes
    ) {
      <dropdown
        class="c2s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType
        "
        [settings]="{
          label: 'Type',
          options: ENUMS.RateIndexEnum.toOptions,
          optionsFiltering: false,
          tag: 'Subsequent Close Interest Type',
        }"
        [validators]="ivStore.subsequentCloseInterestType_validators()"
        [validation.id]="iv.$uid"
        (valueChange)="ivStore.updateSubsequentCloseInterestType($event)"
      ></dropdown>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType &&
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType !==
        ENUMS.RateIndexEnum.FixedRate
    ) {
      <dropdown
        class="c3s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndex
        "
        [settings]="{
          label: 'Index',
          options: ENUMS.SubClInterestIndexEnum.toOptions,
          tag: 'Subsequent Close Interest Index',
        }"
        (valueChange)="ivStore.updateSubsequentCloseInterestIndex($event)"
      ></dropdown>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType &&
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType !==
        ENUMS.RateIndexEnum.FixedRate &&
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndex ===
        ENUMS.SubClInterestIndexEnum.Other
    ) {
      <text
        class="c4s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndexDesc
        "
        [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Subsequent Close Interest Index Description',
        }"
      ></text>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType &&
      iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType !==
        ENUMS.RateIndexEnum.Index
    ) {
      <number
        class="c5s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestRate
        "
        [settings]="{
          label: 'Rate',
          placeholder: '%',
          suffix: ' %',
          labelTip: 'Also known as \n\r Late Commitment Penalty',
          tag: 'Subsequent Close Interest Rate',
        }"
        [validators]="ivStore.subsequentCloseInterestRate_validators()"
        [validation.id]="iv.$uid"
      ></number>
    }
  }
}
