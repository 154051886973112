import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { ApiService, LocalStorageService } from '@aksia/services';
import { catchError, tap } from 'rxjs';
import {
  AuthenticationStepEnum,
  ICredentials,
  AuthRequestEnum,
  STORAGE_KEYS,
  IAuthenticationService,
  AuthenticationResponseCode,
  IO2User,
  RoleType,
} from '@aksia/infrastructure';
import { HttpErrorResponse } from '@angular/common/http';
import { O2User } from '@aksia/models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements IAuthenticationService {
  //#region Injections

  private readonly store = inject(LocalStorageService);
  private readonly api = inject(ApiService);
  private readonly router = inject(Router);

  //#endregion

  //#region Properties

  user = signal<O2User | undefined>(undefined);
  redirectUrl = signal<string | undefined>(undefined);
  statusMessage = signal<string>('');

  authenticationStep = signal<AuthenticationStepEnum>(
    AuthenticationStepEnum.NotAuthenticated,
  );

  isAuthenticated = computed(() => !!this.user());

  //#endregion

  constructor() {
    this.authenticationStep.set(AuthenticationStepEnum.NotAuthenticated);
    this.initLoad();
  }

  initLoad() {
    if (!this.isAuthenticated()) {
      let user = this.store.get(STORAGE_KEYS.AUTHENTICATION.USER);
      if (user) {
        this.user.set(Object.assign(new O2User(), user));
        this.authenticationStep.set(AuthenticationStepEnum.IsAuthenticated);
      }
    }
  }

  signIn(credentials?: ICredentials) {
    this.api
      .post(AuthRequestEnum.AUTH, JSON.stringify(credentials))
      .pipe(
        tap(() =>
          this.authenticationStep.set(
            AuthenticationStepEnum.VerifyingCredentials,
          ),
        ),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              if (
                err.error?.responseCode ===
                AuthenticationResponseCode.NeedVerificationCode
              ) {
                this.statusMessage.set(
                  `Enter the new code you received at: ${err.error.responseDetails}`,
                );
                this.authenticationStep.set(
                  AuthenticationStepEnum.Requested2FA,
                );
              } else {
                this.authenticationStep.set(
                  AuthenticationStepEnum.RejectedAuthentication,
                );
                this.statusMessage.set(
                  'Your account could not be verified. Please try again.',
                );
              }
            }
          }
          throw err.error;
        }),
      )
      .subscribe((response: any): void => {
        if (response?.responseCode === AuthenticationResponseCode.Success) {
          const user: IO2User = {
            userName: credentials!.maxusername,
            fullName: response.responseDetails.fullName,
            roles: response.responseDetails?.roles,
          };
          this.setUser(user);
        }
      });
  }

  signOut() {
    this.api.post(AuthRequestEnum.SIGN_OUT, {}).subscribe({
      next: (success: any) => {
        //this.authSvc.clearSecurity();
        /* this.broadcast.publish({
          type: 'event_signout',
          payload: 'Signed out',
        }); */
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
        if (error?.status === 401) {
          /* this.authSvc.clearSecurity(); */
        }
      },
    });
  }

  verify2FA(credentials?: ICredentials) {
    this.api
      .post(AuthRequestEnum.AUTH_2FA, credentials)
      .pipe(
        tap(() =>
          this.authenticationStep.set(AuthenticationStepEnum.Verifying2FA),
        ),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              if (
                err.error?.responseCode ===
                AuthenticationResponseCode.VerificationCodeIncorrect
              ) {
                this.statusMessage.set(
                  `Your account could not be verified. Please try again.`,
                );
                this.authenticationStep.set(
                  AuthenticationStepEnum.RejectedAuthentication,
                );
              }
            }
          }
          throw err.error;
        }),
      )
      .subscribe((response: any): void => {
        const user: IO2User = {
          userName: credentials!.maxusername,
          fullName: response.responseDetails.fullName,
          roles: response.responseDetails?.roles,
        };
        this.setUser(user);
      });
  }

  impersonate(role: RoleType | unknown) {
    this.user.update((user) => {
      user!.impersonate = role as RoleType;
      return user;
    });
    this.store.set(STORAGE_KEYS.AUTHENTICATION.USER, this.user());
  }

  setUser(user: IO2User) {
    let o2user = this.store.get(STORAGE_KEYS.AUTHENTICATION.USER);
    if (o2user) {
      this.user.set(Object.assign(new O2User(), o2user));
    } else {
      this.user.set(new O2User(user));
      this.store.set(STORAGE_KEYS.AUTHENTICATION.USER, this.user());
    }
    this.authenticationStep.set(AuthenticationStepEnum.IsAuthenticated);
    this.redirect();
  }

  clearUser() {
    this.user.set(undefined);
    this.store.remove(STORAGE_KEYS.AUTHENTICATION.USER);
  }

  redirect() {
    this.router.navigateByUrl(this.redirectUrl() || '/home');
  }
}
