@let iv = ivStore.investmentVehicle()!; @if (iv) {
<dropdown
  class="c1s1"
  [(value)]="iv.commitmentCurrency"
  [settings]="{
        label: 'Currency',
        labelTip:
          '*This field is directly associated with Commitments Currency in the Classic view.',
        options: ENUMS.CurrencyEnum.toOptions,
        tag: 'Commitment Currency',
      }"
  [validators]="ivStore.commitmentCurrency_validators()"
  [validation.id]="iv.$uid"
></dropdown>
<number
  class="c2s1"
  [(value)]="iv.coInvestmentDetails.totalInvestmentAmount"
  [settings]="{
        label: 'Total Investment Amount',
      }"
></number>
<number
  class="c3s1"
  [(value)]="iv.coInvestmentDetails.totalCoInvestSyndicated"
  [settings]="{
        label: 'Total Co-Invest Syndicated',
      }"
></number>
<number
  class="c4s1"
  [(value)]="iv.coInvestmentDetails.indicativeCapacityAksiaClients"
  [settings]="{
        label: 'Indicative Capacity to Aksia Clients',
      }"
></number>
<text
  class="c1s4 multi"
  [(value)]="iv.coInvestmentDetails.syndicationCapacityNotes"
  [settings]="{
        label: 'Syndication & Capacity Notes',
        multiline: true,
        showCharCount: true,
        labelTip: 'Up to 2000 characters.',
      }"
></text>
}
