@let selectedOpenClass = ivStore.selectedOpenClass()!; @if (selectedOpenClass) {
<dropdown
  #hasSidePocketCtrl
  class="c1s1"
  [(value)]="selectedOpenClass.hasSidePocket"
  [settings]="{
        label: 'Side Pockets',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has Side Pocket',
        uid: selectedOpenClass.$uid,
      }"
  [validators]="ivStore.hasSidePocket_validators()"
  (valueChange)="ivStore.updateHasSidePocket($event)"
></dropdown>
@if (selectedOpenClass.hasSidePocket === ENUMS.SimpleAnswerEnum.Yes) {
<dropdown
  class="c2s1"
  [(value)]="selectedOpenClass.sidePocketOpt"
  [settings]="{
          label: 'Opt-in/Opt-out right',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          optionsFiltering: false,
          tag: 'Side Pocket Opt',
          uid: selectedOpenClass.$uid,
        }"
></dropdown>
} @for ( sidePocket of ivStore.filteredSidePockets(); track sidePocket.$uid; let
i = $index, c = $count ) { @if (selectedOpenClass.hasSidePocket ===
ENUMS.SimpleAnswerEnum.Yes) {
<dropdown
  #sidePocketLevelCtrl
  class="c3s1"
  [(value)]="sidePocket.sidePocketLevel"
  [settings]="{
            label: 'Side Pocket Level #' + (i + 1).toString(),
            options: ENUMS.FundLevelsEnum.toOptions([
              ENUMS.FundLevelsEnum.Investor_Fund,
              ENUMS.FundLevelsEnum.Investor_MasterFund,
            ]),
            optionsFiltering: false,
            selectedOptionStrategy: 'removed',
            tag: 'Side Pocket Level #' + (i + 1),
            uid: sidePocket.$uid + '@' + selectedOpenClass.$uid,
          }"
  [validators]="ivStore.sidePocketLevel_validators()"
></dropdown>
} @if (selectedOpenClass.hasSidePocket === ENUMS.SimpleAnswerEnum.Yes) {
<number
  #sidePocketLimitCtrl
  class="c4s1"
  [(value)]="sidePocket.sidePocketLimit"
  [settings]="{
            label: 'Side Pocket Limit #' + (i + 1),
            placeholder: '%',
            suffix: ' %',
            tag: 'Side Pocket Limit #' + (i + 1),
            uid: sidePocket.$uid + '@' + selectedOpenClass.$uid,
          }"
  [validators]="ivStore.sidePocketLimit_validators()"
></number>
}
<div class="flex c5s1 place-self-stretch">
  @if (i > 0) {
  <btn
    class="place-self-stretch"
    [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Side Pocket #' + (i + 1),
            }"
    (click)="ivStore.removeSidePocket(sidePocket)"
  ></btn>
  } @if (i === c - 1) {
  <btn
    class="place-self-stretch"
    [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Side Pocket',
            }"
    (click)="ivStore.addSidePocket()"
  ></btn>
  }
</div>
} }
