import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENVIRONMENT, PropExtend } from '@aksia/infrastructure';
import {
  ChipComponent,
  ButtonComponent,
  CopyDirective,
  DropdownComponent,
  IconComponent,
} from '@aksia/ui';
import { BroadcastService, SearchService } from '@aksia/services';
import { AuthenticationService } from '../../services/authentication.service';
import { CompanyStore } from '../../stores/company.store';
import { StaticContentDirective } from '../../section-contents/base.section.content';

@Component({
  selector: 'page-header-company',
  imports: [
    CommonModule,
    IconComponent,
    DropdownComponent,
    ButtonComponent,
    CopyDirective,
  ],
  template: `
    @let company = store.company()!;

    @if (company) {
      <div class="asset-class" IconArea>
        <icon [iconDefinition]="ICONS.company" [iconSize]="'x-large'"></icon>
      </div>
      <!-- <chip IconArea class="asset-class">
        
      </chip> -->
      <div TitleArea>
        <h2 class="r1s1" TitleText [UiCopy]="company.name!">
          {{ company.name }}
        </h2>
        <div Subtitle>
          <div>
            <icon [iconSize]="'smaller'" [iconDefinition]="ICONS.link"></icon>
            <a
              [attr.href]="
                env.max_url +
                '/Application/Fund/ManagementCompany.aspx?dataProviderEntityId=' +
                company?.managementCompanyId
              "
              target="_blank"
              >View in MAX</a
            >
          </div>
        </div>
      </div>
      <div ControlsArea class="align-center justify-end">
        <btn
          class="primary"
          [settings]="{
            label: 'Save',
            tag: 'Save',
            disabled: store.state() === 'pristine',
          }"
          [state]="store.state()"
          (click)="store.saveCompany()"
        ></btn>
        <btn
          class="tertiary shadow-sm"
          [settings]="{ label: 'Cancel', tag: 'Cancel' }"
        ></btn>
        <dropdown
          [settings]="{
            placeholder: 'Manager Funds',
            options: store.funds(),
          }"
        ></dropdown>
        <ng-template #customUiOptionTmpl let-option>
          <icon
            [iconSize]="'normal'"
            [iconDefinition]="
              option.entityTypeId === ENTITY_TYPES.ManagementCompany
                ? ICONS.company
                : ICONS.fund
            "
          ></icon>
          <span [innerHtml]="option.label"></span>
          <!-- <span [innerHtml]="option.value | highlight : searchDD.query"></span> -->
        </ng-template>
      </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPageHeader extends StaticContentDirective {
  protected readonly env = inject(ENVIRONMENT);
  protected readonly auth = inject(AuthenticationService);
  protected readonly search = inject(SearchService);
  protected readonly broadcast = inject(BroadcastService);
  protected readonly store = inject(CompanyStore);

  visitEntity(option?: PropExtend<unknown>) {
    let entity: PropExtend<unknown> = option?.data;
    const entityUrl =
      entity['entityTypeId'] === this.ENTITY_TYPES.ManagementCompany
        ? 'managementcompany'
        : 'investmentvehicle';
    this.nav.goToPage(`${entityUrl}/${entity.key}`);
  }
}
