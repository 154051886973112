import {
  ICONS,
  DATE_SELECTION,
  AksiaValidators,
  PERMISSIONS,
  ROLES,
  LAYOUT_SERVICE_TOKEN,
} from '@aksia/infrastructure';
import { NavigationService } from '@aksia/services';
import { Directive, inject, ViewContainerRef } from '@angular/core';
import { ASSET_TEAM_VIEWS, Enums } from '@aksia/models';
import { UTILS } from '@aksia/infrastructure';
import { ModalService } from '@aksia/ui';
import { InvestmentVehicleStore } from '../stores/investment-vehicle.store';
import { CompanyStore } from '../stores/company.store';

@Directive({
  selector: '[static-content]',
  standalone: true,
})
export abstract class StaticContentDirective {
  //#region Injected Services

  protected readonly viewRef = inject(ViewContainerRef);
  protected readonly nav = inject(NavigationService);
  protected readonly layout = inject(LAYOUT_SERVICE_TOKEN);
  protected readonly modal = inject(ModalService);
  protected readonly ivStore = inject(InvestmentVehicleStore);
  protected readonly companyStore = inject(CompanyStore);

  //#endregion

  //#region Constants

  protected readonly ROLES = ROLES;
  protected readonly PERMISSIONS = PERMISSIONS;
  protected readonly VIEWS = ASSET_TEAM_VIEWS;
  protected readonly ASSET_TYPES = Enums.AssetTypeEnum;
  protected readonly ENTITY_TYPES = Enums.EntityTypeEnum;
  protected readonly DATE_SELECTION = DATE_SELECTION;
  protected readonly ENUMS = Enums;
  protected readonly ICONS = ICONS;
  protected readonly UTILS = UTILS;
  protected readonly UI = UTILS.UI;
  protected readonly MONTHS = UTILS.CONSTANTS.MONTHS_TO_MMM;
  protected readonly TODAY = new Date();
  protected readonly Validators = AksiaValidators;

  //#endregion
}
