@let iv = ivStore.investmentVehicle()!; @if (iv) {
<text
  class="c1s10"
  [(value)]="iv.aksiaPerspective"
  [settings]="{
        label: 'Aksia Perspective',
        multiline: true,
        showCharCount: true,
        labelTip:
          'This should include 2-5 sentences summarizing Aksia’s opinion about the manager, including what we like, what we dislike, and how they stack up/compare to peers, with optional relevant context of the depth of the relationship (e.g. introductory meeting, due diligence)',
      }"
></text>
<date
  class="c11s2"
  [(value)]="iv.aksiaPerspectiveAsOf"
  [settings]="{
        label: 'As of',
        placeholder: 'mm/dd/yyyy',
        labelTip:
          'Populate the date your Aksia Perspective text is relevant to. This content will be hidden from MAX once it is 5 years old',
        tag: 'Aksia Perspective As of',
      }"
  [validators]="[Validators.maxDate(TODAY, { isWarning: true })]"
>
</date>
}
