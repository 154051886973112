@let iv = ivStore.investmentVehicle()!; @if (iv) {
<number
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentTarget"
  [settings]="{
        label: 'Commitments (Target)',
        placeholder: 'amount',
        abbr: true,
        labelTip:
          'Also known as: \n\r Target Final Close Size \n Target Capital Raise (Equity) (Est.)',
        tag: 'Commitment Target',
      }"
  [validators]="ivStore.commitmentTarget_validators()"
  [validation.id]="iv.$uid"
  [validation.chain]="ivStore.commitmentTarget_validator_chain()"
></number>
<dropdown
  class="c2s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.hasCommitmentCap"
  [settings]="{
        label: 'Commitments (Cap)',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Has Commitment Cap',
      }"
  [validators]="ivStore.hasCommitmentCap_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateHasCommitmentCap($event)"
></dropdown>

@if ( iv.closedEndDetails.closedEndKeyTerm.hasCommitmentCap ===
ENUMS.SimpleAnswerEnum.Yes ) {
<number
  class="c3s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.commitmentCap"
  [settings]="{
          label: 'amount',
          labelTip: 'Also known as: \n\r Target Capital Raise (Cap)',
          labelFocused: ' ',
          placeholder: 'amount',
          abbr: true,
          tag: 'Commitment Cap',
        }"
  [validators]="ivStore.commitmentCap_validators()"
  [validation.id]="iv.$uid"
  [validation.chain]="ivStore.commitmentCap_validator_chain()"
></number>
}

<dropdown
  class="c4s1"
  [(value)]="iv.commitmentCurrency"
  [settings]="{
        label: '',
        placeholder: 'Currency',
        options: ENUMS.CurrencyEnum.toOptions,
        tag: 'Commitment Currency',
      }"
  [validators]="ivStore.commitmentCurrency_validators()"
  [validation.id]="iv.$uid"
></dropdown>

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitmentType"
  [settings]="{
        label: 'Minimum GP Commitment',
        options: ENUMS.MinGPCommitmentEnum.toOptions,
        tag: 'Minimum GP Commitment Type',
      }"
  (valueChange)="ivStore.updateMinGPCommitmentType($event)"
></dropdown>

@if ( iv.closedEndDetails.closedEndStructure.minGPCommitmentType &&
iv.closedEndDetails.closedEndStructure.minGPCommitmentType !==
ENUMS.MinGPCommitmentEnum.Amount ) {
<number
  class="c2s1"
  [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitment"
  [settings]="{
          label: '',
          placeholder: '% of commitments',
          placeholderPersists: true,
          suffix: ' % of commitments',
          tag: 'Minimum GP Commitment',
        }"
  [validators]="ivStore.minGPCommitment_validators()"
  [validation.id]="iv.$uid"
></number>
} @if ( iv.closedEndDetails.closedEndStructure.minGPCommitmentType &&
iv.closedEndDetails.closedEndStructure.minGPCommitmentType !==
ENUMS.MinGPCommitmentEnum.Percentage ) {
<number
  class="c3s1"
  [(value)]="iv.closedEndDetails.closedEndStructure.minGPCommitmentAmount"
  [settings]="{
          label: '',
          abbr: true,
          placeholder: 'in million/billion',
          tag: 'Minimum GP Commitment Amount',
        }"
  [validators]="ivStore.minGPCommitmentAmount_validators()"
  [validation.id]="iv.$uid"
></number>
} @if (iv.closedEndDetails.closedEndStructure.minGPCommitmentType) {
<check
  class="c4s1"
  [(value)]="
          iv.closedEndDetails.closedEndStructure.minGPCommitmentCashless
        "
  [settings]="{
          label: 'Cashless',
          tag: 'Minimum GP Commitment Cashless',
        }"
></check>
}

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.followOnInvestmentsPerLimit"
  [settings]="{
        label: 'Follow-on Investments % Limit',
        labelTip:
          'Insert any percentage limit on follow-on investments after the end of the investment period.',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Follow-on Investments Per Limit',
      }"
  [validators]="ivStore.followOnInvestmentsPerLimit_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateFollowOnInvestmentsPerLimit($event)"
></dropdown>

@if ( iv.closedEndDetails.followOnInvestmentsPerLimit ===
ENUMS.SimpleAnswerEnum.Yes ) {
<number
  class="c2s1"
  [(value)]="iv.closedEndDetails.followOnInvestmentsPerLimitRate"
  [settings]="{
          label: '',
          placeholder: '%',
          suffix: ' %',
          tag: 'Follow-on Investments Per Limit Rate',
        }"
  [validators]="ivStore.followOnInvestmentsPerLimitRate_validators()"
  [validation.id]="iv.$uid"
></number>
}

<dropdown
  class="c3s1"
  [(value)]="iv.closedEndDetails.followOnInvestmentsTimeLimit"
  [settings]="{
        label: 'Follow-on Investments Time Limit',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Follow-on Investments Time Limit',
      }"
  [validators]="ivStore.followOnInvestmentsTimeLimit_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateFollowOnInvestmentsTimeLimit($event)"
></dropdown>

@if ( iv.closedEndDetails.followOnInvestmentsTimeLimit ===
ENUMS.SimpleAnswerEnum.Yes ) {
<number
  class="c4s1"
  [(value)]="iv.closedEndDetails.followOnInvestmentsTimeLimitMonths"
  [settings]="{
          label: '',
          placeholder: '# of months',
          suffix: ' months',
          tag: 'Follow-on Investments Time Limit Months',
          uid: iv.$uid,
        }"
  [validators]="ivStore.followOnInvestmentsTimeLimitMonths_validators()"
  [validation.id]="iv.$uid"
></number>
}

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.investmentsInProgressTimeLimit"
  [settings]="{
        label: 'Investments in Progress Time Limit',
        labelTip:
          'Insert any time limit for investments in process/follow-up investments after the end of the investment period.',
        options: ENUMS.SimpleAnswerEnum.toOptions,
      }"
  [validators]="ivStore.investmentsInProgressTimeLimit_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateInvestmentsInProgressTimeLimit($event)"
></dropdown>

@if ( iv.closedEndDetails.investmentsInProgressTimeLimit ===
ENUMS.SimpleAnswerEnum.Yes ) {
<number
  class="c2s1"
  [(value)]="iv.closedEndDetails.investmentsInProgressTimeLimitMonths"
  [settings]="{
          label: '',
          placeholder: '# of months',
          suffix: ' months',
          tag: 'Investments in Progress Time Limit Months',
        }"
  [validators]="ivStore.investmentsInProgressTimeLimitMonths_validators()"
  [validation.id]="iv.$uid"
></number>
}

<dropdown
  class="c1s1"
  [(value)]="iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterest"
  [settings]="{
        label: 'Subsequent Close Interest',
        labelTip: 'Insert the Subsequent LP Interest Payment.',
        options: ENUMS.SimpleAnswerEnum.toOptions,
      }"
  [validators]="ivStore.subsequentCloseInterest_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateSubsequentCloseInterest($event)"
></dropdown>

@if ( iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterest ===
ENUMS.SimpleAnswerEnum.Yes ) {
<dropdown
  class="c2s1"
  [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType
        "
  [settings]="{
          label: 'Type',
          options: ENUMS.RateIndexEnum.toOptions,
          tag: 'Subsequent Close Interest Type',
        }"
  [validators]="ivStore.subsequentCloseInterestType_validators()"
  [validation.id]="iv.$uid"
  (valueChange)="ivStore.updateSubsequentCloseInterestType($event)"
></dropdown>
} @if ( iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType &&
iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType !==
ENUMS.RateIndexEnum.FixedRate ) {
<dropdown
  class="c3s1"
  [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndex
        "
  [settings]="{
          label: 'Index',
          options: ENUMS.SubClInterestIndexEnum.toOptions,
          tag: 'Subsequent Close Interest Index',
        }"
  (valueChange)="ivStore.updateSubsequentCloseInterestIndex($event)"
></dropdown>
} @if ( iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType &&
iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType !==
ENUMS.RateIndexEnum.FixedRate &&
iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndex ===
ENUMS.SubClInterestIndexEnum.Other ) {
<text
  class="c4s1"
  [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestIndexDesc
        "
  [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Subsequent Close Interest Index Description',
        }"
></text>
} @if ( iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType &&
iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestType !==
ENUMS.RateIndexEnum.Index ) {
<number
  class="c5s1"
  [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.subsequentCloseInterestRate
        "
  [settings]="{
          label: 'Rate',
          labelTip: 'Also known as \n\r Late Commitment Penalty',
          placeholder: '%',
          suffix: ' %',
          tag: 'Subsequent Close Interest Rate',
        }"
  [validators]="ivStore.subsequentCloseInterestRate_validators()"
  [validation.id]="iv.$uid"
></number>
} }
